import styles from './Puzzle.module.scss';
import classNames from 'classnames';
import Goal from './components/Goal/Goal';
import Token from './components/Token/Token';
import { PuzzleSize } from './types/PuzzleSize';
import { Box, BoxProps } from '@mui/system';

export interface PuzzleProps extends BoxProps {
  goal: number;
  size?: PuzzleSize;
  tokens: number[];
}

const Puzzle = ({
  className,
  goal,
  size = 'medium',
  tokens,
  ...rest
}: PuzzleProps) => {
  return (
    <Box
      className={classNames(
        styles['puzzle'],
        styles[`size-${size}`],
        className
      )}
      {...rest}
    >
      <Goal value={goal} size={size} />

      <div className={styles['tokens']}>
        {tokens
          .sort((a, b) => a - b)
          .map((token, i) => (
            <Token key={i} value={token} size={size} />
          ))}
      </div>
    </Box>
  );
};

export default Puzzle;
