import { useEffect, useState, useRef } from 'react';
import useMatchmaking from '../../../../hooks/useMatchmaking/useMatchmaking';
import ChallengeDialog from '../ChallengeDialog/ChallengeDialog';
import { useNavigate } from 'react-router-dom';
import useUser from '../../../../hooks/useUser/useUser';
import useSettings from '../../../../hooks/useSettings/useSettings';
import { ChallengeStatusUpdateMessageData } from '../../../../hooks/useMatchmaking/types/messages/server/ChallengeStatusUpdateMessage';

const ChallengeListener = () => {
  const {
    state,
    updateChallengeStatus,
    status: matchmakingStatus,
    setStatus: setMatchmakingStatus,
  } = useMatchmaking();
  const { challengeStatus, matchStartData } = state;

  const { blockChallengeAFriendRequests } = useSettings();

  const [isOpenChallengeStatusDialog, setIsOpenChallengeStatusDialog] =
    useState(false);

  const { user } = useUser();
  const { username } = user;
  const isChallengee = challengeStatus?.challengee === username;

  const navigate = useNavigate();

  const prevChallengeStatus = useRef<ChallengeStatusUpdateMessageData | null>(
    null
  );

  useEffect(() => {
    if (
      challengeStatus &&
      challengeStatus.status === 'ACCEPTED' &&
      matchmakingStatus === 'idle'
    ) {
      if (matchStartData && matchStartData.startedAt) {
        const delay = Math.max(
          0,
          new Date(matchStartData.startedAt).getTime() - Date.now()
        );

        setTimeout(() => {
          navigate('/play/challenge');
        }, delay);
      }
    }
  }, [
    challengeStatus,
    matchmakingStatus,
    matchStartData,
    navigate,
    setMatchmakingStatus,
  ]);

  useEffect(() => {
    const blockIncomingChallenges =
      blockChallengeAFriendRequests && isChallengee;

    if (challengeStatus && challengeStatus.status !== null) {
      if (challengeStatus.status !== prevChallengeStatus.current?.status) {
        if (challengeStatus.status === 'PENDING' && !blockIncomingChallenges) {
          setIsOpenChallengeStatusDialog(true);
        } else if (
          ['DECLINED', 'CANCELED', 'BLOCKED'].includes(challengeStatus.status)
        ) {
          setTimeout(() => {
            setIsOpenChallengeStatusDialog(false);
          }, 3000);
        } else if (challengeStatus.status === 'ACCEPTED') {
          // leave dialog open if challeng accepted, hook above will navigate
        } else {
          setIsOpenChallengeStatusDialog(false);
        }
      }
    } else {
      setIsOpenChallengeStatusDialog(false);
    }

    prevChallengeStatus.current = challengeStatus;
  }, [
    challengeStatus,
    matchStartData,
    navigate,
    isChallengee,
    blockChallengeAFriendRequests,
  ]);

  if (isOpenChallengeStatusDialog && challengeStatus) {
    return (
      <ChallengeDialog
        challenger={challengeStatus.challenger}
        challengee={challengeStatus.challengee}
        challengeStatus={challengeStatus.status}
        expirationTime={challengeStatus.expirationTime}
        onAccept={() => {
          updateChallengeStatus(challengeStatus.challengeId, 'ACCEPTED');
        }}
        onDecline={() => {
          updateChallengeStatus(challengeStatus.challengeId, 'DECLINED');
          setIsOpenChallengeStatusDialog(false);
        }}
        onCancel={() =>
          updateChallengeStatus(challengeStatus.challengeId, 'CANCELED')
        }
        open
        maxWidth='xs'
        onClose={() => setIsOpenChallengeStatusDialog(false)}
      />
    );
  }

  return null;
};

export default ChallengeListener;
