import styles from './Play.module.scss';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import assets from '../../assets/assets';
import { useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import { useAppDispatch } from '../../redux/hooks';
import { resetState } from '../../redux/slices/matchmaking/matchmaking';
import PlayGameCard from './components/PlayGameCard/PlayGameCard';
import useMatchmaking from '../../hooks/useMatchmaking/useMatchmaking';
import useUser from '../../hooks/useUser/useUser';
import {
  Alert,
  Badge,
  Box,
  Button,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import HowToPlayDialog from '../AppHeaderFooter/components/HowToPlayDialog/HowToPlayDialog';

const Play = () => {
  const { t } = useTranslation();
  useDocumentTitle(t(`${t('navigation.navigation.play')}`));

  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  const { user } = useUser();

  const {
    selectMatchmakingGameMode,
    selectedMatchmakingGameMode,
    status: matchmakingStatus,
  } = useMatchmaking();

  const [isOpenHowToPlayDialog, setIsOpenHowToPlayDialog] = useState(false);

  useEffect(() => {
    if (
      selectedMatchmakingGameMode &&
      ['normal', 'rated'].includes(selectedMatchmakingGameMode) &&
      matchmakingStatus === 'gameModeSelected'
    ) {
      navigate(`/play/${selectedMatchmakingGameMode}`);
    }
  }, [matchmakingStatus, navigate, selectedMatchmakingGameMode]);

  useEffect(() => {
    appDispatch(resetState());
  }, [appDispatch]);

  return (
    <Page className={styles['page']} paddingSize='large'>
      <div className={styles['gamemodes']}>
        <PlayGameCard
          title={t('game.gameModes.practice.title')}
          description={t('game.gameModes.practice.description')}
          icon={<assets.icons.fitness_center />}
          onClick={() => navigate('/play/practice')}
        />

        <PlayGameCard
          title={t('game.gameModes.normal.title')}
          description={t('game.gameModes.normal.description')}
          icon={<assets.icons.handshake />}
          onClick={() => selectMatchmakingGameMode('normal')}
        />

        {user.type === 'REGISTERED' && (
          <PlayGameCard
            title={t('game.gameModes.rated.title')}
            description={t('game.gameModes.rated.description')}
            icon={<assets.icons.swords />}
            onClick={() => selectMatchmakingGameMode('rated')}
          />
        )}

        {user.type === 'REGISTERED' && (
          <PlayGameCard
            title={t('game.gameModes.challenge.title')}
            description={t('game.gameModes.challenge.description')}
            icon={<assets.icons.groups />}
            onClick={() => navigate('/play/challenge/lobby')}
          />
        )}

        {user.type === 'GUEST' && (
          <Badge
            badgeContent={
              <Tooltip title={t('navigation.play.signinRequired')}>
                <SvgIcon className={styles['signup-badge-icon']}>
                  <assets.icons.lock_filled />
                </SvgIcon>
              </Tooltip>
            }
            color='primary'
            className={styles['signup-required-badge']}
          >
            <PlayGameCard
              title={t('game.gameModes.rated.title')}
              description={t('game.gameModes.rated.description')}
              disabled
              icon={<assets.icons.swords />}
              onClick={() => selectMatchmakingGameMode('rated')}
            />
          </Badge>
        )}

        {user.type === 'GUEST' && (
          <Badge
            badgeContent={
              <Tooltip title={t('navigation.play.signinRequired')}>
                <SvgIcon className={styles['signup-badge-icon']}>
                  <assets.icons.lock_filled />
                </SvgIcon>
              </Tooltip>
            }
            color='primary'
            className={styles['signup-required-badge']}
          >
            <PlayGameCard
              title={t('game.gameModes.challenge.title')}
              description={t('game.gameModes.challenge.description')}
              disabled
              icon={<assets.icons.swords />}
              onClick={() => selectMatchmakingGameMode('rated')}
            />
          </Badge>
        )}
      </div>

      <Box className={styles['how-to-play-banner']}>
        <Box className={styles['end-node']}>
          <Typography variant='h2'>
            {t('navigation.play.learnTheBasics')}
          </Typography>
          <Button
            className={styles['how-to-play-button']}
            size='medium'
            onClick={() => setIsOpenHowToPlayDialog(true)}
          >
            {t('navigation.navigation.howToPlay')}
          </Button>

          <HowToPlayDialog
            open={isOpenHowToPlayDialog}
            onClose={() => setIsOpenHowToPlayDialog(false)}
          />
        </Box>
      </Box>

      {user.type === 'GUEST' && (
        <Alert
          className={styles['guest-mode-alert']}
          severity='info'
          action={
            <Button
              size='medium'
              color='primary'
              className={styles['signup-button']}
              variant='text'
              onClick={() => navigate('/signup')}
            >
              {t('actions.createAccount')}
            </Button>
          }
        >
          <Typography>{t('navigation.play.createAccountAlertBody')}</Typography>
        </Alert>
      )}
    </Page>
  );
};

export default Play;
