import { Avatar, Box, BoxProps, Button, Typography } from '@mui/material';
import useUser from '../../../../../../../hooks/useUser/useUser';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './MainMenuProfileItem.module.scss';
import { useNavigate } from 'react-router-dom';

export interface MainMenuProfileItemProps extends BoxProps {}

const MainMenuProfileItem = ({
  className,
  ...rest
}: MainMenuProfileItemProps) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { username, type } = user;

  const navigate = useNavigate();

  return (
    <Box className={classNames(styles['profile-item'], className)} {...rest}>
      <Box className={styles['avatar-container']}>
        <Avatar aria-label={'user avatar'} tabIndex={0}>
          {username[0] || '?'}
        </Avatar>

        <Typography className={styles['username']}>{username}</Typography>
      </Box>

      {type !== 'REGISTERED' && (
        <Box className={styles['account-actions']}>
          <Button color='primary' onClick={() => navigate('signin')}>
            {t('actions.signin')}
          </Button>
          <Button
            color='primary'
            onClick={() => navigate('signup')}
            variant='outlined'
          >
            {t('actions.createAccount')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MainMenuProfileItem;
