import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiAvatar: {
  defaultProps?: ComponentsProps['MuiAvatar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAvatar'];
  variants?: ComponentsVariants<Theme>['MuiAvatar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.onPrimary,
      backgroundColor: theme.palette.primary.main,
      '&:focus-visible': {
        outline: `2rem solid ${theme.palette.primary.extraLight}`,
      },
    }),
  },
};

export default MuiAvatar;
