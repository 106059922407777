import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import { usePasswordResetCompleteMutation } from '../../../../redux/slices/api/api';
import signinFormStyles from '../../../Signin/components/SigninForm/SigninForm.module.scss';
import FormContainer from '../../../Signin/components/FormContainer/FormContainer';
import FormLogoHeader from '../../../Signin/components/FormLogoHeader/FormLogoHeader';
import FormBody from '../../../Signin/components/FormBody/FormBody';
import { NavLink, useSearchParams } from 'react-router-dom';
import styles from './ResetPasswordCompleteForm.module.scss';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import util from '../../../../util/util';
import config from '../../../../config/config';

interface ResetPasswordCompleteFormProps extends BoxProps {}

const ResetPasswordCompleteForm = ({
  className,
  ...rest
}: ResetPasswordCompleteFormProps) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const expires = searchParams.get('expires');
  const isLinkExpired = expires
    ? new Date(Number(expires)) < new Date()
    : false;

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

  const [
    passwordResetComplete,
    {
      isLoading: isLoadingPasswordResetComplete,
      isSuccess: isSuccessPasswordResetComplete,
      isError: isErrorPasswordResetComplete,
    },
  ] = usePasswordResetCompleteMutation();

  useEffect(() => {
    setNewPasswordErrorText('');
  }, [newPassword]);

  useEffect(() => {
    setConfirmPasswordErrorText('');
  }, [confirmPassword]);

  useEffect(() => {
    if (isErrorPasswordResetComplete) {
      setConfirmPasswordErrorText(
        t('navigation.resetPassword.resetPasswordFailed')
      );
    }
  }, [isErrorPasswordResetComplete, setConfirmPasswordErrorText, t]);

  const handlePasswordResetComplete = (e: any) => {
    e.preventDefault();

    if (!isValidInput()) {
      return;
    }

    if (token === null) return;

    passwordResetComplete({
      newPassword,
      token,
    });
  };

  const isValidInput = () => {
    if (newPassword.length === 0) {
      setNewPasswordErrorText(t('errors.validation.password.emptyPassword'));
      return false;
    } else if (!util.validation.isValidPasswordLength(newPassword)) {
      setNewPasswordErrorText(
        t('errors.validation.password.invalidPasswordLength', {
          minLength: config.account.password.minLength,
        })
      );
      return false;
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.emptyPassword')
      );
      return false;
    } else if (!util.validation.isValidPasswordLength(confirmPassword)) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.invalidPasswordLength', {
          minLength: config.account.password.minLength,
        })
      );
      return false;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.confirmPasswordMismatch')
      );
      return false;
    }

    return true;
  };

  if (!token || !expires) {
    return (
      <FormContainer {...rest}>
        <FormLogoHeader
          title={t('navigation.resetPassword.complete.invalidLinkError.title')}
        />
        <FormBody>
          <Box className={styles['form-introduction-text']}>
            <Typography className={styles['form-introduction-text']}>
              {t(
                'navigation.resetPassword.complete.invalidLinkError.description'
              )}
            </Typography>
          </Box>
          <Button
            className={styles['form-action-button']}
            color='primary'
            component={NavLink}
            fullWidth
            size='large'
            to='/signin'
          >
            {t('actions.returnToSignin')}
          </Button>
        </FormBody>
      </FormContainer>
    );
  }

  if (isLinkExpired) {
    return (
      <FormContainer {...rest}>
        <FormLogoHeader
          title={t('navigation.resetPassword.complete.linkExpiredError.title')}
        />
        <FormBody>
          <Box className={styles['form-introduction-text']}>
            <Typography className={styles['form-introduction-text']}>
              {t(
                'navigation.resetPassword.complete.linkExpiredError.description'
              )}
            </Typography>
          </Box>
          <Button
            className={styles['form-action-button']}
            color='primary'
            component={NavLink}
            fullWidth
            size='large'
            to='/signin'
          >
            {t('actions.returnToSignin')}
          </Button>
        </FormBody>
      </FormContainer>
    );
  }

  if (isSuccessPasswordResetComplete) {
    return (
      <FormContainer {...rest}>
        <FormLogoHeader
          title={t('navigation.resetPassword.complete.success.title')}
        />
        <FormBody>
          <Box className={styles['form-introduction-text']}>
            <Typography className={styles['form-introduction-text']}>
              {t('navigation.resetPassword.complete.success.description')}
            </Typography>
          </Box>
          <Button
            className={styles['form-action-button']}
            color='primary'
            component={NavLink}
            fullWidth
            size='large'
            to='/signin'
          >
            {t('actions.returnToSignin')}
          </Button>
        </FormBody>
      </FormContainer>
    );
  }

  return (
    <FormContainer {...rest}>
      <FormLogoHeader title={t('actions.resetYourPassword')} />

      <FormBody>
        <Box className={styles['form-introduction-text']}>
          <Typography>
            {t('navigation.resetPassword.complete.instructions')}
          </Typography>
        </Box>

        <form
          autoComplete='off'
          noValidate
          onSubmit={handlePasswordResetComplete}
          className={signinFormStyles['form']}
        >
          <PasswordField
            autoFocus
            disabled={isLoadingPasswordResetComplete}
            error={Boolean(newPasswordErrorText)}
            fullWidth
            helperText={newPasswordErrorText}
            label={t('forms.newPassword')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewPassword(e.target.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handlePasswordResetComplete(e);
              }
            }}
            spellCheck={false}
            value={newPassword}
          />

          <PasswordField
            disabled={isLoadingPasswordResetComplete}
            error={Boolean(confirmPasswordErrorText)}
            fullWidth
            helperText={confirmPasswordErrorText}
            label={t('forms.confirmPassword')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(e.target.value)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                handlePasswordResetComplete(e);
              }
            }}
            spellCheck={false}
            value={confirmPassword}
          />

          <Button
            disabled={isLoadingPasswordResetComplete}
            className={signinFormStyles['signin-button']}
            color='primary'
            fullWidth
            type='submit'
            size='large'
          >
            {t('actions.resetPassword')}
          </Button>
        </form>
      </FormBody>
    </FormContainer>
  );
};

export default ResetPasswordCompleteForm;
