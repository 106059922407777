import config from '../../config/config';
import { PasswordValidationErrorType } from '../../redux/slices/api/types/error/PaswordValidationErrorType';

/**
 * Checks if given password has a valid length.
 *
 * @param {string} password - Password on which to perform the check on.
 * @returns {boolean} a Boolean value that indicates whether or not a given password
 * has a valid length.
 *
 */
export const isValidPasswordLength = (password: string) => {
  const { length } = password;

  return (
    config.account.password.minLength <= length &&
    length <= config.account.password.maxLength
  );
};

/**
 * Checks if given password is valid.
 *
 * @param {string} password - Password on which to perform the check on.
 * @returns {boolean} a Boolean value that indicates whether or not a given password
 * is valid.
 *
 */
export const isValidPassword = (password: string) =>
  isValidPasswordLength(password);

/**
 * Returns validity of a given password.
 *
 * @param {string} password - Password string to check the validity of.
 * @returns {enum} Undefined if password is valid, error otherwise.
 *
 */
export const validatePassword = (
  password: string
): PasswordValidationErrorType | undefined => {
  if (password.length === 0) {
    return 'Empty Password';
  } else if (!isValidPasswordLength(password)) {
    return 'Invalid Password Length';
  }
};
