import account from './account';
import application from './application';
import game from './game';
import standings from './standings';

const config = {
  account,
  application,
  game,
  standings,
};

export default config;
