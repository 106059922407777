import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  LinkProps,
  Theme,
} from '@mui/material';
import LinkBehavior from '../mui-link';

const MuiLink: {
  defaultProps?: ComponentsProps['MuiLink'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLink'];
  variants?: ComponentsVariants<Theme>['MuiLink'];
} = {
  defaultProps: {
    component: LinkBehavior,
  } as LinkProps,
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      let rules: any = {
        fontSize: '14rem',
        transition: 'color 0.15s',
        '&:focus-visible': {
          outline: `2rem solid ${theme.palette.primary.extraLight}`,
          textDecoration: 'underline',
        },
      };

      if (ownerState.color === 'font-color-secondary') {
        rules = {
          ...rules,
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        };
      } else if (ownerState.color === 'font-color-tertiary') {
        rules = {
          ...rules,
          color: theme.palette.text.tertiary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        };
      }

      return rules;
    },
    button: {
      fontFamily: 'inherit',
      verticalAlign: 'baseline',
    },
  },
};

export default MuiLink;
