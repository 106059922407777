import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiIconButton: {
  defaultProps?: ComponentsProps['MuiIconButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
  variants?: ComponentsVariants<Theme>['MuiIconButton'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      '&.Mui-disabled .MuiIcon-root svg': {
        fill: theme.palette.text.secondary,
      },
      '&.Mui-focusVisible': {
        outline: `2rem solid ${theme.palette.primary.extraLight}`,
      },
    }),
    edgeEnd: {
      marginRight: '-8rem',
    },
  },
};

export default MuiIconButton;
