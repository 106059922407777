import styles from './TabBar.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import assets from '../../../../../assets/assets';
import { useTranslation } from 'react-i18next';
import NavigationButton from '../NavigationButton/NavigationButton';
import { Box, BoxProps } from '@mui/material';
import useUser from '../../../../../hooks/useUser/useUser';

export interface TabBarProps extends BoxProps {}

const TabBar = ({ className, ...rest }: TabBarProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { type: userType } = user;

  return (
    <Box className={classNames(styles['tab-bar'], className)} {...rest}>
      <NavLink
        aria-label={'play'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/play'}
      >
        {({ isActive }) => (
          <NavigationButton
            icon={
              isActive ? (
                <assets.icons.boronu_icon_filled />
              ) : (
                <assets.icons.boronu_icon />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.play')}
            variant='titled-icon'
          />
        )}
      </NavLink>

      <NavLink
        aria-label={'profile'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/profile'}
      >
        {({ isActive }) => (
          <NavigationButton
            icon={
              isActive ? (
                <assets.icons.account_circle_filled />
              ) : (
                <assets.icons.account_circle />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.profile')}
            variant='titled-icon'
          />
        )}
      </NavLink>

      {userType === 'REGISTERED' && (
        <NavLink
          aria-label={'friends'}
          className={(active) =>
            classNames(styles['nav-link'], {
              [styles['active']]: active.isActive,
            })
          }
          to={'/friends'}
        >
          {({ isActive }) => (
            <NavigationButton
              icon={
                isActive ? (
                  <assets.icons.groups_filled />
                ) : (
                  <assets.icons.groups />
                )
              }
              isActive={isActive}
              title={t('navigation.navigation.friends')}
              variant='titled-icon'
            />
          )}
        </NavLink>
      )}

      <NavLink
        aria-label={'standings'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/standings'}
      >
        {({ isActive }) => (
          <NavigationButton
            icon={
              isActive ? (
                <assets.icons.leaderboard_filled />
              ) : (
                <assets.icons.leaderboard />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.standings')}
            variant='titled-icon'
          />
        )}
      </NavLink>
    </Box>
  );
};

export default TabBar;
