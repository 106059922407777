import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiToggleButton: {
  defaultProps?: ComponentsProps['MuiToggleButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiToggleButton'];
  variants?: ComponentsVariants<Theme>['MuiToggleButton'];
} = {
  styleOverrides: {
    root: {
      border: 0,
      padding: '8rem',
      lineHeight: 0,
    },
  },
};

export default MuiToggleButton;
