import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiInputBase: {
  defaultProps?: ComponentsProps['MuiInputBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputBase'];
  variants?: ComponentsVariants<Theme>['MuiInputBase'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '14rem',
      border: `1px solid red`,
      '&.Mui-focused': {
        outline: `2px solid ${theme.palette.primary.extraLight}`,
      },
    }),
    input: {
      minWidth: '10ch',
    },
  },
};

export default MuiInputBase;
