import config from '../../config/config';

const getTranslationKeyDefaultnterpolationObject = (key: string) => {
  switch (key) {
    case 'errors.validation.password.invalidPasswordLength':
      return {
        minLength: config.account.password.minLength,
      };
    case 'errors.validation.username.invalidUsernameLength':
      return {
        minLength: config.account.username.minLength,
        maxLength: config.account.username.maxLength,
      };
    case 'navigation.more.account.editUsername.formBody':
      return {
        minDaysBetweenChanges: config.account.username.minDaysBetweenChanges,
      };
    case 'navigation.more.account.editEmail.formBody':
      return {
        minDaysBetweenChanges: config.account.email.minDaysBetweenChanges,
      };
    case 'navigation.updateEmail.instructions': {
      return {
        minDaysBetweenChanges: config.account.email.minDaysBetweenChanges,
      };
    }
  }
};

export default getTranslationKeyDefaultnterpolationObject;
