import { Box, IconButton, Skeleton, SvgIcon } from '@mui/material';
import styles from './MatchmakingPuzzleboard.module.scss';
import PuzzleboardHeader from '../../components/Puzzleboard/PuzzleboardHeader/PuzzleboardHeader';
import assets from '../../../../assets/assets';
import GoalboardSkeleton from '../../components/Puzzleboard/Goalboard/GoalboardSkeleton';
import KeyboardSkeleton from '../../components/Puzzleboard/Keyboard/KeyboardSkeleton';

const MatchmakingPuzzleboardSkeleton = () => {
  return (
    <Box className={styles['puzzleboard']}>
      <PuzzleboardHeader>
        <Skeleton>
          <IconButton>
            <SvgIcon>
              <assets.icons.close />
            </SvgIcon>
          </IconButton>
        </Skeleton>

        <Skeleton sx={{ marginLeft: 'auto' }}>
          <IconButton>
            <SvgIcon>
              <assets.icons.help />
            </SvgIcon>
          </IconButton>
        </Skeleton>
      </PuzzleboardHeader>

      <GoalboardSkeleton />

      <KeyboardSkeleton />
    </Box>
  );
};

export default MatchmakingPuzzleboardSkeleton;
