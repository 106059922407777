import { Skeleton } from '@mui/material';
import styles from './StandingsTable.module.scss';

const StandingsTableSkeleton = () => {
  return (
    <div className={styles['table-container']}>
      <Skeleton variant='rounded' height={1000} />
    </div>
  );
};

export default StandingsTableSkeleton;
