import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setUser as reducerSetUser } from '../../redux/slices/user/user';
import { User } from '../../redux/slices/user/types/User';

const useUser = () => {
  const user = useAppSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  const setUser = (user: User) => dispatch(reducerSetUser(user));

  return {
    user,
    setUser,
  };
};

export default useUser;
