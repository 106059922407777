import styles from './Friends.module.scss';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import TabPanel from '../../components/TabPanel/TabPanel';
import {
  useFriendRequestRespondMutation,
  useGetFriendsQuery,
  useGetPendingFriendRequestsQuery,
  useUnfriendMutation,
} from '../../redux/slices/api/api';
import FriendCard from './components/FriendCard/FriendCard';
import FriendTabsChipGroup from './components/FriendTabsChipGroup/FriendTabsChipGroup';
import assets from '../../assets/assets';
import useMatchmaking from '../../hooks/useMatchmaking/useMatchmaking';
import classNames from 'classnames';

const Friends = () => {
  const { t } = useTranslation();
  useDocumentTitle(t(`${t('navigation.navigation.friends')}`));

  const [activeTab, setActiveTab] = useState(0);

  const {
    data: friends,
    isLoading: isLoadingFriends,
    isError: isErrorFriends,
    refetch: refetchGetFriends,
  } = useGetFriendsQuery();

  const {
    data: pendingFriendRequests,
    isLoading: isLoadingGetPendingFriendRequests,
    isError: isErrorGetPendingFriendRequests,
    refetch: refetchGetPendingFriendRequests,
  } = useGetPendingFriendRequestsQuery();

  const [
    friendRequestRespond,
    {
      isLoading: isLoadingFriendRequestRespond,
      isSuccess: isSuccessFriendRequestRespond,
    },
  ] = useFriendRequestRespondMutation();

  const [
    unfriend,
    { isLoading: isLoadingUnfriend, isSuccess: isSuccessUnfriend },
  ] = useUnfriendMutation();

  const { createChallenge } = useMatchmaking();

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (isSuccessFriendRequestRespond) {
      refetchGetFriends();
      refetchGetPendingFriendRequests();
    }
  }, [
    isSuccessFriendRequestRespond,
    refetchGetFriends,
    refetchGetPendingFriendRequests,
  ]);

  useEffect(() => {
    if (isSuccessUnfriend) {
      refetchGetFriends();
    }
  }, [isSuccessUnfriend, refetchGetFriends]);

  if (!friends || !pendingFriendRequests) return null;

  return (
    <Page className={styles['page']} paddingSize='large'>
      <Typography variant='page-h1' className={'heading-notch'}>
        {t('navigation.navigation.friends')}
      </Typography>

      <Box className={styles['tabs-action-container']}>
        <FriendTabsChipGroup
          value={String(activeTab)}
          onChange={(value: string) => setActiveTab(Number(value))}
          aria-label='friends tabs'
        />

        <Box className={styles['end-node']}>
          <TextField
            className={styles['filter-text-field']}
            placeholder={t('actions.search')}
            value={searchText}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            InputProps={{
              endAdornment:
                searchText === '' ? (
                  <InputAdornment position='end'>
                    <SvgIcon>
                      <assets.icons.search />
                    </SvgIcon>
                  </InputAdornment>
                ) : (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setSearchText('')}
                      className={styles['search-text-field-end-icon-button']}
                    >
                      <SvgIcon>
                        <assets.icons.close_small />
                      </SvgIcon>
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />
        </Box>
      </Box>

      <TabPanel value={activeTab} index={0}>
        {isLoadingFriends && <CircularProgress />}

        {isErrorFriends ? (
          <Typography variant='body2'>
            {t('errors.somethingWentWrongPleaseTryAgain')}
          </Typography>
        ) : (
          <>
            <Box className={styles['friend-requests-grid']}>
              {friends.friends
                .filter((f) => f.name.includes(searchText))
                .map((r) => (
                  <FriendCard name={r.name} key={r.name}>
                    <Button
                      className={styles['profile-action-button']}
                      disabled={isLoadingUnfriend}
                      color='primary'
                      size='small'
                      onClick={() => createChallenge(r.name)}
                    >
                      <SvgIcon fontSize='small'>
                        <assets.icons.groups_filled />
                      </SvgIcon>
                      {t('actions.challenge')}
                    </Button>
                    <Button
                      className={classNames(
                        styles['profile-action-button'],
                        styles['unfriend-action-button']
                      )}
                      disabled={isLoadingUnfriend}
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={() =>
                        unfriend({
                          username: r.name,
                        })
                      }
                    >
                      <SvgIcon fontSize='small'>
                        <assets.icons.person_remove_filled />
                      </SvgIcon>
                      {t('actions.unfriend')}
                    </Button>
                  </FriendCard>
                ))}
            </Box>

            {friends.friends.filter((f) => f.name.includes(searchText))
              .length === 0 && (
              <Typography variant='body2'>
                {t('navigation.friends.noFriendsFound')}
              </Typography>
            )}
          </>
        )}
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        {isLoadingGetPendingFriendRequests && <CircularProgress />}

        {isErrorGetPendingFriendRequests ? (
          <Typography variant='body2'>
            {t('errors.somethingWentWrongPleaseTryAgain')}
          </Typography>
        ) : (
          <>
            <Box className={styles['friend-requests-grid']}>
              {pendingFriendRequests.requests
                .filter((r) => r.sender)
                .filter((f) => f.sender!.includes(searchText))
                .map((r) => (
                  <FriendCard name={r.sender!} key={r.sender!}>
                    <Button
                      className={styles['profile-action-button']}
                      disabled={isLoadingFriendRequestRespond}
                      onClick={() =>
                        friendRequestRespond({
                          requestId: r.id,
                          action: 'ACCEPT',
                        })
                      }
                      color='primary'
                      size='small'
                    >
                      {t('actions.accept')}
                    </Button>
                    <Button
                      className={styles['profile-action-button']}
                      disabled={isLoadingFriendRequestRespond}
                      onClick={() =>
                        friendRequestRespond({
                          requestId: r.id,
                          action: 'DECLINE',
                        })
                      }
                      color='primary'
                      size='small'
                      variant='outlined'
                    >
                      {t('actions.decline')}
                    </Button>
                  </FriendCard>
                ))}
            </Box>

            {pendingFriendRequests.requests
              .filter((r) => r.sender)
              .filter((f) => f.sender!.includes(searchText)).length === 0 && (
              <Typography variant='body2'>
                {t('navigation.friends.noRequestsFound')}
              </Typography>
            )}
          </>
        )}
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        {isLoadingGetPendingFriendRequests && <CircularProgress />}

        {isErrorGetPendingFriendRequests ? (
          <Typography variant='body2'>
            {t('errors.somethingWentWrongPleaseTryAgain')}
          </Typography>
        ) : (
          <>
            <Box className={styles['friend-requests-grid']}>
              {pendingFriendRequests.requests
                .filter((r) => r.receiver)
                .filter((f) => f.receiver!.includes(searchText))
                .map((r) => (
                  <FriendCard name={r.receiver!} key={r.receiver!}>
                    <Button
                      className={styles['profile-action-button']}
                      disabled={isLoadingFriendRequestRespond}
                      onClick={() =>
                        friendRequestRespond({
                          requestId: r.id,
                          action: 'CANCEL',
                        })
                      }
                      color='primary'
                      size='small'
                    >
                      <SvgIcon fontSize='small'>
                        <assets.icons.person_cancel_filled />
                      </SvgIcon>
                      {t('actions.cancelRequest')}
                    </Button>
                  </FriendCard>
                ))}
            </Box>

            {pendingFriendRequests.requests
              .filter((r) => r.receiver)
              .filter((f) => f.receiver!.includes(searchText)).length === 0 && (
              <Typography variant='body2'>
                {t('navigation.friends.noRequestsFound')}
              </Typography>
            )}
          </>
        )}
      </TabPanel>
    </Page>
  );
};

export default Friends;
