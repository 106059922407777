import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiTypography: {
  defaultProps?: ComponentsProps['MuiTypography'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
  variants?: ComponentsVariants<Theme>['MuiTypography'];
} = {
  defaultProps: {
    variantMapping: {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      subtitle1: 'h6',
      subtitle2: 'h6',
      body1: 'span',
      body2: 'span',
      'page-h1': 'h1',
      'page-h2': 'h2',
    },
  },
  styleOverrides: {
    body1: {
      fontSize: '14rem',
    },
    h1: {
      fontWeight: 'bold',
    },
    subtitle1: ({ theme }) => ({
      fontSize: '14rem',
      color: theme.palette.text.secondary,
    }),
  },
};

export default MuiTypography;
