import { GameMode } from '../../../../../types/Gamemode';
import assets from '../../../../../assets/assets';

const getGameModeIcon = (gameMode: GameMode) => {
  switch (gameMode) {
    case 'practice':
      return <assets.icons.fitness_center />;
    case 'normal':
      return <assets.icons.handshake />;
    case 'rated':
      return <assets.icons.swords />;
    case 'challenge':
      return <assets.icons.groups />;
  }
};

export default getGameModeIcon;
