import { Box, BoxProps, SvgIcon } from '@mui/material';
import classNames from 'classnames';
import styles from './ChallengePlayAgainStatus.module.scss';
import assets from '../../../../../assets/assets';
import { PlayerChallengeStatus } from './types/PlayerChallengeStatus';

export interface ChallengePlayAgainStatusProps extends BoxProps {
  statuses: PlayerChallengeStatus[];
}

const ChallengePlayAgainStatus = ({
  className,
  statuses,
  ...rest
}: ChallengePlayAgainStatusProps) => {
  return (
    <Box className={classNames(styles['container'], className)} {...rest}>
      {statuses.map((s, i) => (
        <SvgIcon
          key={i}
          className={classNames(
            styles['icon'],
            styles[`status-${s.toLowerCase()}`]
          )}
        >
          <assets.icons.person_filled />
        </SvgIcon>
      ))}
    </Box>
  );
};

export default ChallengePlayAgainStatus;
