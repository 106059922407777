import styles from './MatchCardHeader.module.scss';
import classNames from 'classnames';
import { Box, BoxProps } from '@mui/material';
import MatchOutcomeText from '../../../../Match/components/MatchOutcomeText/MatchOutcomeChip';
import MatchEndDate from '../../../../Match/components/MatchEndDate/MatchEndDate';
import { MatchOutcome } from '../../../../../types/match/MatchOutcome';
import { GameMode } from '../../../../../types/Gamemode';
import GameModeIcon from '../../GameModeIcon/GameModeIcon';
import { useTranslation } from 'react-i18next';

export interface MatchCardHeaderProps extends BoxProps {
  gameMode: GameMode;
  matchEndDate?: Date;
  matchExpectedEndDate?: Date;
  matchOutcome?: MatchOutcome;
  matchStartDate?: Date;
}

const MatchCardHeader = ({
  gameMode,
  matchEndDate,
  matchExpectedEndDate,
  matchOutcome,
  matchStartDate,
  className,
  ...rest
}: MatchCardHeaderProps) => {
  const { t } = useTranslation();

  const matchInProgress = matchExpectedEndDate
    ? matchExpectedEndDate > new Date()
    : false;
  const endDate = gameMode === 'practice' ? matchStartDate : matchEndDate;

  return (
    <Box
      className={classNames(styles['match-card-header'], className)}
      {...rest}
    >
      <GameModeIcon
        gameMode={gameMode}
        showLabel
        label={
          gameMode === 'challenge'
            ? t('game.gameModes.challenge.shortTitle')
            : undefined
        }
      />

      {matchOutcome && <MatchOutcomeText matchOutcome={matchOutcome} />}

      {endDate && <MatchEndDate endDate={endDate} />}

      {!endDate && matchExpectedEndDate && matchInProgress && (
        <MatchEndDate
          className={styles['in-progress-timestamp']}
          endDate={matchExpectedEndDate}
        />
      )}
    </Box>
  );
};

export default MatchCardHeader;
