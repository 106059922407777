import styles from './App.module.scss';
import './assets/scss/general.scss';
import './assets/scss/colors.scss';
import './assets/scss/global.scss';
import './assets/scss/dimensions.scss';
import { Outlet } from 'react-router-dom';
import './i18n';
import useSettings from './hooks/useSettings/useSettings';
import useAppCache from './hooks/useAppCache/useAppCache';
import useUser from './hooks/useUser/useUser';
import { useAppSelector } from './redux/hooks';
import { RootState } from './redux/store';
import useWebSocket from 'react-use-websocket';
import { WS_API_URL } from './redux/slices/api/config';

function App() {
  const isInitComplete = useAppCache();
  useSettings();

  const { user } = useUser();
  const { isSignedIn } = user;

  const accessToken = useAppSelector(
    (state: RootState) => state.auth.accessToken
  );

  useWebSocket(
    WS_API_URL!,
    {
      queryParams: !!accessToken
        ? {
            token: accessToken,
          }
        : undefined,
      share: true,
      heartbeat: {
        interval: 30_000,
      },
    },
    isSignedIn && isInitComplete
  );

  if (!isInitComplete) return null;

  return (
    <div className={styles['app']}>
      <Outlet />
    </div>
  );
}

export default App;
