import { Button } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import styles from './Pagination.module.scss';

export interface PaginationProps {
  className?: string;
  onChange: (activePageNumber: number) => void;
  pageCount: number;
}

const Pagination = ({ className, pageCount, onChange }: PaginationProps) => {
  const [activePageNumber, setActivePageNumber] = useState(1);

  return (
    <div className={classNames(styles['pagination'], className)}>
      {Array.from(Array(pageCount).keys()).map((i) => (
        <Button
          className={classNames(styles['paging-button'], {
            [styles['active']]: activePageNumber === i + 1,
          })}
          key={i}
          onClick={() => {
            setActivePageNumber(i + 1);
            onChange?.(i + 1);
          }}
        >
          {i + 1}
        </Button>
      ))}
    </div>
  );
};

export default Pagination;
