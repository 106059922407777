import {
  Box,
  BoxProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  SvgIcon,
} from '@mui/material';
import classNames from 'classnames';
import assets from '../../../assets/assets';
import styles from './DialogHeader.module.scss';

export interface DialogHeaderProps extends BoxProps {
  closeDialogButtonProps?: IconButtonProps;
  onClose?: VoidFunction;
  title?: string;
  titleProps?: DialogTitleProps;
}

const DialogHeader = ({
  className,
  closeDialogButtonProps = {},
  onClose,
  title,
  titleProps,
  ...rest
}: DialogHeaderProps) => {
  const {
    className: closeDialogButtonClassName,
    ...restCloseDialogButtonProps
  } = closeDialogButtonProps;

  return (
    <Box className={classNames(styles['header'], className)} {...rest}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}

      {onClose && (
        <IconButton
          aria-label='close-dialog'
          className={classNames(
            styles['close-button'],
            closeDialogButtonClassName
          )}
          onClick={onClose}
          {...restCloseDialogButtonProps}
        >
          <SvgIcon fontSize='medium'>
            <assets.icons.close />
          </SvgIcon>
        </IconButton>
      )}
    </Box>
  );
};

export default DialogHeader;
