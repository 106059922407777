import classNames from 'classnames';
import styles from './PuzzleSolutionCard.module.scss';
import { Card, CardProps } from '@mui/material';
import { SolutionHeaderTitleVariant } from './components/SolutionHeader/types/SolutionHeaderTitleVariant';
import SolutionHeader from './components/SolutionHeader/SolutionHeader';
import SolutionExpressionDetails from './components/PuzzleSolutionDetails/PuzzleSolutionDetails';
import util from '../../../../../util/util';
import { PuzzleSolution } from '../../../../../types/match/PuzzleSolution';
import { ExpressionError as ExpressionErrorType } from '../../../../../types/match/ExpressionError';
import ExpressionError from './components/ExpressionError/ExpressionError';
import { MatchOutcome } from '../../../../../types/match/MatchOutcome';
import { Animatable } from '../../../../../types/Animatable';
import { TimeColor } from './components/PuzzleSolutionDetails/types/TimeColor';
import { DistanceSize } from './components/PuzzleSolutionDetails/types/DistanceSize';

export interface PuzzleSolutionCardProps
  extends Omit<CardProps, 'title'>,
    Animatable {
  distance?: number;
  displayTime?: boolean;
  distanceSize?: DistanceSize;
  error?: ExpressionErrorType;
  forfeit?: boolean;
  matchOutcome?: MatchOutcome;
  rating?: number;
  ratingChange?: number;
  solution?: PuzzleSolution;
  time?: string;
  timeColor?: TimeColor;
  title: string;
  titleVariant?: SolutionHeaderTitleVariant;
}

const PuzzleSolutionCard = ({
  animate,
  className,
  distance,
  displayTime = true,
  distanceSize = 'large',
  forfeit,
  error,
  matchOutcome,
  rating,
  ratingChange,
  solution,
  title,
  titleVariant = 'profile-link',
  style,
  time,
  timeColor,
  ...rest
}: PuzzleSolutionCardProps) => {
  return (
    <Card
      className={classNames(styles['puzzle-solution-card'], className)}
      {...rest}
    >
      <SolutionHeader
        animate={animate}
        forfeit={forfeit}
        matchOutcome={matchOutcome}
        rating={rating}
        ratingChange={ratingChange}
        titleVariant={titleVariant}
        title={title}
      />
      <SolutionExpressionDetails
        distance={distance}
        displayTime={displayTime}
        distanceSize={distanceSize}
        error={error}
        expression={
          solution?.expression !== undefined
            ? util.strings.getPrettyExpressionString(solution.expression)
            : undefined
        }
        expressionValue={solution?.expressionValue}
        time={time}
        timeColor={timeColor}
      />
      {error && <ExpressionError error={error} />}
    </Card>
  );
};

export default PuzzleSolutionCard;
