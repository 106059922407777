import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { ExpressionboardProps } from './Expressionboard';
import styles from './Expressionboard.module.scss';

export interface ExpressionboardSkeletonProps
  extends Partial<ExpressionboardProps> {}

const ExpressionboardSkeleton = ({
  className,
  ...rest
}: ExpressionboardSkeletonProps) => {
  return (
    <Skeleton
      className={classNames(styles['expressionboard'], className)}
      width={'100%'}
      height={'100rem'}
      sx={{ transform: 'unset' }}
    />
  );
};

export default ExpressionboardSkeleton;
