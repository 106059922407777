import styles from './FormContainer.module.scss';
import classNames from 'classnames';
import { Box, BoxProps } from '@mui/system';

interface FormContainerProps extends BoxProps {}

const FormContainer = ({
  children,
  className,
  ...rest
}: FormContainerProps) => {
  return (
    <Box className={classNames(styles['form-container'], className)} {...rest}>
      {children}
    </Box>
  );
};

export default FormContainer;
