import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import styles from './ChallengeDialog.module.scss';
import useUser from '../../../../hooks/useUser/useUser';
import classNames from 'classnames';
import ChallengeStatus from '../ChallengeStatus/ChallengeStatus';
import { ChallengeStatus as ChallengeStatusType } from '../../../../hooks/useMatchmaking/types/ChallengeStatus';
import useSettings from '../../../../hooks/useSettings/useSettings';

export interface ChallengeDialogProps extends DialogProps {
  expirationTime?: number;
  challengee: string;
  challenger: string;
  challengeStatus: ChallengeStatusType;
  onAccept?: () => void;
  onDecline?: () => void;
  onCancel?: () => void;
}

const ChallengeDialog = ({
  className,
  expirationTime,
  challenger,
  challengee,
  challengeStatus,
  onAccept,
  onDecline,
  onCancel,
  ...rest
}: ChallengeDialogProps) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { username } = user;

  const isChallenger = challenger === username;

  const { blockChallengeAFriendRequests, setBlockChallengeAFriendRequests } =
    useSettings();

  const closeDialog = () => rest?.onClose?.({}, 'escapeKeyDown');

  const handleTimeElapsed = () => {
    if (isChallenger) {
      setTimeout(closeDialog, 5000);
    } else {
      onDecline?.();
    }
  };

  return (
    <Dialog
      aria-describedby='challenge-request-dialog-title'
      className={classNames(styles['dialog'], className)}
      {...rest}
    >
      <DialogHeader
        titleProps={{
          id: 'challenge-request-dialog-title',
        }}
      />

      <DialogContent
        className={classNames(
          styles['dialog-content'],
          isChallenger && styles['margin-top']
        )}
      >
        {!isChallenger && (
          <FormControlLabel
            className={styles['block-challenges-switch']}
            control={
              <Switch
                checked={blockChallengeAFriendRequests}
                inputProps={{
                  'aria-label': 'block challenge a friend requests',
                }}
                onChange={(e, checked) =>
                  setBlockChallengeAFriendRequests(checked)
                }
              />
            }
            label={t('settings.game.blockChallenges')}
            labelPlacement='start'
          />
        )}

        <ChallengeStatus
          status={challengeStatus}
          challenger={challenger}
          challengee={challengee}
          expirationTime={expirationTime}
          isChallenger={isChallenger}
          onTimeElapsed={handleTimeElapsed}
        />
      </DialogContent>

      <DialogActions className={styles['actions']}>
        {isChallenger ? (
          <Button disabled={challengeStatus !== 'PENDING'} onClick={onCancel}>
            {t('actions.cancel')}
          </Button>
        ) : (
          <>
            <Button disabled={challengeStatus !== 'PENDING'} onClick={onAccept}>
              {t('actions.accept')}
            </Button>
            <Button
              disabled={challengeStatus !== 'PENDING'}
              onClick={onDecline}
            >
              {t('actions.decline')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ChallengeDialog;
