import audio from './audio';
import images from './images';
import icons from './icons';

const assets = {
  audio,
  icons,
  images,
};

export default assets;
