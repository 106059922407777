import { useState, useEffect, ReactNode } from 'react';

export interface TimeoutRenderProps {
  children: ReactNode;
  timeout?: number;
}

const TimeoutRender = ({ children, timeout = 500 }: TimeoutRenderProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  return show ? <>{children}</> : null;
};

export default TimeoutRender;
