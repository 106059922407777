import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiDialogActions: {
  defaultProps?: ComponentsProps['MuiDialogActions'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialogActions'];
  variants?: ComponentsVariants<Theme>['MuiDialogActions'];
} = {
  styleOverrides: {
    spacing: {
      padding: '8rem 24rem 24rem 24rem',
    },
  },
};

export default MuiDialogActions;
