import { Link, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination/Pagination';
import styles from './StandingsTable.module.scss';
import config from '../../../../config/config';
import { Standing } from '../../../../redux/slices/api/types/standings/Standings';
import useUser from '../../../../hooks/useUser/useUser';
import MedalRank from './MedalRank/MedalRank';
import classNames from 'classnames';

export interface StandingsTableProps {
  standings: Standing[];
}

const StandingsTable = ({ standings }: StandingsTableProps) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(1);
  const [tableContainer, setTableContainer] = useState<HTMLDivElement | null>(
    null
  );

  const { user } = useUser();

  const paginatedStandings = standings.slice(
    (activePage - 1) * config.standings.playersPerPage,
    activePage * config.standings.playersPerPage
  );

  const minWidth500 = useMediaQuery('(min-width:500px)');

  return (
    <div className={styles['table-container']} ref={setTableContainer}>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th className={styles['rank']}>{t('navigation.profile.rank')}</th>
            <th className={styles['player']}>
              {t('navigation.profile.player')}
            </th>
            <th className={styles['rating']}>
              {t('navigation.profile.rating')}
            </th>
            {minWidth500 && (
              <th className={styles['win-rate']}>
                {t('navigation.profile.winRate')}
              </th>
            )}
            {minWidth500 && (
              <th className={styles['played']}>
                {t('navigation.profile.matches')}
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {paginatedStandings.map((standing) => {
            const isMedalRank = standing.rank < 11;

            return (
              <tr
                key={standing.username}
                className={
                  standing.username === user.username
                    ? styles['personal-standing']
                    : undefined
                }
              >
                <td
                  className={classNames(
                    styles['rank'],
                    isMedalRank && styles['medal-rank']
                  )}
                >
                  {isMedalRank ? (
                    <MedalRank rank={standing.rank} />
                  ) : (
                    standing.rank
                  )}
                </td>
                <td className={styles['player']}>
                  <Link
                    color='inherit'
                    href={`/user/${standing.username}`}
                    underline='hover'
                  >
                    {standing.username}
                  </Link>
                </td>
                <td className={styles['rating']}>{standing.rating}</td>
                {minWidth500 && (
                  <td className={styles['win-rate']}>{`${parseFloat(
                    (
                      (standing.matchesWon / standing.matchesPlayed) * 100 || 0
                    ).toFixed(1)
                  )}%`}</td>
                )}
                {minWidth500 && (
                  <td className={styles['played']}>{standing.matchesPlayed}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        className={styles['pagination']}
        onChange={(activePage: number) => {
          setActivePage(activePage);
          tableContainer?.scrollIntoView({
            behavior: 'smooth',
          });
        }}
        pageCount={
          Math.ceil(standings.length / config.standings.playersPerPage) >
          config.standings.maxPages
            ? config.standings.maxPages
            : Math.ceil(standings.length / config.standings.playersPerPage)
        }
      />
    </div>
  );
};

export default StandingsTable;
