import { ResponseErrorType } from '../../redux/slices/api/types/error/ResponseErrorType';
import getValidationErrorTranslationKey from './getValidationErrorTranslationKey';

const getTranslationFileErrorKey = (error: ResponseErrorType) => {
  switch (error) {
    //  auth
    case 'Wrong Credentials':
      return 'errors.authentication.wrongCredentials';
    case 'Invalid Refresh Token':
      return 'errors.authentication.invalidRefreshtoken';
    default:
      return getValidationErrorTranslationKey(error);
  }
};

export default getTranslationFileErrorKey;
