import { useTranslation } from 'react-i18next';
import StandingsTable from './components/StandingsTable/StandingsTable';
import Page from '../../components/Page/Page';
import Section from '../../components/Page/Section/Section';
import TimeoutRender from '../../components/TimeoutRender/TimeoutRender';
import StandingsSkeleton from './StandingsSkeleton';
import { useStandingsQuery } from '../../redux/slices/api/api';
import { Box, Typography } from '@mui/material';
import PageLoadingError from '../ErrorPage/PageLoadingError/PageLoadingError';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const Standings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.navigation.standings'));

  const { data: standingsResponse, isError } = useStandingsQuery();

  if (isError) {
    return <PageLoadingError />;
  }

  if (!standingsResponse) {
    return (
      <TimeoutRender>
        <StandingsSkeleton />
      </TimeoutRender>
    );
  }

  return (
    <Page paddingSize='medium'>
      <Box className={'heading-notch'}>
        <Typography variant='page-h1'>
          {t('navigation.standings.standingsTopPlayers', {
            playerCount: standingsResponse.standings.slice(0, 200).length,
          })}
        </Typography>
        {standingsResponse.playersStanding && (
          <Typography>
            {t('navigation.standings.yourCurrentRanking', {
              rank: standingsResponse.playersStanding.rank,
            })}
          </Typography>
        )}
      </Box>

      <Section>
        <StandingsTable standings={[...standingsResponse.standings]} />
      </Section>
    </Page>
  );
};

export default Standings;
