import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiSelect: {
  defaultProps?: ComponentsProps['MuiSelect'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSelect'];
  variants?: ComponentsVariants<Theme>['MuiSelect'];
} = {
  defaultProps: {
    variant: 'filled',
    disableUnderline: true,
  },
};

export default MuiSelect;
