import { MatchParticipant } from '../../types/match/participants/MatchParticipant';

/**
 * Returns username of a match winner if there is one.
 *
 * @param participants - Match participants.
 * @returns Match winner or undefined if one can not be deduced.
 *
 */
export const getMatchWinner = (participants: MatchParticipant[]) => {
  const winner = participants.find(
    (participant) => 'placement' in participant && participant.placement === 1
  );

  if (winner) {
    return winner.username;
  }
};
