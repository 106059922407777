import { configureStore } from '@reduxjs/toolkit';
import matchmakingReducer from './slices/matchmaking/matchmaking';
import userReducer from './slices/user/user';
import settingsReducer from './slices/settings/settings';
import apiSlice from './slices/api/api';
import authReducer from './slices/auth/auth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    matchmaking: matchmakingReducer,
    settings: settingsReducer,
    user: userReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
