import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import { useSignupMutation } from '../../../../redux/slices/api/api';
import signinFormStyles from '../../../Signin/components/SigninForm/SigninForm.module.scss';
import useEmailValidation from '../../../../hooks/api/useEmailValidation/useEmailValidation';
import useUsernameValidation from '../../../../hooks/api/useUsernameValidation/useUsernameValidation';
import util from '../../../../util/util';
import FormContainer from '../../../Signin/components/FormContainer/FormContainer';
import FormLogoHeader from '../../../Signin/components/FormLogoHeader/FormLogoHeader';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ErrorResponse } from '../../../../redux/slices/api/types/ErrorResponse';
import FormBody from '../../../Signin/components/FormBody/FormBody';
import styles from './SignupForm.module.scss';
import { NavLink } from 'react-router-dom';
import config from '../../../../config/config';

interface SignupFormProps extends BoxProps {}

const SignupForm = ({ className, ...rest }: SignupFormProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const debouncedEmail = useDebounce(email);
  const { error: emailValidationError } = useEmailValidation(debouncedEmail);
  const [emailErrorText, setEmailErrorText] = useState('');

  const [username, setUsername] = useState('');
  const debouncedUsername = useDebounce(username);
  const { error: usernameValidationError } =
    useUsernameValidation(debouncedUsername);
  const [usernameErrorText, setUsernameErrorText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [signupErrorText, setSignupErrorText] = useState('');

  const [
    signup,
    {
      isSuccess: isSuccesSignup,
      isLoading: isLoadingSignup,
      isError: isErrorSignup,
      error: signupError,
    },
  ] = useSignupMutation();

  useEffect(() => {
    setEmailErrorText('');
    setUsernameErrorText('');
    setPasswordErrorText('');
  }, [email, username, password]);

  useEffect(() => {
    if (emailValidationError === 'Email Unavailable') {
      setEmailErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            emailValidationError
          )
        )
      );
    }
  }, [emailValidationError, t]);

  useEffect(() => {
    if (
      usernameValidationError === 'Username Unavailable' ||
      usernameValidationError === 'Invalid Username Format'
    ) {
      setUsernameErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            usernameValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              usernameValidationError
            )
          )
        )
      );
    }
  }, [usernameValidationError, t]);

  useEffect(() => {
    if (isErrorSignup) {
      if (signupError && 'data' in signupError && signupError.data !== null) {
        const response = signupError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          setSignupErrorText(
            t(
              translationFileKey,
              util.translation.getTranslationKeyDefaultnterpolationObject(
                translationFileKey
              )
            )
          );

          return;
        }
      }

      setSignupErrorText(t('errors.somethingWentWrongPleaseTryAgain'));
    }
  }, [isErrorSignup, signupError, t]);

  const handleSignup = (e: any) => {
    e.preventDefault();

    const emailValidationError = util.validation.validateEmail(email);
    if (emailValidationError) {
      setEmailErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            emailValidationError
          )
        )
      );
      return;
    }

    const usernameValidationError = util.validation.validateUsername(username);
    if (usernameValidationError) {
      setUsernameErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            usernameValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              usernameValidationError
            )
          )
        )
      );
      return;
    }

    const passwordValidationError = util.validation.validatePassword(password);
    if (passwordValidationError) {
      setPasswordErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            passwordValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              passwordValidationError
            )
          )
        )
      );
      return;
    }

    signup({ email, username, password });
  };

  if (isSuccesSignup) {
    return (
      <FormContainer {...rest}>
        <FormLogoHeader title={t('navigation.signup.request.success.title')} />
        <FormBody>
          <Box className={styles['form-introduction-text']}>
            <Typography>
              {t('navigation.signup.request.success.description')}
            </Typography>
          </Box>
          <Button
            className={styles['form-action-button']}
            color='primary'
            component={NavLink}
            fullWidth
            size='large'
            to='/'
          >
            {t('actions.returnToHome')}
          </Button>
        </FormBody>
      </FormContainer>
    );
  }

  return (
    <FormContainer {...rest}>
      <FormLogoHeader title={t('navigation.signup.createYourBoronuAccount')} />

      <form
        autoComplete='off'
        noValidate
        onSubmit={handleSignup}
        className={signinFormStyles['form']}
      >
        <TextField
          autoComplete='off'
          autoFocus
          className={signinFormStyles['input-text-field']}
          error={Boolean(emailErrorText)}
          fullWidth
          helperText={emailErrorText}
          label={t('forms.emailAddress')}
          name='email'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          spellCheck={false}
          value={email}
          variant='filled'
        />

        <TextField
          autoComplete='off'
          className={signinFormStyles['input-text-field']}
          error={Boolean(!emailErrorText) && Boolean(usernameErrorText)}
          fullWidth
          helperText={Boolean(!emailErrorText) ? usernameErrorText : undefined}
          inputProps={{
            maxLength: config.account.username.maxLength,
          }}
          label={t('forms.username')}
          name='username'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setUsername(e.target.value)
          }
          spellCheck={false}
          value={username}
          variant='filled'
        />

        <PasswordField
          autoComplete='off'
          className={signinFormStyles['input-text-field']}
          error={Boolean(passwordErrorText) || Boolean(signupErrorText)}
          fullWidth
          helperText={passwordErrorText || signupErrorText}
          label={t('forms.password')}
          name='password'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          spellCheck={false}
          type='password'
          value={password}
          variant='filled'
        />

        <Button
          disabled={isLoadingSignup}
          className={signinFormStyles['signin-button']}
          color='primary'
          fullWidth
          type='submit'
          size='large'
        >
          {t('actions.signup')}
        </Button>
      </form>

      <Typography>
        {t('navigation.signup.alreadyHaveAnAccount') + ' '}
        <Link underline='hover' href='/signin'>
          {t('actions.signin')}
        </Link>
      </Typography>
    </FormContainer>
  );
};

export default SignupForm;
