const application = {
  emails: {
    feedback: 'support@boronu.com?subject=Boronu Feedback',
    reportABug: 'support@boronu.com?subject=Boronu Bug Report',
  },
  links: {
    appStore: 'https://apps.apple.com/us/app/boronu/id6504283033',
    playStore: 'https://play.google.com/store/apps/details?id=com.boronu',
  },
};

export default application;
