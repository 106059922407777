const game = {
  enqueueTimeout: 1000,
  normal: {
    duration: 90,
  },
  rated: {
    duration: 90,
  },
  challenge: {
    duration: 90,
  },
};

export default game;
