import React, { useState } from 'react';
import { Button, SvgIcon, Typography, Box, BoxProps } from '@mui/material';
import classNames from 'classnames';
import assets from '../../../../assets/assets';
import styles from './AppFooter.module.scss';
import HowToPlayDialog from '../HowToPlayDialog/HowToPlayDialog';
import ChangeAppLanguageDialog from './ChangeAppLanguageDialog/ChangeAppLanguageDialog';
import useSettings from '../../../../hooks/useSettings/useSettings';
import { appLanguages } from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import config from '../../../../config/config';
import FooterSection from './FooterSection/FooterSection';

export interface AppFooterProps extends BoxProps {}

const AppFooter: React.FC<AppFooterProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();
  const [isOpenChangeAppLanguageDialog, setIsOpenChangeAppLanguageDialog] =
    useState(false);
  const [isOpenHowToPlayDialog, setIsOpenHowToPlayDialog] = useState(false);
  const { language } = useSettings();

  const sections = [
    {
      title: t('navigation.navigation.footer.resources'),
      links: [
        {
          text: t('navigation.navigation.howToPlay'),
          href: '#',
          onClick: () => setIsOpenHowToPlayDialog(true),
        },
        { text: t('navigation.navigation.faq'), href: '/faq' },
      ],
    },
    {
      title: t('navigation.navigation.footer.support'),
      links: [
        {
          text: t('navigation.navigation.giveUsFeedback'),
          href: `mailto:${config.application.emails.feedback}`,
        },
        {
          text: t('navigation.navigation.reportABug'),
          href: `mailto:${config.application.emails.reportABug}`,
        },
      ],
    },
    {
      title: t('navigation.navigation.footer.legal'),
      links: [
        {
          text: t('navigation.navigation.privacyPolicy'),
          href: '/privacy',
          target: '_blank',
        },
        {
          text: t('navigation.navigation.termsOfService'),
          href: '/terms-of-service',
          target: '_blank',
        },
      ],
    },
  ];

  return (
    <Box className={classNames(styles['footer'], className)} {...rest}>
      <Box className={styles['start-node']}>
        <Box className={styles['logo-language-container']}>
          <Box className={styles['boronu-logo-container']}>
            <assets.images.BoronuText
              aria-hidden
              className={styles['boronu-logo']}
            />
          </Box>

          <Button
            className={styles['change-language-button']}
            onClick={() => setIsOpenChangeAppLanguageDialog(true)}
            startIcon={
              <SvgIcon>
                <assets.icons.language />
              </SvgIcon>
            }
          >
            {(() => {
              const selectedLanguage = appLanguages.find(
                (lang) => lang.locale === language
              );
              return selectedLanguage?.name;
            })()}
          </Button>
        </Box>

        <ChangeAppLanguageDialog
          open={isOpenChangeAppLanguageDialog}
          onClose={() => setIsOpenChangeAppLanguageDialog(false)}
          maxWidth='xs'
        />

        <Typography className={styles['copyright']}>
          &copy; {new Date().getFullYear()} Boronu
        </Typography>
      </Box>

      <Box className={styles['section-list']}>
        {sections.map((section, index) => (
          <FooterSection
            key={index}
            title={section.title}
            links={section.links}
          />
        ))}
      </Box>

      <HowToPlayDialog
        open={isOpenHowToPlayDialog}
        onClose={() => setIsOpenHowToPlayDialog(false)}
      />
    </Box>
  );
};

export default AppFooter;
