import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './TimerCircle.module.scss';

interface TimerCircleProps {
  expirationTime: number;
  onTimeElapsed?: () => void;
  size?: number;
}

const TimerCircle: React.FC<TimerCircleProps> = ({
  expirationTime,
  onTimeElapsed,
  size = 100,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(
    Math.max(0, expirationTime - Date.now())
  );

  useEffect(() => {
    const totalDuration = expirationTime - Date.now();

    if (totalDuration <= 0) {
      onTimeElapsed?.();
      return;
    }

    const interval = 1000 / 10;

    const timerId = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(0, expirationTime - now);
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timerId);
        onTimeElapsed?.();
      }
    }, interval);

    return () => clearInterval(timerId);
  }, [expirationTime, onTimeElapsed]);

  return (
    <Box
      className={styles['timer-circle']}
      style={{ width: size, height: size, position: 'relative' }}
    >
      <Box
        className={styles['inner-circle']}
        style={{
          width: size * 0.9,
          height: size * 0.9,
        }}
      >
        <Typography variant='h6' component='div'>
          {Math.ceil(timeLeft / 1000)}
        </Typography>
      </Box>
    </Box>
  );
};

export default TimerCircle;
