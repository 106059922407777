import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiToolbar: {
  defaultProps?: ComponentsProps['MuiToolbar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiToolbar'];
  variants?: ComponentsVariants<Theme>['MuiToolbar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        minHeight: 'unset',
        padding: '16rem 16rem',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 'unset',
        padding: '16rem 16rem',
      },
    }),
  },
};

export default MuiToolbar;
