import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiFormControlLabel: {
  defaultProps?: ComponentsProps['MuiFormControlLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormControlLabel'];
  variants?: ComponentsVariants<Theme>['MuiFormControlLabel'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginRight: 0,
    }),
  },
};

export default MuiFormControlLabel;
