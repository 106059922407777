import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiPopover: {
  defaultProps?: ComponentsProps['MuiTab'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
  variants?: ComponentsVariants<Theme>['MuiTab'];
} = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
  },
};

export default MuiPopover;
