import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import styles from './SolutionDetail.module.scss';
import { TitlePlacement } from './types/TitlePlacement';
import { ValuePlacement } from './types/ValuePlacement';
import { Typography, TypographyProps } from '@mui/material';

export interface SolutionDetailProps extends BoxProps {
  title?: string;
  titlePlacement?: TitlePlacement;
  titleProps?: TypographyProps;
  value?: string;
  valuePlacement?: ValuePlacement;
  valueProps?: TypographyProps;
}

const SolutionDetail = ({
  className,
  title,
  titlePlacement = 'start',
  titleProps,
  value,
  valuePlacement = 'start',
  valueProps,
  ...rest
}: SolutionDetailProps) => {
  return (
    <Box className={classNames(styles['solution-detail'], className)} {...rest}>
      <Typography
        {...valueProps}
        className={classNames(
          styles['value'],
          styles[`horizontal-placement-${valuePlacement}`],
          valueProps?.className
        )}
      >
        {value}
      </Typography>

      <Typography
        {...titleProps}
        className={classNames(
          styles['title'],
          styles[`horizontal-placement-${titlePlacement}`],
          titleProps?.className
        )}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default SolutionDetail;
