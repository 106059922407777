import classNames from 'classnames';
import styles from './Match.module.scss';
import { MatchProps } from './Match';
import { Box } from '@mui/material';
import PuzzleSkeleton from '../../../../components/Puzzle/PuzzleSkeleton';
import MatchHeaderSkeleton from '../MatchHeader/MatchHeaderSkeleton';
import PuzzleSolutionsSkeleton from '../PuzzleSolutions/PuzzleSolutionsSkeleton';

export interface MatchSkeletonProps extends Partial<MatchProps> {}

const MatchSkeleton = ({ className, ...rest }: MatchSkeletonProps) => {
  return (
    <Box className={classNames(styles['match'], className)} {...rest}>
      <MatchHeaderSkeleton />

      <PuzzleSkeleton size={'large'} />

      <PuzzleSolutionsSkeleton />
    </Box>
  );
};

export default MatchSkeleton;
