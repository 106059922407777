import { useTranslation } from 'react-i18next';
import styles from './MatchHeading.module.scss';
import MatchEndDate from '../MatchEndDate/MatchEndDate';
import { Box, BoxProps, Typography } from '@mui/material';
import { Animatable } from '../../../../types/Animatable';
import FadeInRight from '../../../../components/motion/FadeInRight/FadeInRight';
import { GameMode } from '../../../../types/Gamemode';
import classNames from 'classnames';

export interface MatchHeadingProps extends BoxProps, Animatable {
  endDate?: Date;
  expectedEndDate?: Date;
  gameMode: GameMode;
  showMatchEndDateTimestamp?: boolean;
}

const MatchHeading = ({
  className,
  animate,
  endDate,
  expectedEndDate,
  gameMode,
  showMatchEndDateTimestamp = true,
  ...rest
}: MatchHeadingProps) => {
  const { t } = useTranslation();

  const matchEndDate = endDate ? <MatchEndDate endDate={endDate} /> : undefined;

  const expectedMatchEndDate = expectedEndDate ? (
    <MatchEndDate endDate={expectedEndDate} />
  ) : undefined;

  return (
    <Box className={classNames(styles['match-heading'], className)} {...rest}>
      <Typography variant='page-h1' className={styles['gamemode-heading']}>
        {t(`navigation.navigation.${gameMode}Match`)}
      </Typography>

      {showMatchEndDateTimestamp && matchEndDate ? (
        animate ? (
          <FadeInRight>{matchEndDate}</FadeInRight>
        ) : (
          matchEndDate
        )
      ) : undefined}

      {!matchEndDate && expectedMatchEndDate ? (
        animate ? (
          <FadeInRight>{expectedMatchEndDate}</FadeInRight>
        ) : (
          expectedMatchEndDate
        )
      ) : undefined}
    </Box>
  );
};

export default MatchHeading;
