import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Settings } from './types/Settings';
import { ColorTheme } from './types/ColorTheme';
import { i18nLocale } from '../../../i18n';

export const initialState: Settings = {
  appLanguage: 'en-US',
  autoExpandShortestSolutionCard: true,
  colorTheme: 'device',
  enableGameBoardSounds: true,
  blockChallengeAFriendRequests: false,
};

export const settingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setAppLanguage: (state, action: PayloadAction<i18nLocale>) => {
      state.appLanguage = action.payload;
    },
    setSettings: (state, action: PayloadAction<Settings>) => action.payload,
    setColorTheme: (state, action: PayloadAction<ColorTheme>) => {
      state.colorTheme = action.payload;
    },
    setAutoExpandShortestSolutionCard: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.autoExpandShortestSolutionCard = action.payload;
    },
    setEnableGameBoardSounds: (state, action: PayloadAction<boolean>) => {
      state.enableGameBoardSounds = action.payload;
    },
    setBlockChallengeAFriendRequests: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.blockChallengeAFriendRequests = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppLanguage,
  setAutoExpandShortestSolutionCard,
  setColorTheme,
  setEnableGameBoardSounds,
  setSettings,
  setBlockChallengeAFriendRequests,
} = settingsSlice.actions;

export default settingsSlice.reducer;
