/**
 * Returns a a raw (non-prettified) expression string.
 *
 * @param string - Expression string to unprettify.
 * @returns Raw expression string.
 *
 */
export const getRawExpressionString = (expression: string): string => {
  let rawExpressionString = '';
  for (let i = 0; i < expression.length; i++) {
    switch (expression[i]) {
      case '+':
        rawExpressionString += '\u002B';
        break;
      case '\u2212':
        rawExpressionString += '\u002D';
        break;
      case '\u00D7':
        rawExpressionString += '\u002A';
        break;
      case '\u00F7':
        rawExpressionString += '\u002F';
        break;
      default:
        rawExpressionString += expression[i];
    }
  }
  return rawExpressionString;
};
