import { useTranslation } from 'react-i18next';
import Section, { SectionProps } from '../components/Section/Section';
import {
  Divider,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import useSettings from '../../../hooks/useSettings/useSettings';
import Setting from '../components/Setting/Setting';
import { appLanguages, i18nLocale } from '../../../i18n';
import accountSettingsStyles from '../AccountSettingsSection/AccountSettingsSection.module.scss';
import { useAppDispatch } from '../../../redux/hooks';
import styles from './ApplicationSettings.module.scss';

export interface ApplicationSettingsProps {
  firstSettingAutoFocus?: boolean;
  sectionProps?: SectionProps;
  showHeading?: boolean;
}

const ApplicationSettings = ({
  firstSettingAutoFocus,
  sectionProps,
  showHeading = true,
}: ApplicationSettingsProps) => {
  const { t } = useTranslation();

  const { colorScheme, setColorScheme } = useSettings();
  const { language, setLanguage } = useSettings();
  const dispatch = useAppDispatch();

  return (
    <Section {...sectionProps}>
      {showHeading && (
        <Typography variant='page-h2'>
          {t('navigation.more.sections.application')}
        </Typography>
      )}

      <Setting
        endNode={
          <Switch
            autoFocus={firstSettingAutoFocus}
            checked={colorScheme === 'dark'}
            inputProps={{ 'aria-label': 'dark mode' }}
            onChange={() =>
              colorScheme === 'dark'
                ? setColorScheme('light')
                : setColorScheme('dark')
            }
          />
        }
        title={t('navigation.navigation.darkMode')}
      />

      <Divider className={accountSettingsStyles['setting-divider']} />

      <Setting
        endNode={
          <TextField
            className={styles['language-text-field']}
            inputProps={{ 'aria-label': 'application language' }}
            SelectProps={{
              MenuProps: {
                disableScrollLock: true,
              },
            }}
            onChange={(option) =>
              dispatch(setLanguage(option.target.value as i18nLocale))
            }
            value={(() => {
              const selectedLanguage = appLanguages.find(
                (lang) => lang.locale === language
              );
              return selectedLanguage?.locale;
            })()}
            select
            size='medium'
          >
            {appLanguages.map((language) => (
              <MenuItem key={language.locale} value={language.locale}>
                {t(language.name)}
              </MenuItem>
            ))}
          </TextField>
        }
        title={t('navigation.navigation.applicationLanguage')}
      />
    </Section>
  );
};

export default ApplicationSettings;
