import { useEffect, useRef } from 'react';
import { UseDocumentTitleOptions } from './types/UseDocumentTitleOptions';

const defaultUseDocumentTitleOptions: UseDocumentTitleOptions = {
  affixSeparator: '-',
  suffix: 'Boronu',
};

const useDocumentTitle = (
  title: string,
  options = defaultUseDocumentTitleOptions
) => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : null);

  useEffect(() => {
    if (!documentDefined) return;

    let newTitle = title;

    if (!options.disableAffixes) {
      if (options.prefix !== undefined) {
        newTitle =
          options.prefix +
          (options.affixSeparator ? ` ${options.affixSeparator} ` : '') +
          newTitle;
      }

      if (options.suffix !== undefined) {
        newTitle +=
          (options.affixSeparator ? ` ${options.affixSeparator} ` : '') +
          options.suffix;
      }
    }

    if (document.title !== newTitle) {
      document.title = newTitle;
    }

    const currentTitle = originalTitle.current;

    return () => {
      document.title = currentTitle || 'Boronu';
    };
  }, [
    documentDefined,
    options.disableAffixes,
    title,
    options.prefix,
    options.suffix,
    options.affixSeparator,
  ]);
};

export default useDocumentTitle;
