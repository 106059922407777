import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './SubmitButton.module.scss';

export interface SubmitButtonProps extends ButtonProps {}

const SubmitButton = ({ className, children, ...rest }: SubmitButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      className={classNames(styles['submit'], className)}
      color='secondary'
      fullWidth
      variant='contained'
      {...rest}
    >
      {children || t('actions.submit')}
    </Button>
  );
};

export default SubmitButton;
