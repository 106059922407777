import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  SvgIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../../../../components/Dialog/DialogHeader/DialogHeader';
import styles from './ChangeAppLanguage.module.scss';
import { appLanguages } from '../../../../../i18n';
import useSettings from '../../../../../hooks/useSettings/useSettings';
import { useAppDispatch } from '../../../../../redux/hooks';
import assets from '../../../../../assets/assets';

const ChangeAppLanguageDialog = (props: DialogProps) => {
  const { t } = useTranslation();
  const { language, setLanguage } = useSettings();
  const dispatch = useAppDispatch();

  const closeDialog = () => props?.onClose?.({}, 'escapeKeyDown');

  return (
    <Dialog aria-describedby='change-app-language-dialog-title' {...props}>
      <DialogHeader
        onClose={closeDialog}
        title={t('dialogs.changeAppLanguage.title')}
        titleProps={{
          id: 'change-app-language-dialog-title',
        }}
      />

      <DialogContent className={styles['dialog-content']}>
        <Grid container spacing={2}>
          {appLanguages.map((appLanguage) => (
            <Grid item key={appLanguage.locale}>
              <Button
                startIcon={
                  language === appLanguage.locale ? (
                    <SvgIcon>
                      <assets.icons.check />{' '}
                    </SvgIcon>
                  ) : undefined
                }
                onClick={() => {
                  closeDialog();
                  dispatch(setLanguage(appLanguage.locale));
                }}
              >
                {t(appLanguage.name)}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeAppLanguageDialog;
