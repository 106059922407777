import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiListItemIcon: {
  defaultProps?: ComponentsProps['MuiListItemIcon'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiListItemIcon'];
  variants?: ComponentsVariants<Theme>['MuiListItemIcon'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& > svg': {
        fill: theme.palette.text.primary,
      },
    }),
  },
};

export default MuiListItemIcon;
