import { Skeleton, SkeletonProps } from '@mui/material';
import classNames from 'classnames';
import styles from './Token.module.scss';

export interface TokenSkeletonProps extends Partial<SkeletonProps> {}

const TokenSkeleton = ({ className }: TokenSkeletonProps) => {
  return (
    <Skeleton className={classNames(styles['token-skeleton'], className)} />
  );
};

export default TokenSkeleton;
