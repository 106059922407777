import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../../../../components/Dialog/DialogHeader/DialogHeader';
import styles from './GameSettingsDialog.module.scss';
import ApplicationSettings from '../../../../Settings/ApplicationSettings/ApplicationSettings';
import GameSettingsSection from '../../../../Settings/GameSettingsSection/GameSettingsSection';

export interface GameSettingsDialogProps extends DialogProps {}

const GameSettingsDialog = (props: GameSettingsDialogProps) => {
  const { t } = useTranslation();

  const closeDialog = () => props?.onClose?.({}, 'escapeKeyDown');

  return (
    <Dialog {...props}>
      <DialogHeader
        onClose={closeDialog}
        title={t('navigation.more.sections.settings')}
        titleProps={{
          id: 'game-settings-dialog-title',
        }}
      />

      <DialogContent className={styles['dialog-content']}>
        <GameSettingsSection showHeading={false} />

        <Divider className={styles['setting-divider']} />

        <ApplicationSettings showHeading={false} />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog}>{t('actions.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GameSettingsDialog;
