import useUser from '../../hooks/useUser/useUser';
import HomeAuthorized from './HomeAuthorized/HomeAuthorized';
import HomeUnauthorized from './HomeUnauthorized/HomeUnauthorized';

const Home = () => {
  const { user } = useUser();
  const { isSignedIn } = user;

  if (isSignedIn) {
    return <HomeAuthorized />;
  }

  return <HomeUnauthorized />;
};

export default Home;
