import { Typography } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import styles from './FormLayoutFooter.module.scss';

export interface FormLayoutFooterProps extends BoxProps {}

const FormLayoutFooter = ({ className, ...rest }: FormLayoutFooterProps) => {
  return (
    <Box className={classNames(styles['footer'], className)} {...rest}>
      <Typography className={styles['footer-text']}>&copy; Boronu</Typography>
    </Box>
  );
};

export default FormLayoutFooter;
