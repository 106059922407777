import styles from './RecentMatches.module.scss';
import classNames from 'classnames';
import { Skeleton } from '@mui/material';
import { RecentMatchesProps } from './RecentMatches';

export interface RecentMatchesSkeletonProps
  extends Partial<RecentMatchesProps> {}

const RecentMatchesSkeleton = ({ className }: RecentMatchesSkeletonProps) => {
  return (
    <div className={classNames(styles['recent-matches'], className)}>
      <Skeleton
        sx={{ width: '140rem', height: '30rem', marginBottom: '20rem' }}
      />

      <div className={styles['match-list']}>
        {Array.from(Array(30)).map((i) => (
          <Skeleton variant='rounded' key={i} sx={{ height: '490rem' }} />
        ))}
      </div>
    </div>
  );
};

export default RecentMatchesSkeleton;
