import { Box, BoxProps } from '@mui/material';
import styles from './PuzzleSolutions.module.scss';
import classNames from 'classnames';
import PuzzleSolutionCard from '../../../Gameboards/components/MatchSummary/PuzzleSolutionCard/PuzzleSolutionCard';
import FadeInRight from '../../../../components/motion/FadeInRight/FadeInRight';
import { Animatable } from '../../../../types/Animatable';
import { MatchParticipant } from '../../../../types/match/participants/MatchParticipant';
import { Puzzle } from '../../../../types/match/Puzzle';
import { getParticipantsSolvingTime } from './util/getParticipantsSolvingTime';
import { getSolvingTimePrecision } from './util/getSolvingTimePrecision';
import util from '../../../../util/util';
import ShortestSolutionAccordion from '../ShortestSolutionAccordion/ShortestSolutionAccordion';
import { GameMode } from '../../../../types/Gamemode';

export interface PuzzleSolutionsProps extends BoxProps, Animatable {
  gameMode: GameMode;
  matchStartDate?: Date;
  participants: MatchParticipant[];
  puzzle: Puzzle;
  autoExpandShortestSolutionCard?: boolean;
}

const PuzzleSolutions = ({
  animate,
  className,
  gameMode,
  matchStartDate,
  participants,
  puzzle,
  autoExpandShortestSolutionCard = true,
  ...rest
}: PuzzleSolutionsProps) => {
  const { shortestSolution } = puzzle;

  return (
    <Box
      className={classNames(styles['puzzle-solutions'], className)}
      {...rest}
    >
      {shortestSolution && (
        <ShortestSolutionAccordion
          className={styles['shortest-solution']}
          expanded={autoExpandShortestSolutionCard}
          expression={shortestSolution.expression}
          expressionValue={shortestSolution.expressionValue}
          goalDistance={Math.abs(
            shortestSolution.expressionValue - puzzle.goal
          )}
        />
      )}

      <Box className={classNames(styles['player-solutions'], className)}>
        {participants.length > 0 &&
          participants.map((participant, i) => {
            if (
              participant.puzzleSolution === undefined &&
              !('placement' in participant)
            ) {
              return null;
            }

            const { expressionValue, error } = participant.puzzleSolution || {};
            const preMatchRating =
              ('preMatchRating' in participant && participant.preMatchRating) ||
              undefined;
            const postMatchRating =
              ('postMatchRating' in participant &&
                participant.postMatchRating) ||
              undefined;
            const matchOutcome = util.matches.getMatchOutcome(
              participant.username,
              participants
            );
            const matchOutcomeVariant =
              util.matches.getMatchOutcomeVariant(participants);
            const timeColor =
              matchOutcome !== 'draw' && matchOutcomeVariant === 'time'
                ? matchOutcome
                : undefined;

            return animate ? (
              <FadeInRight key={participant.username + i}>
                <PuzzleSolutionCard
                  animate
                  distance={
                    expressionValue !== undefined
                      ? Math.abs(expressionValue - puzzle.goal)
                      : undefined
                  }
                  displayTime={gameMode !== 'practice'}
                  error={error}
                  forfeit={
                    participant.status &&
                    participant.status.toLowerCase() === 'forfeit'
                  }
                  matchOutcome={matchOutcome}
                  rating={postMatchRating}
                  ratingChange={
                    preMatchRating !== undefined &&
                    postMatchRating !== undefined
                      ? postMatchRating - preMatchRating
                      : undefined
                  }
                  solution={participant.puzzleSolution}
                  time={
                    matchStartDate && gameMode !== 'practice'
                      ? getParticipantsSolvingTime(
                          participant,
                          matchStartDate,
                          getSolvingTimePrecision(participants, matchStartDate)
                        )
                      : undefined
                  }
                  title={participant.username}
                  timeColor={timeColor}
                />
              </FadeInRight>
            ) : (
              <PuzzleSolutionCard
                key={participant.username + i}
                distance={
                  expressionValue !== undefined
                    ? Math.abs(expressionValue - puzzle.goal)
                    : undefined
                }
                displayTime={gameMode !== 'practice'}
                error={error}
                forfeit={
                  participant.status &&
                  participant.status.toLowerCase() === 'forfeit'
                }
                matchOutcome={matchOutcome}
                rating={postMatchRating}
                ratingChange={
                  preMatchRating !== undefined && postMatchRating !== undefined
                    ? postMatchRating - preMatchRating
                    : undefined
                }
                solution={participant.puzzleSolution}
                time={
                  matchStartDate && gameMode !== 'practice'
                    ? getParticipantsSolvingTime(
                        participant,
                        matchStartDate,
                        getSolvingTimePrecision(participants, matchStartDate)
                      )
                    : undefined
                }
                timeColor={timeColor}
                title={participant.username}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default PuzzleSolutions;
