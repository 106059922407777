import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import getExpressionErrorTranslationFileKey from './util/getExpressionErrorTranslationFileKey';
import { ExpressionError as ExpressionErrorType } from '../../../../../../../types/match/ExpressionError';
import { Box, BoxProps, SvgIcon, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './ExpressionError.module.scss';
import assets from '../../../../../../../assets/assets';

export interface ExpressionErrorProps extends BoxProps {
  error: ExpressionErrorType;
}

const ExpressionError = ({
  error,
  className,
  ...rest
}: ExpressionErrorProps) => {
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(styles['expression-error'], className)}
      {...rest}
    >
      <SvgIcon className={styles['error-icon']}>
        <assets.icons.cancel_filled />
      </SvgIcon>
      <Typography className={styles['error-text']}>
        {t(getExpressionErrorTranslationFileKey(error))}
      </Typography>
    </Box>
  );
};

export default memo(ExpressionError);
