/**
 * Formats goal distance value into a display-friendly format.
 *
 * @param distance - Solution's goal distance.
 * @returns Display-friendly goal distance.
 *
 */
export const formatGoalDistance = (distance: number): string => {
  if (distance < 1_000) {
    return String(distance);
  } else if (distance > 10_000) {
    return '9K+';
  }

  return `${Math.floor(distance / 1_000)}K${distance % 1_000 ? '+' : ''}`;
};
