import { SvgIcon } from '@mui/material';
import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Operator from './Operator/Operator';
import styles from './Operators.module.scss';
import GAMEBOARD_OPERATORS from './util';

export interface OperatorsProps extends BoxProps {
  onOperatorClick?: (operator: string) => void;
}

const Operators = ({ className, onOperatorClick, ...rest }: OperatorsProps) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles['operators'], className)} {...rest}>
      {GAMEBOARD_OPERATORS.map((operator) => (
        <Operator
          aria-label={t(operator.tooltip)}
          key={operator.value}
          onClick={() => onOperatorClick?.(operator.value)}
        >
          {operator?.icon ? (
            <SvgIcon className={classNames(styles['operator-icon'])}>
              <operator.icon />
            </SvgIcon>
          ) : (
            operator.value
          )}
        </Operator>
      ))}
    </Box>
  );
};

export default Operators;
