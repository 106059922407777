import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiAppBar: {
  defaultProps?: ComponentsProps['MuiAppBar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAppBar'];
  variants?: ComponentsVariants<Theme>['MuiAppBar'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'unset',
    }),
  },
  defaultProps: {
    elevation: 0,
  },
};

export default MuiAppBar;
