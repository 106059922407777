import { useParams } from 'react-router-dom';
import Page from '../../components/Page/Page';
import TimeoutRender from '../../components/TimeoutRender/TimeoutRender';
import { useGetMatchQuery } from '../../redux/slices/api/api';
import MatchSkeleton from './components/Match/MatchSkeleton';
import DefaultMatch from './components/Match/Match';
import PageLoadingError from '../ErrorPage/PageLoadingError/PageLoadingError';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const Match = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.navigation.match'));

  const { id } = useParams<{
    id: string;
  }>();

  const { data: match, isError } = useGetMatchQuery(
    { matchId: id! },
    { skip: !id }
  );

  if (isError) {
    return <PageLoadingError />;
  }

  if (!match) {
    return (
      <TimeoutRender>
        <Page>
          <MatchSkeleton />
        </Page>
      </TimeoutRender>
    );
  }

  return (
    <Page>
      <DefaultMatch data={match} />
    </Page>
  );
};

export default Match;
