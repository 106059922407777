import { Typography, TypographyProps } from '@mui/material';
import classNames from 'classnames';

export interface RatingChangeProps extends TypographyProps {
  ratingChange: number;
}

const RatingChange = ({
  className,
  ratingChange,
  ...rest
}: RatingChangeProps) => {
  return (
    <Typography
      className={classNames(
        ratingChange < 0 && 'color-match-outcome-defeat',
        ratingChange > 0 && 'color-match-outcome-victory',
        className
      )}
      {...rest}
    >
      {ratingChange < 1 ? ratingChange : `+${ratingChange}`}
    </Typography>
  );
};

export default RatingChange;
