import { enUS, srLatn, sr } from 'date-fns/locale';
import { i18nLocale } from '../../i18n';
import { Locale } from 'date-fns';

/**
 * Calculates appropriate date-fns Locale for a given i18nLocale.
 *
 * @param locale - i18nLocale to calculate appropriate date-fns locale for.
 * @returns date-fns Locale derived from the given i18nLocale.
 *
 */
const getDateFnsLocale = (locale: i18nLocale): Locale | undefined => {
  switch (locale) {
    case 'en-US':
      return enUS;
    case 'sr-Latn':
      return srLatn;
    case 'sr-Cyrl':
      return sr;
  }
};

export default getDateFnsLocale;
