import classNames from 'classnames';
import styles from './Goal.module.scss';
import { GoalSize } from './types/GoalSize';
import { Typography, TypographyProps } from '@mui/material';

export interface GoalProps extends TypographyProps {
  size?: GoalSize;
  value: number;
}

const Goal = ({ className, size = 'medium', value, ...rest }: GoalProps) => {
  return (
    <Typography
      className={classNames(styles['goal'], styles[`size-${size}`], className)}
      {...rest}
    >
      {value}
    </Typography>
  );
};

export default Goal;
