import classNames from 'classnames';
import styles from './Goalboard.module.scss';
import { Box } from '@mui/material';
import { GoalboardProps } from './Goalboard';
import GoalSkeleton from '../../../../../components/Puzzle/components/Goal/GoalSkeleton';

export interface GoalboardSkeletonProps extends Partial<GoalboardProps> {}

const GoalboardSkeleton = ({ className, ...rest }: GoalboardSkeletonProps) => {
  return (
    <Box className={classNames(styles['goalboard'], className)} {...rest}>
      <GoalSkeleton size='extra-large' />
    </Box>
  );
};

export default GoalboardSkeleton;
