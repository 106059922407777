import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './LookingForOpponnent.module.scss';
import classNames from 'classnames';

export interface LookingForOpponentProps extends BoxProps {
  cancelButtonProps?: Partial<ButtonProps>;
  headline?: string;
}

const LookingForOpponent = ({
  cancelButtonProps,
  headline,
  className,
  ...rest
}: LookingForOpponentProps) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles['container'], className)} {...rest}>
      <Box className={styles['progress-container']}>
        <CircularProgress />
        <Typography className={styles['looking-for-opponnent-message']}>
          {headline || t('navigation.matchmaking.lookingForOpponent')}
        </Typography>
      </Box>
      <Button
        fullWidth
        color='primary'
        {...cancelButtonProps}
        className={classNames(
          styles['cancel-button'],
          cancelButtonProps?.className
        )}
      >
        {cancelButtonProps?.children || t('actions.cancel')}
      </Button>
    </Box>
  );
};

export default LookingForOpponent;
