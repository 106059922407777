import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';
import styles from './Token.module.scss';

export interface TokenProps extends ButtonProps {}

const Token = ({ className, children, ...rest }: TokenProps) => {
  return (
    <Button className={classNames(styles['token'], className)} {...rest}>
      {children}
    </Button>
  );
};

export default Token;
