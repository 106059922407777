/**
 * Checks if given string is a representation of a number.
 *
 * @param string - String to perform the number representation check of.
 * @returns a Boolean value that indicates whether given string is a string
 * representation of a number.
 */
const isNum = (string: string): boolean => {
  if (string === undefined || string === null) return false;
  if (string.trim() === '') return false;

  return !isNaN(Number(string));
};

export default isNum;
