import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiIcon: {
  defaultProps?: ComponentsProps['MuiIcon'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIcon'];
  variants?: ComponentsVariants<Theme>['MuiIcon'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& > svg': {
        fill: theme.palette.text.primary,
      },
    }),
  },
};

export default MuiIcon;
