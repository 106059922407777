import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiLinearProgress: {
  defaultProps?: ComponentsProps['MuiLinearProgress'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiLinearProgress'];
  variants?: ComponentsVariants<Theme>['MuiLinearProgress'];
} = {
  styleOverrides: {
    root: {
      borderRadius: 9999,
      height: '5rem',
    },
    bar: {
      borderRadius: 9999,
    },
  },
};

export default MuiLinearProgress;
