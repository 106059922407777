import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import SignupForm from './components/SignupForm/SignupForm';
import signinStyles from '../Signin/Signin.module.scss';
import FormLayoutContainer from '../Signin/components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from '../Signin/components/FormLayoutFooter/FormLayoutFooter';

const Signup = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('actions.signup')}`);

  return (
    <Page className={signinStyles['page']}>
      <FormLayoutContainer>
        <SignupForm />

        <FormLayoutFooter />
      </FormLayoutContainer>
    </Page>
  );
};

export default Signup;
