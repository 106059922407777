import { Navigate } from 'react-router-dom';
import useUser from '../../hooks/useUser/useUser';

// Create a private route component that checks if the user is signed in
function PrivateRoute({ element }: { element: React.ReactNode }) {
  const { user } = useUser();
  const { isSignedIn } = user;

  // If the user is signed in, render the element, otherwise navigate to the sign-in page
  return isSignedIn ? <>{element}</> : <Navigate to='/signin' />;
}

export default PrivateRoute;
