import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import { usePasswordResetMutation } from '../../../../redux/slices/api/api';
import signinFormStyles from '../../../Signin/components/SigninForm/SigninForm.module.scss';
import FormContainer from '../../../Signin/components/FormContainer/FormContainer';
import FormLogoHeader from '../../../Signin/components/FormLogoHeader/FormLogoHeader';
import FormBody from '../../../Signin/components/FormBody/FormBody';
import { NavLink } from 'react-router-dom';
import styles from './ResetPasswordForm.module.scss';

interface ResetPasswordFormProps extends BoxProps {}

const ResetPasswordForm = ({ className, ...rest }: ResetPasswordFormProps) => {
  const { t } = useTranslation();

  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [emailOrUsernameErrorText, setEmailOrUsernameErrorText] = useState('');

  const [
    passwordReset,
    {
      isLoading: isLoadingPasswordReset,
      isSuccess: isSuccessPasswordReset,
      isError: isErrorPasswordReset,
    },
  ] = usePasswordResetMutation();

  useEffect(() => {
    if (emailOrUsername) {
      setEmailOrUsernameErrorText('');
    }
  }, [emailOrUsername]);

  useEffect(() => {
    if (isErrorPasswordReset) {
      setEmailOrUsernameErrorText(
        t('navigation.resetPassword.resetPasswordFailed')
      );
    }
  }, [isErrorPasswordReset, setEmailOrUsernameErrorText, t]);

  const handlePasswordReset = (e: any) => {
    e.preventDefault();

    if (!isValidInput()) {
      return;
    }

    passwordReset({ emailOrUsername });
  };

  const isValidInput = () => {
    if (emailOrUsername === '') {
      setEmailOrUsernameErrorText(
        t('errors.validation.emailOrUsername.emptyEmailOrUsername')
      );

      return false;
    } else {
      setEmailOrUsernameErrorText('');
    }

    return true;
  };

  if (isSuccessPasswordReset) {
    return (
      <FormContainer {...rest}>
        <FormLogoHeader title={t('actions.resetYourPassword')} />
        <FormBody>
          <Box className={styles['form-introduction-text']}>
            <Typography>
              {t('navigation.resetPassword.instructionsSent')}
            </Typography>
          </Box>
          <Button
            className={styles['reset-password-button']}
            color='primary'
            component={NavLink}
            fullWidth
            size='large'
            to='/signin'
          >
            {t('actions.returnToSignin')}
          </Button>
        </FormBody>
      </FormContainer>
    );
  }

  return (
    <FormContainer {...rest}>
      <FormLogoHeader title={t('actions.resetYourPassword')} />

      <FormBody>
        <Box className={styles['form-introduction-text']}>
          <Typography>{t('navigation.resetPassword.instructions')}</Typography>
        </Box>

        <form
          autoComplete='off'
          noValidate
          onSubmit={handlePasswordReset}
          className={signinFormStyles['form']}
        >
          <TextField
            autoComplete='off'
            autoFocus
            className={signinFormStyles['input-text-field']}
            error={Boolean(emailOrUsernameErrorText)}
            fullWidth
            helperText={emailOrUsernameErrorText}
            label={t('forms.emailOrUsername')}
            name='emailOrUsername'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmailOrUsername(e.target.value)
            }
            spellCheck={false}
            value={emailOrUsername}
            variant='filled'
          />

          <Button
            disabled={isLoadingPasswordReset}
            className={signinFormStyles['signin-button']}
            color='primary'
            fullWidth
            type='submit'
            size='large'
          >
            {t('actions.resetPassword')}
          </Button>
        </form>
      </FormBody>

      <Link underline='hover' href='/signin'>
        {t('actions.returnToSignin')}
      </Link>
    </FormContainer>
  );
};

export default ResetPasswordForm;
