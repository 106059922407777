import { MatchParticipant } from '../../../../../types/match/participants/MatchParticipant';

export const getMatchOutcomeVariant = (
  participants: MatchParticipant[]
): string | undefined => {
  const solutions = participants
    .map((p) => p.puzzleSolution)
    .filter((sol) => sol);

  if (solutions.length === 0) {
    return undefined;
  } else if (solutions.length === 1) {
    return 'distance';
  }

  const uniqueSolutions = new Set(solutions.map((sol) => sol?.expressionValue));

  if (uniqueSolutions.size === 1) {
    return 'time';
  } else {
    return 'distance';
  }
};
