import classNames from 'classnames';
import styles from './Token.module.scss';
import { Typography, TypographyProps } from '@mui/material';
import { TokenSize } from './types/TokenSize';

export interface TokenProps extends TypographyProps {
  size?: TokenSize;
  value: number;
}

const Token = ({ className, size = 'medium', value, ...rest }: TokenProps) => {
  return (
    <Typography
      className={classNames(styles['token'], styles[`size-${size}`], className)}
      {...rest}
    >
      {value}
    </Typography>
  );
};

export default Token;
