import { useTranslation } from 'react-i18next';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import util from '../../../../util/util';
import config from '../../../../config/config';
import styles from '../EditUsernameDialog/EditUsernameDialog.module.scss';
import { useUpdatePasswordMutation } from '../../../../redux/slices/api/api';
import { ErrorResponse } from '../../../../redux/slices/api/types/ErrorResponse';

const EditPasswordDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

  const [
    updatePassword,
    {
      isLoading: isLoadingUpdatePassword,
      isSuccess: isSuccessUpdatePassword,
      isError: isErrorUpdatePassword,
      error: updatePasswordError,
    },
  ] = useUpdatePasswordMutation();

  useEffect(() => {
    setCurrentPasswordErrorText('');
  }, [currentPassword]);

  useEffect(() => {
    setNewPasswordErrorText('');
  }, [newPassword]);

  useEffect(() => {
    setConfirmPasswordErrorText('');
  }, [confirmPassword]);

  const handleUpdatePassword = () => {
    if (currentPassword.length === 0) {
      setCurrentPasswordErrorText(
        t('errors.validation.password.emptyPassword')
      );
      return;
    }

    if (newPassword.length === 0) {
      setNewPasswordErrorText(t('errors.validation.password.emptyPassword'));
      return;
    } else if (!util.validation.isValidPasswordLength(newPassword)) {
      setNewPasswordErrorText(
        t('errors.validation.password.invalidPasswordLength', {
          minLength: config.account.password.minLength,
        })
      );
      return;
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.emptyPassword')
      );
      return;
    } else if (!util.validation.isValidPasswordLength(confirmPassword)) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.invalidPasswordLength', {
          minLength: config.account.password.minLength,
        })
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordErrorText(
        t('errors.validation.password.confirmPasswordMismatch')
      );
      return;
    }

    updatePassword({ currentPassword, newPassword, confirmPassword });
  };

  useEffect(() => {
    if (isErrorUpdatePassword) {
      if (
        updatePasswordError &&
        'data' in updatePasswordError &&
        updatePasswordError.data !== null
      ) {
        const response = updatePasswordError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          switch (translationFileKey) {
            case 'errors.validation.password.incorrectPassword':
              setCurrentPasswordErrorText(t(translationFileKey));
              return;
            default:
              setConfirmPasswordErrorText(t(translationFileKey));
              return;
          }
        }
      }

      setConfirmPasswordErrorText(t('errors.somethingWentWrong'));
    }
  }, [isErrorUpdatePassword, updatePasswordError, t]);

  const closeDialog = () => props?.onClose?.({}, 'escapeKeyDown');

  return (
    <Dialog aria-describedby='edit-password-dialog-title' {...props}>
      {isSuccessUpdatePassword ? (
        <>
          <DialogHeader
            onClose={closeDialog}
            title={t(
              'navigation.more.account.dialogs.editPasswordSuccess.title'
            )}
            titleProps={{
              id: 'edit-password-dialog-title',
            }}
          />

          <DialogContent>
            <Typography>
              {t('navigation.more.account.dialogs.editPasswordSuccess.body')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={closeDialog}>
              {t('actions.ok')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogHeader
            onClose={closeDialog}
            title={t('navigation.navigation.editPassword')}
            titleProps={{
              id: 'edit-password-dialog-title',
            }}
          />

          <DialogContent>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              className={styles['inputs-container']}
            >
              <PasswordField
                autoFocus
                disabled={isLoadingUpdatePassword}
                error={Boolean(currentPasswordErrorText)}
                fullWidth
                helperText={currentPasswordErrorText}
                label={t('forms.currentPassword')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCurrentPassword(e.target.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleUpdatePassword();
                  }
                }}
                spellCheck={false}
                value={currentPassword}
              />

              <PasswordField
                disabled={isLoadingUpdatePassword}
                error={Boolean(newPasswordErrorText)}
                fullWidth
                helperText={newPasswordErrorText}
                label={t('forms.newPassword')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewPassword(e.target.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleUpdatePassword();
                  }
                }}
                spellCheck={false}
                value={newPassword}
              />

              <PasswordField
                disabled={isLoadingUpdatePassword}
                error={Boolean(confirmPasswordErrorText)}
                fullWidth
                helperText={confirmPasswordErrorText}
                label={t('forms.confirmPassword')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleUpdatePassword();
                  }
                }}
                spellCheck={false}
                value={confirmPassword}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isLoadingUpdatePassword}
              onClick={handleUpdatePassword}
            >
              {t('actions.submit')}
            </Button>

            <Button disabled={isLoadingUpdatePassword} onClick={closeDialog}>
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditPasswordDialog;
