import gameModes from './sr-Cyrl/game/gameModes.json';
import matchOutcomes from './sr-Cyrl/game/matchOutcomes.json';
import puzzle from './sr-Cyrl/game/puzzle.json';

import faq from './sr-Cyrl/navigation/faq.json';
import friends from './sr-Cyrl/navigation/friends.json';
import home from './sr-Cyrl/navigation/home.json';
import matchmaking from './sr-Cyrl/navigation/matchmaking.json';
import more from './sr-Cyrl/navigation/more.json';
import navigation from './sr-Cyrl/navigation/navigation.json';
import play from './sr-Cyrl/navigation/play.json';
import profile from './sr-Cyrl/navigation/profile.json';
import resetPassword from './sr-Cyrl/navigation/resetPassword.json';
import signin from './sr-Cyrl/navigation/signin.json';
import signup from './sr-Cyrl/navigation/signup.json';
import standings from './sr-Cyrl/navigation/standings.json';
import updateEmail from './sr-Cyrl/navigation/updateEmail.json';

import accountSettings from './sr-Cyrl/settings/accountSettings.json';
import appThemeSettings from './sr-Cyrl/settings/appThemeSettings.json';
import languageSettings from './sr-Cyrl/settings/languageSettings.json';
import game from './sr-Cyrl/settings/game.json';

import actions from './sr-Cyrl/actions.json';
import dialogs from './sr-Cyrl/dialogs.json';
import errors from './sr-Cyrl/errors.json';
import forms from './sr-Cyrl/forms.json';
import validation from './sr-Cyrl/validation.json';

const srCyrl = {
  game: {
    gameModes,
    matchOutcomes,
    puzzle,
  },
  navigation: {
    faq,
    friends,
    home,
    matchmaking,
    more,
    navigation,
    play,
    profile,
    resetPassword,
    signin,
    signup,
    standings,
    updateEmail,
  },
  settings: {
    accountSettings,
    appThemeSettings,
    languageSettings,
    game,
  },
  actions,
  dialogs,
  errors,
  forms,
  validation,
};

export default srCyrl;
