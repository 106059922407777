import styles from './FormBody.module.scss';
import classNames from 'classnames';
import { Box, BoxProps } from '@mui/system';

interface FormBodyProps extends BoxProps {}

const FormBody = ({ children, className, ...rest }: FormBodyProps) => {
  return (
    <Box className={classNames(styles['form-body'], className)} {...rest}>
      {children}
    </Box>
  );
};

export default FormBody;
