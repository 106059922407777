import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import shadows from '@mui/material/styles/shadows';

const MuiDialog: {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants<Theme>['MuiDialog'];
} = {
  defaultProps: {
    fullWidth: true,
    PaperProps: {
      elevation: 0,
    },
  },
  styleOverrides: {
    paper: {
      boxShadow: shadows[5],
    },
  },
};

export default MuiDialog;
