import {
  Card,
  CardProps,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import styles from './Expressionboard.module.scss';
import assets from '../../../../../../../assets/assets';

export interface ExpressionboardProps extends CardProps {
  className?: string;
  expression: string;
  expressionAppendix?: string;
  onClearExpression: VoidFunction;
}

const Expressionboard = ({
  className,
  expression,
  expressionAppendix,
  onClearExpression,
  ...rest
}: ExpressionboardProps) => {
  return (
    <Card
      className={classNames(styles['expressionboard'], className)}
      {...rest}
    >
      <IconButton
        disabled={expression === ''}
        className={styles['expression-clear-button']}
        onClick={onClearExpression}
      >
        <SvgIcon>
          <assets.icons.close />
        </SvgIcon>
      </IconButton>

      <Typography className={styles['expression']}>
        {expression}
        <Typography className={styles['expression-appendix']}>
          {expression ? expressionAppendix : 0}
        </Typography>
      </Typography>
    </Card>
  );
};

export default Expressionboard;
