import styles from './RecentMatches.module.scss';
import classNames from 'classnames';
import { Box, BoxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import GamemodesChipGroup from '../GamemodesChipGroup/GamemodesChipGroup';
import MatchCard from '../MatchCard/MatchCard';
import { Match } from '../../../../types/match/Match';

export interface RecentMatchesProps extends BoxProps {
  matches: Match[];
  matchHistoryOwner: string;
}

const RecentMatches = ({
  className,
  matches,
  matchHistoryOwner,
  ...rest
}: RecentMatchesProps) => {
  const { t } = useTranslation();

  const [gamemodeFilter, setGamemodeFilter] = useState('all');
  matches = (matches || []).filter((m) =>
    [m.gameMode, 'all'].includes(gamemodeFilter)
  );

  return (
    <Box className={classNames(styles['recent-matches'], className)} {...rest}>
      <Typography variant='page-h2'>
        {t('navigation.profile.recentMatches')}
      </Typography>

      <GamemodesChipGroup onChange={setGamemodeFilter} />

      <div className={styles['match-list']}>
        {matches.length ? (
          matches.map((match: Match) => (
            <MatchCard
              key={match.id}
              data={match}
              matchPerspectiveOwner={matchHistoryOwner}
            />
          ))
        ) : (
          <Typography>
            {t('navigation.profile.noPlayedMatchesFound')}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default RecentMatches;
