import { ValidationError } from '../../redux/slices/api/types/error/ValidationError';

const getValidationErrorTranslationKey = (validationError: ValidationError) => {
  switch (validationError) {
    // emailOrUsername
    case 'Empty Email or Username':
      return 'errors.validation.emailOrUsername';
    case 'Invalid Email or Username':
      return 'errors.validation.invalidEmailOrUsername';
    // email
    case 'Empty Email':
      return 'errors.validation.email.emptyEmail';
    case 'Invalid Email':
      return 'errors.validation.email.invalidEmail';
    case 'Email Unavailable':
      return 'errors.validation.email.emailUnavailable';
    case 'Email Change Frequency Exceeded':
      return 'errors.validation.email.emailChangeFrequencyExceeded';
    // expression
    case 'Negative Numbers':
      return 'errors.validation.expression.negativeNumbers';
    case 'Division With Remainder':
      return 'errors.validation.expression.divisionWithRemainder';
    case 'Division By Zero':
      return 'errors.validation.expression.divisionByZero';
    case 'Mismatched Parentheses':
      return 'errors.validation.expression.mismatchedParentheses';
    case 'Misplaced Operator':
      return 'errors.validation.expression.misplacedOperator';
    case 'Invalid Expression':
      return 'errors.validation.expression.invalidExpression';
    // password
    case 'Empty Password':
      return 'errors.validation.password.emptyPassword';
    case 'Invalid Password':
      return 'errors.validation.password.invalidPassword';
    case 'Invalid Password Length':
      return 'errors.validation.password.invalidPasswordLength';
    case 'Incorrect Password':
      return 'errors.validation.password.incorrectPassword';
    case 'Password Unchanged':
      return 'errors.validation.password.passwordUnchanged';
    case 'Invalid Password Reset Token':
      return 'errors.validation.password.invalidPasswordResetToken';
    // username
    case 'Empty Username':
      return 'errors.validation.username.emptyUsername';
    case 'Invalid Username Length':
      return 'errors.validation.username.invalidUsernameLength';
    case 'Invalid Username':
      return 'errors.validation.username.invalidUsername';
    case 'Invalid Username Format':
      return 'errors.validation.username.invalidUsernameFormat';
    case 'Username Unavailable':
      return 'errors.validation.username.usernameUnavailable';
    case 'Username Change Frequency Exceeded':
      return 'errors.validation.username.usernameChangeFrequencyExceeded';
  }
};

export default getValidationErrorTranslationKey;
