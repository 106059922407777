import styles from './FormLayoutContainer.module.scss';
import classNames from 'classnames';
import { Box, BoxProps } from '@mui/system';

interface FormLayoutContainerProps extends BoxProps {}

const FormLayoutContainer = ({
  children,
  className,
  ...rest
}: FormLayoutContainerProps) => {
  return (
    <Box
      className={classNames(styles['form-layout-container'], className)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FormLayoutContainer;
