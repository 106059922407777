import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import assets from '../../../../../assets/assets';
import NavigationButton from '../NavigationButton/NavigationButton';
import styles from './MainNavigation.module.scss';
import { Box, BoxProps } from '@mui/material';
import useUser from '../../../../../hooks/useUser/useUser';

export interface MainNavigationProps extends BoxProps {}

const MainNavigation = ({ className, ...rest }: MainNavigationProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { type: userType } = user;

  return (
    <Box className={classNames(styles['main-navigation'], className)} {...rest}>
      <NavLink
        aria-label={'play'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/play'}
      >
        {({ isActive }) => (
          <NavigationButton
            className={styles['navigation-button']}
            icon={
              isActive ? (
                <assets.icons.boronu_icon_filled />
              ) : (
                <assets.icons.boronu_icon />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.play')}
          />
        )}
      </NavLink>

      <NavLink
        aria-label={'profile'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/profile'}
      >
        {({ isActive }) => (
          <NavigationButton
            className={styles['navigation-button']}
            icon={
              isActive ? (
                <assets.icons.account_circle_filled />
              ) : (
                <assets.icons.account_circle />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.profile')}
          />
        )}
      </NavLink>

      {userType === 'REGISTERED' && (
        <NavLink
          aria-label={'friends'}
          className={(active) =>
            classNames(styles['nav-link'], {
              [styles['active']]: active.isActive,
            })
          }
          to={'/friends'}
        >
          {({ isActive }) => (
            <NavigationButton
              className={styles['navigation-button']}
              icon={
                isActive ? (
                  <assets.icons.group_filled />
                ) : (
                  <assets.icons.group />
                )
              }
              isActive={isActive}
              title={t('navigation.navigation.friends')}
            />
          )}
        </NavLink>
      )}

      <NavLink
        aria-label={'standings'}
        className={(active) =>
          classNames(styles['nav-link'], {
            [styles['active']]: active.isActive,
          })
        }
        to={'/standings'}
      >
        {({ isActive }) => (
          <NavigationButton
            className={styles['navigation-button']}
            icon={
              isActive ? (
                <assets.icons.leaderboard_filled />
              ) : (
                <assets.icons.leaderboard />
              )
            }
            isActive={isActive}
            title={t('navigation.navigation.standings')}
          />
        )}
      </NavLink>
    </Box>
  );
};

export default MainNavigation;
