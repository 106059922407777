import { Box } from '@mui/material';
import styles from './MatchHeader.module.scss';
import { MatchHeaderProps } from './MatchHeader';
import MatchHeadingSkeleton from '../MatchHeading/MatchHeadingSkeleton';

export interface MatchHeaderSkeletonProps extends Partial<MatchHeaderProps> {}

const MatchHeaderSkeleton = () => {
  return (
    <Box className={styles['match-header']}>
      <MatchHeadingSkeleton />
    </Box>
  );
};

export default MatchHeaderSkeleton;
