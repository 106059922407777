import { Skeleton } from '@mui/material';
import { CloseMatchSummaryButtonProps } from './CloseMatchSummaryButton';

export interface CloseMatchSummaryButtonSkeletonProps
  extends Partial<CloseMatchSummaryButtonProps> {}

const CloseMatchSummaryButtonSkeleton = ({
  className,
  ...rest
}: CloseMatchSummaryButtonSkeletonProps) => {
  return (
    <Skeleton variant='rounded' sx={{ width: '56rem', height: '56rem' }} />
  );
};

export default CloseMatchSummaryButtonSkeleton;
