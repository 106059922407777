import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  BoxProps,
  Box,
  SvgIcon,
  Typography,
} from '@mui/material';
import styles from './ItemPicker.module.scss';
import { useTranslation } from 'react-i18next';
import SearchField, {
  SearchFieldProps,
} from '../../../../components/SearchField/SearchField';
import classNames from 'classnames';
import assets from '../../../../assets/assets';

export interface ItemPickerProps extends BoxProps {
  items: string[];
  onPick: (item: string | null) => void;
  searchFieldProps?: Partial<SearchFieldProps>;
  noItemsFoundWarning?: string;
}

const ItemPicker: React.FC<ItemPickerProps> = ({
  className,
  items,
  onPick,
  searchFieldProps: searchFrieldProps,
  noItemsFoundWarning = 'No items found.',
  ...rest
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setFilteredItems(
      items.filter((items) => items.toLowerCase().includes(term.toLowerCase()))
    );
  };

  const handlePick = (item: string) => {
    selectedItem === item ? setSelectedItem(null) : setSelectedItem(item);
  };

  useEffect(() => {
    onPick(selectedItem);
  }, [selectedItem, onPick]);

  return (
    <Box className={classNames(styles['list-container'], className)} {...rest}>
      <Box className={styles['actions']}>
        <SearchField
          className={styles['search-field']}
          value={searchTerm}
          onChange={handleSearch}
          placeholder={t('actions.search')}
          {...searchFrieldProps}
        />
      </Box>

      <List className={styles['item-list']}>
        {filteredItems.length === 0 && (
          <Typography className={styles['no-items-text']}>
            {noItemsFoundWarning}
          </Typography>
        )}

        {filteredItems.map((item) => (
          <ListItem
            key={item}
            className={classNames(
              styles['list-item'],
              selectedItem === item ? styles['selected'] : undefined
            )}
            onClick={() => handlePick(item)}
          >
            <ListItemText className={styles['list-item-text']} primary={item} />
            {selectedItem === item && (
              <SvgIcon>
                <assets.icons.check />
              </SvgIcon>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ItemPicker;
