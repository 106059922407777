import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '@mui/material';
import classNames from 'classnames';
import { MatchOutcome } from '../../../../types/match/MatchOutcome';
import styles from './MatchOutcomeChip.module.scss';

export interface MatchOutcomeChipProps extends TypographyProps {
  showBorder?: boolean;
  matchOutcome: MatchOutcome;
}

const MatchOutcomeChip = ({
  showBorder = false,
  matchOutcome,
  className,
  ...rest
}: MatchOutcomeChipProps) => {
  const { t } = useTranslation();

  return (
    <Typography
      className={classNames(
        styles['match-outcome-chip'],
        showBorder && styles['border'],
        `color-match-outcome-${matchOutcome}`,
        `border-color-match-outcome-${matchOutcome}`,
        className
      )}
      {...rest}
    >
      {t(`game.matchOutcomes.${matchOutcome}`)}
    </Typography>
  );
};

export default MatchOutcomeChip;
