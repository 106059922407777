import { differenceInMilliseconds } from 'date-fns';
import { getFormattedSolvingTime } from './getFormattedSolvingTime';
import { MatchParticipant } from '../../../../../types/match/participants/MatchParticipant';

/**
 * Calculates the solving time of a match participant.
 *
 * @param {MatchParticipant} participant - The participant whose solving time is to be calculated.
 * @param {Date} matchStartDate - The start date and time of the match.
 * @param {number} [secondsFractionDigits] - Optional. The number of digits after the decimal point for seconds.
 * @returns {string | undefined} The solving time as a formatted string in 'Xm Ys' format, or undefined if the participant hasn't submitted a solution.
 */
export const getParticipantsSolvingTime = (
  participant: MatchParticipant,
  matchStartDate: Date,
  secondsFractionDigits?: number
): string | undefined => {
  if (
    participant.puzzleSolution &&
    'submittedAt' in participant.puzzleSolution &&
    participant.puzzleSolution.submittedAt
  ) {
    const timeInMillis = differenceInMilliseconds(
      new Date(participant.puzzleSolution.submittedAt),
      matchStartDate
    );

    return getFormattedSolvingTime(timeInMillis, secondsFractionDigits);
  }

  // Return undefined if the participant hasn't submitted a solution
  return undefined;
};
