import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import Puzzle from '../../../../components/Puzzle/Puzzle';
import util from '../../../../util/util';
import styles from './HowToPlayDialog.module.scss';
import { Puzzle as PuzzleType } from '../../../../types/match/Puzzle';

const HowToPlayDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const closeDialog = () => props?.onClose?.({}, 'escapeKeyDown');

  const puzzle: PuzzleType = {
    goal: 12,
    tokens: [3, 4, 5, 6, 8, 9],
  };

  const sampleExpressions = [
    util.strings.getPrettyExpressionString('8 + 4'),
    util.strings.getPrettyExpressionString('4 * 3'),
    util.strings.getPrettyExpressionString('9 + 8 - 5'),
    util.strings.getPrettyExpressionString('8 * 6 / 4'),
  ];

  return (
    <Dialog aria-describedby='how-to-play-dialog-title' {...props}>
      <DialogHeader
        onClose={closeDialog}
        title={t('dialogs.howToPlay.title')}
        titleProps={{
          id: 'how-to-play-dialog-title',
        }}
      />

      <DialogContent className={styles['dialog-content']}>
        <Typography>
          <Trans
            i18nKey={t('dialogs.howToPlay.puzzleIntroduction', {
              puzzleGoal: puzzle.goal,
              token1: puzzle.tokens[0],
              token2: puzzle.tokens[1],
              token3: puzzle.tokens[2],
              token4: puzzle.tokens[3],
              token5: puzzle.tokens[4],
              token6: puzzle.tokens[5],
            })}
            obje
            components={[
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
              <span className={styles['puzzle-detail']} />,
            ]}
          />
        </Typography>

        <Puzzle goal={puzzle.goal} tokens={puzzle.tokens} />

        <Box className={styles['instructions-container']}>
          <Typography>{t('dialogs.howToPlay.instructions')}</Typography>

          <Typography>{t('dialogs.howToPlay.sampleExpressions')}</Typography>
        </Box>

        <Box className={styles['sample-expressions']}>
          {sampleExpressions.map((expression) => (
            <Box key={expression}>
              <Typography className={styles['sample-expression']}>
                {expression}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog}>{t('actions.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default HowToPlayDialog;
