import classNames from 'classnames';
import styles from './Match.module.scss';
import { Box, BoxProps } from '@mui/material';
import { Match as MatchType } from '../../../../types/match/Match';
import useSettings from '../../../../hooks/useSettings/useSettings';
import Puzzle from '../../../../components/Puzzle/Puzzle';
import MatchHeader, { MatchHeaderProps } from '../MatchHeader/MatchHeader';
import util from '../../../../util/util';
import PuzzleSolutions from '../PuzzleSolutions/PuzzleSolutions';
import { Animatable } from '../../../../types/Animatable';

export interface MatchProps extends BoxProps, Animatable {
  data: MatchType;
  matchHeaderProps?: Partial<MatchHeaderProps>;
}

const Match = ({
  animate,
  className,
  data,
  matchHeaderProps,
  ...rest
}: MatchProps) => {
  const { gameMode, participants, puzzle, startedAt, endedAt } = data;
  const endDate = endedAt ? new Date(endedAt) : undefined;
  const startDate = startedAt ? new Date(startedAt) : undefined;
  const expectedEndDate = startDate
    ? util.dates.getExpectedMatchEndDate(new Date(startDate), gameMode)
    : undefined;

  const { autoExpandShortestSolutionCard } = useSettings();

  return (
    <Box className={classNames(styles['match'], className)} {...rest}>
      <MatchHeader
        animate={animate}
        gameMode={gameMode}
        endDate={gameMode === 'practice' ? startDate : endDate}
        expectedEndDate={expectedEndDate}
        {...matchHeaderProps}
      />

      <Puzzle size={'large'} goal={puzzle.goal} tokens={[...puzzle.tokens]} />

      <PuzzleSolutions
        animate={animate}
        autoExpandShortestSolutionCard={autoExpandShortestSolutionCard}
        gameMode={gameMode}
        matchStartDate={startDate}
        participants={participants}
        puzzle={puzzle}
      />
    </Box>
  );
};

export default Match;
