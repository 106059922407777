import MainMenu from './MainMenu/MainMenu';
import MainNavigation from './MainNavigation/MainNavigation';
import styles from './Navigation.module.scss';
import TabBar from './TabBar/TabBar';

const Navigation = () => {
  return (
    <nav className={styles['nav']}>
      <MainNavigation className={styles['main-navigation']} />

      <TabBar className={styles['tab-bar']} />

      <MainMenu />
    </nav>
  );
};

export default Navigation;
