import { Card, CardProps } from '@mui/material';
import classNames from 'classnames';
import styles from './Section.module.scss';

export interface SectionProps extends Omit<CardProps, 'title'> {}

const Section = ({ children, className, ...rest }: SectionProps) => {
  return (
    <Card
      className={classNames(styles['section'], className)}
      // sx={{ bgcolor: 'unset', gap: '8rem', ...sx }}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default Section;
