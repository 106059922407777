import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import TokenKey from './Token/Token';
import styles from './Tokens.module.scss';
import { Token } from './types';

export interface TokensProps extends BoxProps {
  className?: string;
  onTokenClick: (tokenIndex: number) => void;
  tokens: Token[];
}

const Tokens = ({ className, onTokenClick, tokens, ...rest }: TokensProps) => {
  return (
    <Box className={classNames(styles['tokens'], className)} {...rest}>
      {tokens.map((token, i) => (
        <TokenKey
          disabled={token.disabled}
          key={i}
          onClick={() => onTokenClick?.(i)}
        >
          {token.value}
        </TokenKey>
      ))}
    </Box>
  );
};

export default Tokens;
