import { Box, BoxProps } from '@mui/material';
import MatchHeading, { MatchHeadingProps } from '../MatchHeading/MatchHeading';
import styles from './MatchHeader.module.scss';
import { Animatable } from '../../../../types/Animatable';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface MatchHeaderProps
  extends Animatable,
    BoxProps,
    Pick<
      MatchHeadingProps,
      'endDate' | 'expectedEndDate' | 'gameMode' | 'showMatchEndDateTimestamp'
    > {
  endNode?: ReactNode;
  matchHeadingProps?: Partial<MatchHeadingProps>;
}

const MatchHeader = ({
  animate,
  className,
  endDate,
  endNode,
  expectedEndDate,
  gameMode,
  showMatchEndDateTimestamp,
  matchHeadingProps,
  ...rest
}: MatchHeaderProps) => {
  return (
    <Box className={classNames(styles['match-header'], className)} {...rest}>
      <MatchHeading
        animate={animate}
        endDate={endDate}
        expectedEndDate={expectedEndDate}
        gameMode={gameMode}
        showMatchEndDateTimestamp={showMatchEndDateTimestamp}
        {...matchHeadingProps}
      />

      {endNode && <Box className={styles['end-node']}>{endNode}</Box>}
    </Box>
  );
};

export default MatchHeader;
