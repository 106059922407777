import { useState } from 'react';
import { UNICODE_CHARACTER_ENCODINGS } from './Operators';
import util from '../../../../../../util/util';

export const useExpression = () => {
  const [expressionTokens, setExpressionTokens] = useState<string[]>([]);
  const [expressionAppendix, setExpressionAppendix] = useState('');

  const pushToken = (token: string): boolean => {
    if (isValidAppendToken(expressionTokens, token)) {
      setExpressionTokens([...expressionTokens, token]);

      if (isLeftBracket(token)) {
        setExpressionAppendix(
          expressionAppendix.concat(
            UNICODE_CHARACTER_ENCODINGS['RIGHT PARENTHESIS']
          )
        );
      } else if (isRightBracket(token)) {
        setExpressionAppendix(expressionAppendix.slice(0, -1));
      }

      return true;
    }

    return false;
  };

  const popToken = (): string | undefined => {
    const newExpression = [...expressionTokens];
    const poppedToken = newExpression.pop();
    setExpressionTokens(newExpression);

    if (isLeftBracket(String(poppedToken))) {
      setExpressionAppendix(expressionAppendix.slice(0, -1));
    }

    return poppedToken;
  };

  const clearExpression = (): boolean => {
    const tokenCount = expressionTokens.length;
    setExpressionTokens([]);
    setExpressionAppendix('');

    return tokenCount > 0;
  };

  return {
    expression: getOperatorSpacedExpressionString(expressionTokens),
    expressionAppendix,
    pushToken,
    popToken,
    clearExpression,
  };
};

const isValidAppendToken = (expression: string[], token: string): boolean => {
  if (expression.length === 0) {
    return isValidFirstExpressionToken(token);
  }

  if (util.strings.isNum(token)) {
    if (isLastTokenNumber(expression) || isLastTokenRightBracket(expression)) {
      return false;
    }
  }

  if (isOperator(token)) {
    if (isLastTokenOperator(expression) || isLastTokenLeftBracket(expression)) {
      return false;
    }
  }

  if (isRightBracket(token)) {
    if (
      isLastTokenLeftBracket(expression) ||
      isLastTokenOperator(expression) ||
      countUnmatchedLeftBrackets(
        getOperatorSpacedExpressionString(expression)
      ) === 0
    ) {
      return false;
    }
  }

  if (isLeftBracket(token)) {
    if (isLastTokenRightBracket(expression) || isLastTokenNumber(expression)) {
      return false;
    }
  }

  return true;
};

const isOperator = (token: string): boolean => {
  switch (token) {
    case UNICODE_CHARACTER_ENCODINGS['PLUS SIGN']:
    case UNICODE_CHARACTER_ENCODINGS['MINUS SIGN']:
    case UNICODE_CHARACTER_ENCODINGS['MULTIPLICATION SIGN']:
    case UNICODE_CHARACTER_ENCODINGS['DIVISION SIGN']:
      return true;
  }

  return false;
};

const isValidFirstExpressionToken = (token: string): boolean =>
  util.strings.isNum(token) || isLeftBracket(token);

const isLastTokenNumber = (expression: string[]): boolean =>
  util.strings.isNum(expression[expression.length - 1]);

const isLastTokenLeftBracket = (expression: string[]): boolean =>
  isLeftBracket(expression[expression.length - 1]);

const isLastTokenRightBracket = (expression: string[]): boolean =>
  isRightBracket(expression[expression.length - 1]);

const isLastTokenOperator = (expression: string[]): boolean =>
  isOperator(expression[expression.length - 1]);

const isLeftBracket = (token: string): boolean =>
  token === UNICODE_CHARACTER_ENCODINGS['LEFT PARENTHESIS'];

const isRightBracket = (token: string): boolean =>
  token === UNICODE_CHARACTER_ENCODINGS['RIGHT PARENTHESIS'];

export const isBackspace = (token: string): boolean =>
  token === UNICODE_CHARACTER_ENCODINGS.BACKSPACE;

// returns leftBracketCount - rightBracketCount, expects valid expression
const countUnmatchedLeftBrackets = (expression: string): number => {
  let leftBracketCount = 0;
  let rightBracketCount = 0;
  const expressionLength = expression.length;
  for (let i = 0; i < expressionLength; i++) {
    switch (expression[i]) {
      case UNICODE_CHARACTER_ENCODINGS['LEFT PARENTHESIS']: {
        leftBracketCount++;
        break;
      }
      case UNICODE_CHARACTER_ENCODINGS['RIGHT PARENTHESIS']: {
        rightBracketCount++;
        break;
      }
    }
  }

  return leftBracketCount - rightBracketCount;
};

const getOperatorSpacedExpressionString = (expression: string[]): string => {
  let str = '';
  const expressionLength = expression.length;
  for (let i = 0; i < expressionLength; i++) {
    str += isOperator(expression[i]) ? ` ${expression[i]} ` : expression[i];
  }

  return str;
};
