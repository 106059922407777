import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import assets from '../../../../../assets/assets';
import useSettings from '../../../../../hooks/useSettings/useSettings';
import useUser from '../../../../../hooks/useUser/useUser';
import MainMenuProfileItem from './components/MainMenuProfileItem/MainMenuProfileItem';
import styles from './MainMenu.module.scss';

const MainMenu = () => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { username, type } = user;
  const navigate = useNavigate();

  const [avatarElement, setAvatarElement] = useState<HTMLElement | null>(null);
  const { colorScheme, setColorScheme } = useSettings();

  return (
    <div>
      <Avatar
        aria-label={'main menu'}
        className={'cursor-pointer'}
        onClick={(e) => setAvatarElement(e.currentTarget)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setAvatarElement(e.currentTarget);
          }
        }}
        tabIndex={0}
      >
        {username[0] || '?'}
      </Avatar>

      <Menu
        anchorEl={avatarElement}
        disableScrollLock={true}
        open={Boolean(avatarElement)}
        onClose={() => setAvatarElement(null)}
        slotProps={{
          paper: {
            elevation: undefined,
            sx: {
              overflow: 'visible',
              filter:
                colorScheme === 'light'
                  ? 'drop-shadow(0rem 1rem 2rem rgba(0,0,0,0.05))'
                  : 'drop-shadow(0rem 1rem 2rem rgba(0,0,0,0.6))',
              mt: 1.5,
              minWidth: 260,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transitionDuration={1}
        disableAutoFocusItem
      >
        <MainMenuProfileItem className={styles['profile-item']} />

        <Divider />

        <MenuItem
          id='main-menu-app-theme-toggle'
          onClick={() =>
            colorScheme === 'light'
              ? setColorScheme('dark')
              : setColorScheme('light')
          }
        >
          <ListItemIcon>
            <assets.icons.dark_mode />
          </ListItemIcon>
          {t('navigation.navigation.darkMode')}
          <Switch
            checked={colorScheme === 'dark'}
            onChange={(e, checked) =>
              checked ? setColorScheme('dark') : setColorScheme('light')
            }
            sx={{ marginLeft: 'auto' }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/settings');
            setAvatarElement(null);
          }}
        >
          <ListItemIcon>
            <assets.icons.settings />
          </ListItemIcon>
          {t('navigation.more.sections.settings')}
        </MenuItem>

        <Divider />

        {type === 'GUEST' ? (
          <MenuItem onClick={() => navigate('/signout')}>
            <ListItemIcon>
              <assets.icons.logout />
            </ListItemIcon>
            {t('actions.exitGuestMode')}
          </MenuItem>
        ) : (
          <MenuItem onClick={() => navigate('/signout')}>
            <ListItemIcon>
              <assets.icons.logout />
            </ListItemIcon>
            {t('actions.signout')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MainMenu;
