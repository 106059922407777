import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { WS_API_URL } from '../../redux/slices/api/config';
import useWebSocket from 'react-use-websocket';
import { EnqueueMessage } from './types/messages/client/EnqueueMessage';
import { DequeueMessage } from './types/messages/client/DequeueMessage';
import {
  resetState,
  setChallengeStatus,
  setEnqueuedState,
  setForfeitMatchStatus,
  setMatchStartData,
  setMatchSummaryState,
  setMatchmakingStatus,
  setPlayAgainState,
  setSelectedMatchmakingGameMode,
} from '../../redux/slices/matchmaking/matchmaking';
import { SubmitSolutionMessage } from './types/messages/client/SubmitSolutionMessage';
import { useEffect } from 'react';
import { ServerMatchmakingWebSocketMessage } from './types/messages/server/ServerMatchmakingWebSocketMessage';
import { EnqueuedMessage } from './types/messages/server/EnqueuedMessage';
import { MatchStartedMessage } from './types/messages/server/MatchStartedMessage';
import { MatchSummaryMessage } from './types/messages/server/MatchSummaryMessage';
import apiSlice, { useRefreshTokensMutation } from '../../redux/slices/api/api';
import { MatchmakingGameMode } from '../../types/Gamemode';
import { MatchmakingStatus } from '../../redux/slices/matchmaking/types/MatchmakingStatus';
import { ForfeitMatchMessage } from './types/messages/client/ForfeitMatchMessage';
import { setAuthTokens } from '../../redux/slices/auth/auth';
import util from '../../util/util';
import { CreateChallengeRequestMessage } from './types/messages/client/CreateChallengeRequestMessage';
import { UpdateChallengeRequestMessage } from './types/messages/client/UpdateChallengeStatusRequestMessage';
import { ChallengeStatus } from './types/ChallengeStatus';
import { ChallengeStatusUpdateMessage } from './types/messages/server/ChallengeStatusUpdateMessage';

const useMatchmaking = () => {
  const matchmakingState = useAppSelector(
    (state: RootState) => state.matchmaking
  );

  const { matchId: inProgressMatchId } = matchmakingState.matchStartData || {};

  const accessToken = useAppSelector(
    (state: RootState) => state.auth.accessToken
  );
  const refreshToken = useAppSelector(
    (state: RootState) => state.auth.refreshToken
  );

  const { sendJsonMessage, lastMessage } = useWebSocket(WS_API_URL!, {
    queryParams: !!accessToken
      ? {
          token: accessToken,
        }
      : undefined,
    share: true,
    heartbeat: {
      interval: 30_000,
    },
  });
  const dispatch = useAppDispatch();

  const [
    refreshTokens,
    { isSuccess: isSucessRefreshTokens, data: refreshTokensData },
  ] = useRefreshTokensMutation();

  useEffect(() => {
    if (accessToken && util.jwt.isJWTExpired(accessToken, 5)) {
      if (refreshToken) {
        refreshTokens({ refreshToken });
      }
    }
  }, [accessToken, refreshToken, refreshTokens]);

  useEffect(() => {
    if (isSucessRefreshTokens && refreshTokensData) {
      dispatch(setAuthTokens(refreshTokensData));
    }
  }, [isSucessRefreshTokens, refreshTokensData, dispatch]);

  const selectMatchmakingGameMode = (gameMode: MatchmakingGameMode) =>
    dispatch(setSelectedMatchmakingGameMode(gameMode));

  const enqueue = (gameMode: MatchmakingGameMode) => {
    const message: EnqueueMessage = {
      type: 'matchmakingEnqueue',
      data: {
        gameMode: gameMode,
      },
    };

    switch (gameMode) {
      case 'normal':
      case 'rated': {
        sendJsonMessage(message);
        break;
      }
    }
  };

  const dequeue = (gameMode: MatchmakingGameMode) => {
    const message: DequeueMessage = {
      type: 'matchmakingDequeue',
      data: {
        gameMode: gameMode,
      },
    };

    sendJsonMessage(message);
    dispatch(resetState());
  };

  const submitSolution = (expression: string, matchId: string) => {
    const message: SubmitSolutionMessage = {
      type: 'matchSubmitSolution',
      data: {
        expression: expression,
        matchId: matchId,
      },
    };

    sendJsonMessage(message);
    dispatch(setMatchmakingStatus('solutionSubmitted'));
  };

  const setStatus = (status: MatchmakingStatus) => {
    dispatch(setMatchmakingStatus(status));
  };

  const forfeitMatch = (matchId: string) => {
    const message: ForfeitMatchMessage = {
      type: 'forfeitMatch',
      data: {
        matchId: matchId,
      },
    };

    sendJsonMessage(message);
    dispatch(setForfeitMatchStatus());
  };

  const createChallenge = (challengee: string) => {
    const message: CreateChallengeRequestMessage = {
      type: 'challengeCreate',
      data: {
        challengee: challengee,
      },
    };

    sendJsonMessage(message);
  };

  const updateChallengeStatus = (
    challengeId: string,
    status: ChallengeStatus
  ) => {
    const message: UpdateChallengeRequestMessage = {
      type: 'challengeUpdate',
      data: {
        challengeId: challengeId,
        status: status,
      },
    };

    sendJsonMessage(message);
  };

  useEffect(() => {
    if (lastMessage) {
      const message: ServerMatchmakingWebSocketMessage = JSON.parse(
        lastMessage.data
      );

      switch (message.type) {
        case 'matchmakingEnqueued':
          {
            const enqueuedData = (message as EnqueuedMessage).data;
            const { gameMode } = enqueuedData;

            dispatch(setEnqueuedState(gameMode));
          }
          break;
        case 'matchStarted': {
          const matchStartData = (message as MatchStartedMessage).data;

          dispatch(setMatchStartData(matchStartData));
          break;
        }
        case 'matchSummary': {
          const matchSummaryData = (message as MatchSummaryMessage).data;

          if (inProgressMatchId === matchSummaryData.id) {
            dispatch(apiSlice.util.invalidateTags(['match']));
            dispatch(setMatchSummaryState(matchSummaryData));
          }
          break;
        }
        case 'challengeStatusUpdate': {
          const challengeStatusData = (message as ChallengeStatusUpdateMessage)
            .data;

          dispatch(setChallengeStatus(challengeStatusData));
          break;
        }
      }
    }
  }, [lastMessage, dispatch, inProgressMatchId]);

  const playAgain = () => dispatch(setPlayAgainState());

  const _restState = () => dispatch(resetState());

  return {
    dequeue,
    enqueue,
    forfeitMatch,
    selectedMatchmakingGameMode: matchmakingState.selectedMatchmakingGameMode,
    enqueuedGameMode: matchmakingState.enqueuedGameMode,
    matchStartData: matchmakingState.matchStartData,
    matchSummaryData: matchmakingState.matchSummaryData,
    playAgain,
    resetState: _restState,
    selectMatchmakingGameMode,
    setStatus,
    submitSolution,
    state: matchmakingState,
    status: matchmakingState.status,
    createChallenge,
    updateChallengeStatus,
  };
};

export default useMatchmaking;
