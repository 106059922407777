import { Trans, useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
  Typography,
} from '@mui/material';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import editUsernameDialogStyles from '../EditUsernameDialog/EditUsernameDialog.module.scss';
import styles from './DeleteYourAccount.module.scss';
import { useDeleteAccountMutation } from '../../../../redux/slices/api/api';
import { ErrorResponse } from '../../../../redux/slices/api/types/ErrorResponse';
import util from '../../../../util/util';
import { useNavigate } from 'react-router-dom';

const DeleteYourAccountDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const [confirmationText, setConfirmationText] = useState('');
  const [confirmationTextErrorText, setConfirmationTextErrorText] =
    useState('');

  const [
    deleteAccount,
    {
      isLoading: isLoadingDeleteAccount,
      isSuccess: isSuccessDeleteAccount,
      isError: isErrorDeleteAccount,
      error: deleteAccountError,
    },
  ] = useDeleteAccountMutation();

  const navigate = useNavigate();

  const { onClose } = props;

  useEffect(() => {
    setPasswordErrorText('');
  }, [password]);

  useEffect(() => {
    setConfirmationTextErrorText('');
  }, [confirmationText]);

  const handleDeleteAccount = () => {
    if (password.length === 0) {
      setPasswordErrorText(t('errors.validation.password.emptyPassword'));
      return;
    }

    deleteAccount({ password });
  };

  useEffect(() => {
    if (isErrorDeleteAccount) {
      if (
        deleteAccountError &&
        'data' in deleteAccountError &&
        deleteAccountError.data !== null
      ) {
        const response = deleteAccountError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          switch (translationFileKey) {
            case 'errors.validation.password.incorrectPassword':
              setConfirmationTextErrorText(t(translationFileKey));
              return;
            default:
              setConfirmationTextErrorText(t(translationFileKey));
              return;
          }
        }
      }

      setConfirmationTextErrorText(t('errors.somethingWentWrong'));
    }
  }, [isErrorDeleteAccount, deleteAccountError, t]);

  const signout = useCallback(() => navigate('/signout'), [navigate]);

  const closeDialog = useCallback(() => {
    if (isSuccessDeleteAccount) {
      signout();
    } else {
      onClose?.({}, 'escapeKeyDown');
    }
  }, [isSuccessDeleteAccount, signout, onClose]);

  useEffect(() => {
    if (isSuccessDeleteAccount) {
      const timer = setTimeout(() => {
        signout();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [isSuccessDeleteAccount, signout]);

  return (
    <Dialog
      aria-describedby='delete-account-dialog-title'
      {...props}
      disableEscapeKeyDown={isSuccessDeleteAccount}
    >
      {isSuccessDeleteAccount ? (
        <>
          <DialogHeader
            onClose={signout}
            title={t(
              'navigation.more.account.dialogs.deleteAccountSuccess.title'
            )}
            titleProps={{
              id: 'edit-email-dialog-title',
            }}
          />

          <DialogContent>
            <Typography>
              {t('navigation.more.account.dialogs.deleteAccountSuccess.body')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={signout}>
              {t('actions.signout')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogHeader
            onClose={closeDialog}
            title={t('actions.deleteYourAccount')}
            titleProps={{
              id: 'delete-account-dialog-title',
            }}
          />

          <DialogContent>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              className={editUsernameDialogStyles['inputs-container']}
            >
              <Typography>
                {t('navigation.more.account.deleteAccount.formBody')}
              </Typography>

              <PasswordField
                autoComplete='off'
                autoFocus
                disabled={isLoadingDeleteAccount}
                error={Boolean(passwordErrorText)}
                fullWidth
                helperText={passwordErrorText}
                label={t('forms.password')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleDeleteAccount();
                  }
                }}
                spellCheck={false}
                value={password}
              />

              <Typography>
                <Trans
                  i18nKey={t(
                    'navigation.more.account.deleteAccount.confirmationTextLabel'
                  )}
                  obje
                  components={[
                    <span className={styles['confirmation-text']} />,
                  ]}
                />
              </Typography>

              <TextField
                autoComplete='off'
                disabled={isLoadingDeleteAccount}
                error={Boolean(confirmationTextErrorText)}
                fullWidth
                helperText={confirmationTextErrorText}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmationText(e.target.value)
                }
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    handleDeleteAccount();
                  }
                }}
                spellCheck={false}
                value={confirmationText}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={
                confirmationText !==
                  t('navigation.more.account.deleteAccount.confirmationText') ||
                isLoadingDeleteAccount
              }
              onClick={handleDeleteAccount}
            >
              {t('actions.deleteAccount')}
            </Button>

            <Button disabled={isLoadingDeleteAccount} onClick={closeDialog}>
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DeleteYourAccountDialog;
