/**
 * Converts time in milliseconds to a formatted string of minutes and seconds.
 *
 * @param {number} time - The time duration in milliseconds.
 * @param {number} [secondsFractionDigits=0] - The number of digits after the decimal point for seconds.
 * @returns {string} Formatted time string in 'Xm Ys' format. If minutes are 0, returns only seconds.
 */
export const getFormattedSolvingTime = (
  time: number,
  secondsFractionDigits: number = 0
): string => {
  const minutes = Math.floor(time / 60_000);
  const seconds = ((time % 60_000) / 1000).toFixed(secondsFractionDigits);

  return `${minutes ? `${minutes}m ` : ''}${
    seconds !== '0' ? `${seconds}s` : ''
  }`.trim();
};
