import { Button, ButtonProps } from '@mui/material';
import classNames from 'classnames';
import styles from './Operator.module.scss';

export interface OperatorProps extends ButtonProps {}

const Operator = ({ className, children, ...rest }: OperatorProps) => {
  return (
    <Button className={classNames(styles['operator'], className)} {...rest}>
      {children}
    </Button>
  );
};

export default Operator;
