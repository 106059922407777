import { EmailValidationErrorType } from '../../redux/slices/api/types/error/EmailValidationErrorType';

/**
 * Checks if given email address has a valid form.
 *
 * @param {string} email - Email address on which to perform the check on.
 * @returns {boolean} a Boolean value that indicates whether or not a given email address
 * has a valid form.
 *
 */
const isValidEmailAddress = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export default isValidEmailAddress;

/**
 * Validates given email address.
 *
 * @param {string} email - Email string to check the validity of.
 * @returns {enum} Undefined if email is valid, error otherwise.
 *
 */
export const validateEmail = (
  email: string
): EmailValidationErrorType | undefined => {
  if (email.length < 1) {
    return 'Empty Email';
  } else if (!isValidEmailAddress(email)) {
    return 'Invalid Email';
  }
};
