import { useColorScheme } from '@mui/material';
import { i18nLocale } from '../../i18n';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setAutoExpandShortestSolutionCard as reducerSetAutoExpandShortestSolutionCard,
  setEnableGameBoardSounds as enableGameBoardSoundsAction,
  setBlockChallengeAFriendRequests as setBlockChallengeAFriendRequestsAction,
} from '../../redux/slices/settings/settings';
import { RootState } from '../../redux/store';
import useLanguage from '../useLanguage';
import { setAppLanguage } from '../../redux/slices/settings/settings';

const useSettings = () => {
  useLanguage();
  const { colorScheme, setColorScheme } = useColorScheme();

  const settings = useAppSelector((state: RootState) => state.settings);
  const dispatch = useAppDispatch();

  const setLanguage = (language: i18nLocale) =>
    dispatch(setAppLanguage(language));

  const setAutoExpandShortestSolutionCard = (
    autoExpandShortestSolutionCard: boolean
  ) =>
    dispatch(
      reducerSetAutoExpandShortestSolutionCard(autoExpandShortestSolutionCard)
    );

  const setEnableGameBoardSounds = (enableGameBoardSounds: boolean) => {
    dispatch(enableGameBoardSoundsAction(enableGameBoardSounds));
  };

  const setBlockChallengeAFriendRequests = (block: boolean) => {
    dispatch(setBlockChallengeAFriendRequestsAction(block));
  };

  return {
    colorScheme,
    language: settings.appLanguage,
    setColorScheme,
    setLanguage,
    setAutoExpandShortestSolutionCard,
    setEnableGameBoardSounds,
    autoExpandShortestSolutionCard: settings.autoExpandShortestSolutionCard,
    enableGameBoardSounds: settings.enableGameBoardSounds,
    blockChallengeAFriendRequests: settings.blockChallengeAFriendRequests,
    setBlockChallengeAFriendRequests,
  };
};

export default useSettings;
