import getTranslationFileErrorKey from './translation/getTranslationFileErrorKey';
import getTranslationKeyDefaultnterpolationObject from './translation/getTranslationKeyDefaultnterpolationObject';
import getValidationErrorTranslationKey from './translation/getValidationErrorTranslationKey';
import getTranslationFileKeyFromErrorResponse from './translation/getTranslationFileKeyFromErrorResponse';

const translation = {
  getTranslationFileErrorKey,
  getTranslationKeyDefaultnterpolationObject,
  getValidationErrorTranslationKey,
  getTranslationFileKeyFromErrorResponse,
};

export default translation;
