import cabled_mess_blip from './audio/cabled_mess_blip.wav';
import finger_tap from './audio/finger_tap.wav';
import menu_click_silent from './audio/menu_click_silent.wav';
import score_defeat from './audio/score_defeat.mp3';
import score_victory from './audio/score_victory.mp3';

const audio = {
  cabled_mess_blip,
  finger_tap,
  menu_click_silent,
  score_defeat,
  score_victory,
};

export default audio;
