/**
 * Capitalizes first letter of the string.
 *
 * @param string - String to capitalize the first letter of.
 * @returns The string argument with first letter capitalized.
 *
 */
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default capitalizeFirstLetter;
