import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './i18n/en-US';
import srLatn from './i18n/sr-Latn';
import srCyrl from './i18n/sr-Cyrl';

export type i18nLocale = 'en-US' | 'sr-Latn' | 'sr-Cyrl';
export type i18nLanguage = {
  name: string;
  locale: i18nLocale;
};

export const appLanguages: i18nLanguage[] = [
  { name: 'English', locale: 'en-US' },
  { name: 'Српски (Ћирилица)', locale: 'sr-Cyrl' },
  { name: 'Serbian (Latin)', locale: 'sr-Latn' },
];

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  'en-US': {
    translation: enUS,
  },
  'sr-Latn': {
    translation: srLatn,
  },
  'sr-Cyrl': {
    translation: srCyrl,
  },
};

// eslint-disable-next-line
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: 'en-US', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en-US',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
