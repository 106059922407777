import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import styles from './SignupComplete.module.scss';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSignupCompleteMutation } from '../../redux/slices/api/api';
import { useEffect } from 'react';
import FormContainer from '../Signin/components/FormContainer/FormContainer';
import FormLogoHeader from '../Signin/components/FormLogoHeader/FormLogoHeader';
import FormBody from '../Signin/components/FormBody/FormBody';
import FormLayoutContainer from '../Signin/components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from '../Signin/components/FormLayoutFooter/FormLayoutFooter';

const SignupComplete = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('actions.signup')}`);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const expires = searchParams.get('expires');
  const isLinkExpired = expires
    ? new Date(Number(expires)) < new Date()
    : false;

  const [
    signupComplete,
    { isSuccess: isSuccessSignupComplete, isError: isErrorSignupComplete },
  ] = useSignupCompleteMutation();

  useEffect(() => {
    if (token) {
      signupComplete({ token });
    }
  }, [token, signupComplete]);

  if (!token || !expires) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.signup.complete.invalidLinkError.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.signup.complete.invalidLinkError.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isLinkExpired) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.signup.complete.linkExpiredError.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.signup.complete.linkExpiredError.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isErrorSignupComplete) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.signup.complete.error.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.signup.complete.error.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isSuccessSignupComplete) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.signup.complete.success.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.signup.complete.success.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/signin'
              >
                {t('actions.returnToSignin')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  return (
    <Page className={styles['signup-complete']}>
      <CircularProgress />
    </Page>
  );
};

export default SignupComplete;
