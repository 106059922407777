import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiModal: {
  defaultProps?: ComponentsProps['MuiModal'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiModal'];
  variants?: ComponentsVariants<Theme>['MuiModal'];
} = {
  styleOverrides: {
    root: {
      '& .MuiModal-backdrop:not(.MuiBackdrop-invisible)': {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
    },
  },
};

export default MuiModal;
