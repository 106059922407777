import { ExpressionError } from '../../../../../../../../types/match/ExpressionError';

const getExpressionErrorTranslationFileKey = (
  expressionError: ExpressionError
) => {
  switch (expressionError) {
    case 'Division By Zero':
      return 'errors.validation.expression.divisionByZero';
    case 'Division With Remainder':
      return 'errors.validation.expression.divisionWithRemainder';
    case 'Invalid Expression':
      return 'errors.validation.expression.invalidExpression';
    case 'Mismatched Parentheses':
      return 'errors.validation.expression.mismatchedParentheses';
    case 'Misplaced Operator':
      return 'errors.validation.expression.misplacedOperator';
    case 'Negative Numbers':
      return 'errors.validation.expression.negativeNumbers';
  }
};

export default getExpressionErrorTranslationFileKey;
