import styles from './SigninForm.module.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, TextField, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import useUser from '../../../../hooks/useUser/useUser';
import { useSigninMutation } from '../../../../redux/slices/api/api';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import FormContainer from '../FormContainer/FormContainer';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';
import { jwtDecode } from 'jwt-decode';
import { useAppDispatch } from '../../../../redux/hooks';
import { AuthTokens } from '../../../../redux/slices/auth/types/AuthTokens';
import { setAuthTokens } from '../../../../redux/slices/auth/auth';
import { UserType } from '../../../../redux/slices/user/types/UserType';
import { ErrorResponse } from '../../../../redux/slices/api/types/ErrorResponse';
import util from '../../../../util/util';

interface SigninFormProps extends BoxProps {}

const SigninForm = ({ className, ...rest }: SigninFormProps) => {
  const { t } = useTranslation();

  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [emailOrUsernameErrorText, setEmailOrUsernameErrorText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const [signinErrorText, setSigninErrorText] = useState('');

  const { setUser } = useUser();
  const dispatch = useAppDispatch();

  const [
    signin,
    {
      data: signinData,
      error: signinError,
      isLoading: isLoadingSignin,
      isSuccess: isSuccessSignin,
      isError: isErrorSignin,
    },
  ] = useSigninMutation();

  useEffect(() => {
    if (emailOrUsername) {
      setEmailOrUsernameErrorText('');
      setSigninErrorText('');
    }
  }, [emailOrUsername]);

  useEffect(() => {
    if (password) {
      setPasswordErrorText('');
      setSigninErrorText('');
    }
  }, [password]);

  const handleSignin = (e: any) => {
    e.preventDefault();

    if (!isValidInput()) {
      return;
    }

    signin({ emailOrUsername, password });
  };

  const isValidInput = () => {
    if (emailOrUsername === '') {
      setEmailOrUsernameErrorText(
        t('errors.validation.emailOrUsername.emptyEmailOrUsername')
      );

      return false;
    } else {
      setEmailOrUsernameErrorText('');
    }

    if (password === '') {
      setPasswordErrorText(t('errors.validation.password.emptyPassword'));

      return false;
    } else {
      setPasswordErrorText('');
    }

    return true;
  };

  useEffect(() => {
    if (isSuccessSignin && signinData) {
      const authTokens: AuthTokens = {
        accessToken: signinData.accessToken,
        refreshToken: signinData.refreshToken,
      };

      dispatch(setAuthTokens(authTokens));

      const decodedAccessToken = jwtDecode(authTokens.accessToken);

      setUser({
        isSignedIn: true,
        username: decodedAccessToken.sub!,
        type:
          'type' in decodedAccessToken
            ? (decodedAccessToken.type as UserType)
            : 'GUEST',
      });
    }
  }, [
    isSuccessSignin,
    signinData,
    emailOrUsername,
    password,
    t,
    setUser,
    dispatch,
  ]);

  useEffect(() => {
    if (isErrorSignin) {
      if (signinError && 'data' in signinError && signinError.data !== null) {
        const response = signinError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          switch (translationFileKey) {
            case 'errors.authentication.wrongCredentials':
              setSigninErrorText(t('navigation.signin.signinFailed'));
              return;
          }
        }
      }

      setSigninErrorText(t('errors.somethingWentWrong'));
    }
  }, [isErrorSignin, signinError, t]);

  return (
    <FormContainer {...rest}>
      <FormLogoHeader title={t('navigation.signin.signinToYourAccount')} />

      <form
        autoComplete='off'
        noValidate
        onSubmit={handleSignin}
        className={styles['form']}
      >
        <TextField
          autoComplete='off'
          autoFocus
          className={styles['input-text-field']}
          error={Boolean(emailOrUsernameErrorText)}
          fullWidth
          helperText={emailOrUsernameErrorText}
          label={t('forms.emailOrUsername')}
          name='emailOrUsername'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmailOrUsername(e.target.value)
          }
          spellCheck={false}
          value={emailOrUsername}
          variant='filled'
        />

        <PasswordField
          className={styles['input-text-field']}
          error={Boolean(passwordErrorText) || Boolean(signinErrorText)}
          fullWidth
          helperText={passwordErrorText || signinErrorText}
          label={t('forms.password')}
          name='password'
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          spellCheck={false}
          type='password'
          value={password}
          variant='filled'
        />

        <Button
          disabled={isLoadingSignin}
          className={styles['signin-button']}
          color='primary'
          fullWidth
          type='submit'
          size='large'
        >
          {t('actions.signin')}
        </Button>

        <Link
          underline='hover'
          className={styles['password-reset-link']}
          href='/reset'
        >
          {t('actions.resetYourPassword')}
        </Link>
      </form>

      <Typography>
        {t('navigation.signin.noAccountCreateNew') + ' '}
        <Link underline='hover' href='/signup'>
          {t('actions.signup')}
        </Link>
      </Typography>
    </FormContainer>
  );
};

export default SigninForm;
