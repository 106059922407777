import classNames from 'classnames';
import Goal from '../../../../../components/Puzzle/components/Goal/Goal';
import styles from './Goalboard.module.scss';
import { Box, BoxProps } from '@mui/material';

export interface GoalboardProps extends BoxProps {
  goal: number;
}

const Goalboard = ({ className, goal, ...rest }: GoalboardProps) => {
  return (
    <Box className={classNames(styles['goalboard'], className)} {...rest}>
      <Goal value={goal} size='extra-large' />
    </Box>
  );
};

export default Goalboard;
