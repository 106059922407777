import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import ApplicationSettings from './ApplicationSettings/ApplicationSettings';
import TimeoutRender from '../../components/TimeoutRender/TimeoutRender';
import SettingsSkeleton from './SettingsSkeleton';
import { useAccountInformationQuery } from '../../redux/slices/api/api';
import { useEffect } from 'react';
import useUser from '../../hooks/useUser/useUser';
import { Typography } from '@mui/material';
import AccountSettingsSection from './AccountSettingsSection/AccountSettingsSection';
import PageLoadingError from '../ErrorPage/PageLoadingError/PageLoadingError';
import _ from 'lodash';
import { User } from '../../redux/slices/user/types/User';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import GameSettingsSection from './GameSettingsSection/GameSettingsSection';

const Settings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.more.sections.settings'));

  const { user, setUser } = useUser();

  const {
    data: accountInformationData,
    isError,
    isLoading,
  } = useAccountInformationQuery(undefined, {
    skip: user.type !== 'REGISTERED',
  });

  useEffect(() => {
    if (accountInformationData) {
      const newUser: User = {
        ...user,
        username: accountInformationData.username,
      };

      if (!_.isEqual(user, newUser)) {
        setUser(newUser);
      }
    }
  }, [accountInformationData, setUser, user]);

  if (isError) {
    return <PageLoadingError />;
  }

  if (isLoading) {
    return (
      <TimeoutRender>
        <SettingsSkeleton />
      </TimeoutRender>
    );
  }

  return (
    <Page>
      <Typography variant='page-h1' className={'heading-notch'}>
        {t('navigation.more.sections.settings')}
      </Typography>

      {user.type === 'REGISTERED' && accountInformationData && (
        <AccountSettingsSection
          email={accountInformationData.email}
          emailLastUpdatedAt={
            accountInformationData.emailLastChangedAt ?? undefined
          }
          username={accountInformationData.username}
          usernameLastUpdatedAt={
            accountInformationData.usernameLastChangedAt ?? undefined
          }
        />
      )}

      <ApplicationSettings />

      <GameSettingsSection />
    </Page>
  );
};

export default Settings;
