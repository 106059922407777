import { Box, Typography } from '@mui/material';
import Page from '../../components/Page/Page';
import { Trans, useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const FAQ = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.navigation.privacyPolicy'));

  return (
    <Page>
      <Box>
        <h1>{t('navigation.navigation.faq')}</h1>
        <h2 id='delete_account'>
          {t('navigation.faq.deleteAccount.question')}
        </h2>
        <Typography>
          <Trans
            i18nKey='navigation.faq.deleteAccount.answer'
            components={{
              br: <br />,
              ol: <ol />,
              li: <li />,
              a: <a href='mailto:support@example.com' />,
            }}
          />
        </Typography>
      </Box>
    </Page>
  );
};

export default FAQ;
