import { Box, BoxProps, Typography } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Setting.module.scss';

export interface SettingProps extends BoxProps {
  description?: string;
  endNode?: ReactNode;
  title: string;
}

const Setting = ({
  children,
  className,
  description,
  endNode,
  title,
  ...rest
}: SettingProps) => {
  return (
    <Box className={classNames(styles['setting'], className)} {...rest}>
      <div className={styles['header']}>
        <Box className={styles['start-node']}>
          <Typography className={styles['title']}>{title}</Typography>
          {description && (
            <Typography className={styles['description']}>
              {description}
            </Typography>
          )}
        </Box>

        {endNode && <Box className={styles['end-node']}>{endNode}</Box>}
      </div>

      {children && <div className={styles['body']}>{children}</div>}
    </Box>
  );
};

export default Setting;
