import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiSlice, {
  useUpdateUsernameMutation,
} from '../../../../redux/slices/api/api';
import util from '../../../../util/util';
import useUsernameValidation from '../../../../hooks/api/useUsernameValidation/useUsernameValidation';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import styles from './EditUsernameDialog.module.scss';
import { useAppDispatch } from '../../../../redux/hooks';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import config from '../../../../config/config';
import { ErrorResponse } from 'react-router-dom';
import { AuthTokens } from '../../../../redux/slices/auth/types/AuthTokens';
import { setAuthTokens } from '../../../../redux/slices/auth/auth';
import { setUser } from '../../../../redux/slices/user/user';
import { jwtDecode } from 'jwt-decode';
import { UserType } from '../../../../redux/slices/user/types/UserType';

const EditUsernameDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const debouncedUsername = useDebounce(username);
  const [usernameErrorText, setUsernameErrorText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const { error: usernameValidationError } =
    useUsernameValidation(debouncedUsername);

  const [
    updateUsername,
    {
      data: updateUsernameData,
      isLoading: isLoadingUpdateUsername,
      isSuccess: isSuccessUpdateUsername,
      isError: isErrorUpdateUsername,
      error: usernameUpdateError,
    },
  ] = useUpdateUsernameMutation();

  const [usernameUpdateErrorText, setUsernameUpdateErrorText] = useState('');

  const dispatch = useAppDispatch();

  const [passwordInputRef, setPasswordInputRef] =
    useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setPasswordErrorText('');
    setUsernameErrorText('');
    setUsernameUpdateErrorText('');
  }, [username, password]);

  useEffect(() => {
    if (
      usernameValidationError === 'Username Unavailable' ||
      usernameValidationError === 'Invalid Username Format'
    ) {
      setUsernameErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            usernameValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              usernameValidationError
            )
          )
        )
      );
    }
  }, [usernameValidationError, t]);

  const handleUpdateUsername = () => {
    const usernameValidationError = util.validation.validateUsername(username);
    if (usernameValidationError) {
      setUsernameErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            usernameValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              usernameValidationError
            )
          )
        )
      );
      return;
    }

    const passwordValidationError = util.validation.validatePassword(password);
    if (passwordValidationError === 'Empty Password') {
      setPasswordErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            passwordValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              passwordValidationError
            )
          )
        )
      );
      return;
    }

    updateUsername({ newUsername: username, currentPassword: password });
  };

  useEffect(() => {
    if (isErrorUpdateUsername) {
      if (
        usernameUpdateError &&
        'data' in usernameUpdateError &&
        usernameUpdateError.data !== null
      ) {
        const response = usernameUpdateError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          setUsernameUpdateErrorText(
            t(
              translationFileKey,
              util.translation.getTranslationKeyDefaultnterpolationObject(
                translationFileKey
              )
            )
          );

          return;
        }
      }

      setUsernameUpdateErrorText(t('errors.somethingWentWrongPleaseTryAgain'));
      passwordInputRef?.focus();
    }
  }, [isErrorUpdateUsername, usernameUpdateError, t, passwordInputRef]);

  useEffect(() => {
    if (isSuccessUpdateUsername && updateUsernameData) {
      if (
        'accessToken' in updateUsernameData &&
        'refreshToken' in updateUsernameData
      ) {
        const authTokens: AuthTokens = {
          accessToken: updateUsernameData.accessToken,
          refreshToken: updateUsernameData.refreshToken,
        };

        dispatch(setAuthTokens(authTokens));

        const decodedAccessToken = jwtDecode(authTokens.accessToken);

        dispatch(
          setUser({
            isSignedIn: true,
            username: decodedAccessToken.sub!,
            type:
              'type' in decodedAccessToken
                ? (decodedAccessToken.type as UserType)
                : 'GUEST',
          })
        );
      }
    }
  }, [isSuccessUpdateUsername, updateUsernameData, dispatch]);

  const closeDialog = () => {
    if (isSuccessUpdateUsername) {
      dispatch(apiSlice.util.invalidateTags(['accountInformation']));
    }

    props?.onClose?.({}, 'escapeKeyDown');
  };

  return (
    <Dialog aria-describedby={'edit-username-dialog-title'} {...props}>
      {isSuccessUpdateUsername ? (
        <>
          <DialogHeader
            onClose={closeDialog}
            title={t(
              'navigation.more.account.dialogs.editUsernameSuccess.title'
            )}
            titleProps={{
              id: 'edit-username-dialog-title',
            }}
          />

          <DialogContent>
            <Typography>
              {t('navigation.more.account.dialogs.editUsernameSuccess.body')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={closeDialog}>
              {t('actions.ok')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogHeader
            onClose={closeDialog}
            closeDialogButtonProps={{
              disabled: isLoadingUpdateUsername,
            }}
            title={t('navigation.navigation.editUsername')}
            titleProps={{
              id: 'edit-username-dialog-title',
            }}
          />

          <DialogContent>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              className={styles['inputs-container']}
            >
              <TextField
                autoComplete='off'
                autoFocus
                disabled={isLoadingUpdateUsername}
                error={Boolean(usernameErrorText)}
                fullWidth
                helperText={usernameErrorText}
                label={t('forms.newUsername')}
                inputProps={{
                  maxLength: config.account.username.maxLength,
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setUsername(e.target.value)
                }
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleUpdateUsername();
                  }
                }}
                spellCheck={false}
                value={username}
              />

              <PasswordField
                disabled={isLoadingUpdateUsername}
                error={
                  Boolean(usernameUpdateErrorText) || Boolean(passwordErrorText)
                }
                fullWidth
                helperText={usernameUpdateErrorText || passwordErrorText}
                label={t('forms.password')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleUpdateUsername();
                  }
                }}
                inputRef={setPasswordInputRef}
                spellCheck={false}
                value={password}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isLoadingUpdateUsername}
              onClick={handleUpdateUsername}
            >
              {t('actions.submit')}
            </Button>

            <Button disabled={isLoadingUpdateUsername} onClick={closeDialog}>
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditUsernameDialog;
