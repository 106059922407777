import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiTooltip: {
  defaultProps?: ComponentsProps['MuiTooltip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
  variants?: ComponentsVariants<Theme>['MuiTooltip'];
} = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    arrow: ({ theme }) => ({
      color: theme.palette.background.defaultContrast,
    }),
    tooltip: ({ theme }) => ({
      borderRadius: 4,
      fontSize: '13rem',
      backgroundColor: theme.palette.background.defaultContrast,
      color: theme.palette.primary.contrastText,
    }),
  },
};

export default MuiTooltip;
