import { MatchOutcome } from '../../types/match/MatchOutcome';
import { MatchParticipant } from '../../types/match/participants/MatchParticipant';

/**
 * Calculates MatchOutome relative to the player.
 *
 * @param outcomeRelativeParticipant - Player to calculate match outcome relative to.
 * @param participants - Match participants.
 * @returns MatchOutcome relative to the player, null if outcome can not be calculated.
 *
 */
export const getMatchOutcome = (
  outcomeRelativeParticipant: string,
  participants: MatchParticipant[]
): MatchOutcome | undefined => {
  const firstParticipant = participants[0];

  if (firstParticipant && 'placement' in firstParticipant) {
    const firstPlacement = firstParticipant.placement;

    if (
      participants.every(
        (participant) =>
          'placement' in participant && participant.placement === firstPlacement
      )
    ) {
      return 'draw';
    }
  }

  const participant = participants.find(
    (p) => p.username === outcomeRelativeParticipant
  );

  if (participant && 'placement' in participant) {
    switch (participant.placement) {
      case 1:
        return 'victory';
      case 2:
        return 'defeat';
    }
  }

  return undefined;
};
