import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import Page from '../../components/Page/Page';
import signinStyles from '../Signin/Signin.module.scss';
import FormLayoutContainer from '../Signin/components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from '../Signin/components/FormLayoutFooter/FormLayoutFooter';
import ResetPasswordCompleteForm from './components/ResetPasswordForm/ResetPasswordCompleteForm';

const ResetPasswordComplete = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('actions.resetPassword')}`);

  return (
    <Page className={signinStyles['page']}>
      <FormLayoutContainer>
        <ResetPasswordCompleteForm />

        <FormLayoutFooter />
      </FormLayoutContainer>
    </Page>
  );
};

export default ResetPasswordComplete;
