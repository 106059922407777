import styles from './Puzzle.module.scss';
import classNames from 'classnames';
import { Box } from '@mui/system';
import { PuzzleProps } from './Puzzle';
import GoalSkeleton from './components/Goal/GoalSkeleton';
import TokenSkeleton from './components/Token/TokenSkeleton';

export interface PuzzleSkeletonProps extends Partial<PuzzleProps> {}

const PuzzleSkeleton = ({
  className,
  size = 'medium',
  ...rest
}: PuzzleSkeletonProps) => {
  return (
    <Box
      className={classNames(
        styles['puzzle'],
        styles[`size-${size}`],
        className
      )}
      {...rest}
    >
      <GoalSkeleton size={size} />

      <div className={styles['tokens']}>
        {Array.from(Array(6))
          .sort((a, b) => a - b)
          .map((token, i) => (
            <TokenSkeleton key={i} value={token} size={size} />
          ))}
      </div>
    </Box>
  );
};

export default PuzzleSkeleton;
