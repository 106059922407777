import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiMenuItem: {
  defaultProps?: ComponentsProps['MuiMenuItem'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem'];
  variants?: ComponentsVariants<Theme>['MuiMenuItem'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '14rem',
      [theme.breakpoints.up('sm')]: {
        minHeight: '44rem',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '24rem',
        marginRight: '12rem',
      },
    }),
  },
};

export default MuiMenuItem;
