import { Skeleton } from '@mui/material';
import { OperatorProps } from './Operator';
import classNames from 'classnames';
import styles from './Operator.module.scss';

export interface OperatorSkeletonProps extends Partial<OperatorProps> {}

const OperatorSkeleton = ({ className }: OperatorSkeletonProps) => {
  return (
    <Skeleton className={classNames(styles['operator-skeleton'], className)} />
  );
};

export default OperatorSkeleton;
