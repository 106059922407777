import capitalizeFirstLetter from './strings/capitalizeFirstLetter';
import { formatGoalDistance } from './strings/formatGoalDistance';
import { getPrettyExpressionString } from './strings/getPrettyExpressionString';
import { getRawExpressionString } from './strings/getRawExpressionString';
import { isValidUsernameFormat } from './strings/isValidUsernameFormat';
import isNum from './strings/isNum';

const strings = {
  capitalizeFirstLetter,
  formatGoalDistance,
  getPrettyExpressionString,
  getRawExpressionString,
  isValidUsernameFormat,
  isNum,
};

export default strings;
