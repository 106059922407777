import { Box, BoxProps, Link, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './FormLogoHeader.module.scss';
import useSettings from '../../../../hooks/useSettings/useSettings';
import assets from '../../../../assets/assets';

export interface FormLogoHeaderProps extends BoxProps {
  title?: string;
}

const FormLogoHeader = ({ className, title, ...rest }: FormLogoHeaderProps) => {
  const { colorScheme } = useSettings();

  return (
    <Box className={classNames(styles['header'], className)} {...rest}>
      <Link href='/' aria-label='Go to Home'>
        {colorScheme === 'light' ? (
          <assets.images.BoronuLogoDark className={styles['logo']} />
        ) : (
          <assets.images.BoronuLogoLight className={styles['logo']} />
        )}
      </Link>

      {title && <Typography variant='h1'>{title}</Typography>}
    </Box>
  );
};

export default FormLogoHeader;
