import styles from './MatchCard.module.scss';
import Puzzle from '../../../../components/Puzzle/Puzzle';
import { Box, Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MatchCardHeader from './MatchCardHeader/MatchCardHeader';
import util from '../../../../util/util';
import PuzzleSolutionCard from '../../../Gameboards/components/MatchSummary/PuzzleSolutionCard/PuzzleSolutionCard';
import { Match } from '../../../../types/match/Match';
import { getParticipantsSolvingTime } from '../../../Match/components/PuzzleSolutions/util/getParticipantsSolvingTime';
import { getSolvingTimePrecision } from '../../../Match/components/PuzzleSolutions/util/getSolvingTimePrecision';

export interface MatchCardProps extends ButtonProps {
  data: Match;
  matchPerspectiveOwner: string;
}

const MatchCard = ({
  className,
  data,
  matchPerspectiveOwner,
  ...rest
}: MatchCardProps) => {
  const navigate = useNavigate();
  const { id, gameMode, participants, puzzle, startedAt, endedAt } = data;

  const startDate = startedAt ? new Date(startedAt) : undefined;
  const endDate = endedAt ? new Date(endedAt) : undefined;
  const expectedEndDate = startDate
    ? util.dates.getExpectedMatchEndDate(startDate, gameMode)
    : undefined;
  const matchOutcome = util.matches.getMatchOutcome(
    matchPerspectiveOwner,
    participants
  );

  return (
    <Button
      onClick={() => navigate(`/match/${id}`)}
      className={styles['match-button']}
      {...rest}
    >
      <MatchCardHeader
        gameMode={gameMode}
        matchEndDate={endDate}
        matchExpectedEndDate={expectedEndDate}
        matchOutcome={matchOutcome}
        matchStartDate={startDate}
      />

      <Box className={styles['puzzle-and-solutions']}>
        <Puzzle
          className={styles['puzzle']}
          goal={puzzle.goal}
          size={'medium'}
          tokens={[...puzzle.tokens]}
        />

        <Box className={styles['puzzle-solutions']}>
          {participants.map((participant) => {
            if (
              endDate === undefined &&
              participant.puzzleSolution === undefined
            ) {
              return null;
            }

            const { expressionValue, error } = participant.puzzleSolution || {};
            const preMatchRating =
              ('preMatchRating' in participant && participant.preMatchRating) ||
              undefined;
            const postMatchRating =
              ('postMatchRating' in participant &&
                participant.postMatchRating) ||
              undefined;
            const matchOutcome = util.matches.getMatchOutcome(
              participant.username,
              participants
            );
            const matchOutcomeVariant =
              util.matches.getMatchOutcomeVariant(participants);
            const timeColor =
              matchOutcome !== 'draw' && matchOutcomeVariant === 'time'
                ? matchOutcome
                : undefined;

            return (
              <PuzzleSolutionCard
                className={styles['players-puzzle-solution-card']}
                key={participant.username + startDate}
                distance={
                  expressionValue !== undefined
                    ? Math.abs(expressionValue - puzzle.goal)
                    : undefined
                }
                displayTime={gameMode !== 'practice'}
                distanceSize='regular'
                error={error}
                forfeit={
                  participant.status &&
                  participant.status.toLowerCase() === 'forfeit'
                }
                rating={postMatchRating}
                ratingChange={
                  preMatchRating !== undefined && postMatchRating !== undefined
                    ? postMatchRating - preMatchRating
                    : undefined
                }
                solution={participant.puzzleSolution}
                time={
                  startDate
                    ? getParticipantsSolvingTime(
                        participant,
                        startDate,
                        getSolvingTimePrecision(participants, startDate)
                      )
                    : undefined
                }
                timeColor={timeColor}
                title={participant.username}
              />
            );
          })}
        </Box>
      </Box>
    </Button>
  );
};

export default MatchCard;
