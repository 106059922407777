import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './ProfileStats.module.scss';
import { Box, BoxProps } from '@mui/material';
import ProfileStat from '../ProfileStat/ProfileStat';

export interface StatsProps extends BoxProps {
  matches: number;
  rank: number;
  rating: number;
  winRate: number;
}

const Stats = ({
  className,
  rank,
  rating,
  matches,
  winRate,
  ...rest
}: StatsProps) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles['stats'], className)} {...rest}>
      <ProfileStat
        valueProps={{
          className: styles['profile-stat-value'],
        }}
        title={t('navigation.profile.rank')}
        titlePlacement='center'
        value={String(rank)}
        valuePlacement='center'
      />

      <ProfileStat
        valueProps={{
          className: styles['profile-stat-value'],
        }}
        title={t('navigation.profile.rating')}
        titlePlacement='center'
        value={String(rating)}
        valuePlacement='center'
      />

      <ProfileStat
        valueProps={{
          className: styles['profile-stat-value'],
        }}
        title={t('navigation.profile.winRate')}
        titlePlacement='center'
        value={`${String(parseFloat(winRate.toFixed(2)))}%`}
        valuePlacement='center'
      />

      <ProfileStat
        valueProps={{
          className: styles['profile-stat-value'],
        }}
        title={t('navigation.profile.matches')}
        titlePlacement='center'
        value={String(matches)}
        valuePlacement='center'
      />
    </Box>
  );
};

export default Stats;
