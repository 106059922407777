import { differenceInMilliseconds } from 'date-fns';
import { MatchParticipant } from '../../../../../types/match/participants/MatchParticipant';

/**
 * Determines the precision level needed for differentiating solving times among participants.
 *
 * @param {MatchParticipant[]} participants - An array of match participants.
 * @param {Date} matchStartDate - The start date and time of the match.
 * @returns {number} The precision level as an integer (0, 1, 2, or 3) where 0 indicates no fractional seconds are needed, and 3 indicates milliseconds precision.
 */
export const getSolvingTimePrecision = (
  participants: MatchParticipant[],
  matchStartDate: Date
): number => {
  const times = participants
    .filter((p) => p.puzzleSolution?.submittedAt)
    .map((p) =>
      differenceInMilliseconds(
        new Date(p.puzzleSolution!.submittedAt!),
        matchStartDate
      )
    );

  const uniqueSeconds = new Set(times.map((t) => Math.round(t / 1000))).size;
  const uniqueTenths = new Set(times.map((t) => Math.round(t / 100))).size;
  const uniqueHundredths = new Set(times.map((t) => Math.round(t / 10))).size;

  if (uniqueSeconds === times.length) {
    return 0;
  } else if (uniqueTenths === times.length) {
    return 1;
  } else if (uniqueHundredths === times.length) {
    return 2;
  }

  return 3;
};
