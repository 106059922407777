import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './PuzzleSolutionDetails.module.scss';
import SolutionDetail from '../SolutionDetail/SolutionDetail';
import { useMediaQuery } from '@mui/material';
import { ExpressionError as ExpressionErrorType } from '../../../../../../../types/match/ExpressionError';
import DistanceBar from '../DistanceBar/DistanceBar';
import { TimeColor } from './types/TimeColor';
import { DistanceSize } from './types/DistanceSize';

export interface PuzzleSolutionDetailsProps extends BoxProps {
  error?: ExpressionErrorType;
  distance?: number;
  distanceSize?: DistanceSize;
  displayTime?: boolean;
  expression?: string;
  expressionValue?: number;
  time?: string;
  timeColor?: TimeColor;
}

const PuzzleSolutionDetails = ({
  className,
  distance,
  distanceSize = 'large',
  displayTime = true,
  error,
  expression,
  expressionValue,
  time,
  timeColor,
  ...rest
}: PuzzleSolutionDetailsProps) => {
  const { t } = useTranslation();

  const minWidth600 = useMediaQuery('(min-width:600px)');
  const detailPlacement = minWidth600 ? 'end' : 'start';

  return (
    <Box
      className={classNames(styles['puzzle-solution-details'], className)}
      {...rest}
    >
      <Box className={classNames(styles['start-node'])}>
        <SolutionDetail
          className={styles['expression']}
          title={t('game.puzzle.expression')}
          titlePlacement='start'
          value={expression || '--'}
          valuePlacement='start'
        />

        {!error && (
          <Box className={styles['extra-solution-details']}>
            <SolutionDetail
              className={styles['value']}
              title={t('game.puzzle.value')}
              titlePlacement={detailPlacement}
              value={
                expressionValue !== undefined ? String(expressionValue) : '--'
              }
              valuePlacement={detailPlacement}
            />
            {displayTime && (
              <SolutionDetail
                className={classNames(
                  styles['value'],
                  timeColor && `color-match-outcome-${timeColor}`
                )}
                title={t('game.puzzle.time')}
                titlePlacement={detailPlacement}
                value={time !== undefined ? time : '--'}
                valuePlacement={detailPlacement}
              />
            )}
          </Box>
        )}
      </Box>

      <Box className={styles['end-node']}>
        <SolutionDetail
          title={t('game.puzzle.distance')}
          titlePlacement={'end'}
          value={distance !== undefined ? String(distance) : '--'}
          valuePlacement={'end'}
          valueProps={{
            className:
              distanceSize === 'large'
                ? styles['distance-size-large']
                : undefined,
          }}
          titleProps={{
            className: styles['distance-label'],
          }}
        />
        <DistanceBar distance={distance} />
      </Box>
    </Box>
  );
};

export default PuzzleSolutionDetails;
