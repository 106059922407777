import { useState } from 'react';
import styles from './PracticePuzzleboard.module.scss';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import assets from '../../../../assets/assets';
import PuzzleboardHeader from '../../components/Puzzleboard/PuzzleboardHeader/PuzzleboardHeader';
import Goalboard from '../../components/Puzzleboard/Goalboard/Goalboard';
import util from '../../../../util/util';
import Keyboard from '../../components/Puzzleboard/Keyboard/Keyboard';
import HowToPlayDialog from '../../../AppHeaderFooter/components/HowToPlayDialog/HowToPlayDialog';
import { Puzzle } from '../../../../types/match/Puzzle';
import PuzzleboardToolbar from '../../components/Puzzleboard/PuzzleboardToolbar/PuzzleboardToolbar';
import { t } from 'i18next';
import GameSettingsDialog from '../../components/Puzzleboard/GameSettingsDialog/GameSettingsDialog';
export interface PracticePuzzleboardProps {
  disabled?: boolean;
  puzzle: Puzzle;
  onClose: VoidFunction;
  onSubmit: (expression: string) => void;
  onSkip: VoidFunction;
}

const PracticePuzzleboard = ({
  disabled,
  puzzle,
  onClose,
  onSkip,
  onSubmit,
}: PracticePuzzleboardProps) => {
  const { goal, tokens } = puzzle;

  const [expression, setExpression] = useState('');

  const [isOpenHowToPlayDialog, setIsOpenHowToPlayDialog] = useState(false);
  const [isOpenGameSettingsDialog, setIsOpenGameSettingsDialog] =
    useState(false);

  return (
    <Box className={styles['puzzleboard']}>
      <PuzzleboardHeader>
        <Box className={styles['start-node']}>
          <Tooltip title={t('actions.close')}>
            <IconButton aria-label='close' onClick={onClose}>
              <SvgIcon>
                <assets.icons.close />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Box>

        <Box className={styles['end-node']}>
          <Tooltip title={t('navigation.navigation.howToPlay')}>
            <IconButton
              aria-label='how to play'
              onClick={() => setIsOpenHowToPlayDialog(true)}
            >
              <SvgIcon>
                <assets.icons.help />
              </SvgIcon>
            </IconButton>
          </Tooltip>

          <HowToPlayDialog
            open={isOpenHowToPlayDialog}
            onClose={() => setIsOpenHowToPlayDialog(false)}
          />

          <Tooltip title={t('navigation.more.sections.settings')}>
            <IconButton
              aria-label='settings'
              onClick={() => setIsOpenGameSettingsDialog(true)}
            >
              <SvgIcon>
                <assets.icons.settings />
              </SvgIcon>
            </IconButton>
          </Tooltip>

          <GameSettingsDialog
            open={isOpenGameSettingsDialog}
            onClose={() => setIsOpenGameSettingsDialog(false)}
          />
        </Box>
      </PuzzleboardHeader>

      <Goalboard goal={goal} />

      <PuzzleboardToolbar className={styles['puzzleboard-toolbar']}>
        <Tooltip title={t('actions.skip')}>
          <IconButton aria-label='close' onClick={onSkip}>
            <SvgIcon>
              <assets.icons.skip_next />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </PuzzleboardToolbar>

      <Keyboard
        disabled={disabled}
        tokens={tokens}
        onExpressionChange={setExpression}
        onSubmit={() =>
          onSubmit(util.strings.getRawExpressionString(expression))
        }
      />
    </Box>
  );
};

export default PracticePuzzleboard;
