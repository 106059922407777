import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import styles from './FooterSection.module.scss';

interface FooterSectionProps {
  title: string;
  links: { text: string; href: string; onClick?: () => void }[];
}

const FooterSection: React.FC<FooterSectionProps> = ({ title, links }) => {
  return (
    <Box className={styles['footer-section']}>
      <Typography variant='h6' className={styles['section-title']}>
        {title}
      </Typography>
      {links.map((link, index) => (
        <Link
          key={index}
          href={link.href}
          onClick={link.onClick}
          underline='hover'
          className={styles['footer-link']}
        >
          {link.text}
        </Link>
      ))}
    </Box>
  );
};

export default FooterSection;
