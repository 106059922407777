import React from 'react';

export interface RadioGroupContextValue {
  name: string | undefined;
  onChange: (value: string) => void;
  value: string | undefined;
}

const RadioGroupContext = React.createContext<
  RadioGroupContextValue | undefined
>(undefined);

export default RadioGroupContext;
