import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../../../components/Dialog/DialogHeader/DialogHeader';
import apiSlice, {
  useUpdateEmailRequestMutation,
} from '../../../../redux/slices/api/api';
import util from '../../../../util/util';
import { useAppDispatch } from '../../../../redux/hooks';
import styles from '../EditUsernameDialog/EditUsernameDialog.module.scss';
import PasswordField from '../../../../components/PasswordField/PasswordField';
import { ErrorResponse } from '../../../../redux/slices/api/types/ErrorResponse';
import getTranslationKeyDefaultnterpolationObject from '../../../../util/translation/getTranslationKeyDefaultnterpolationObject';
import useEmailValidation from '../../../../hooks/api/useEmailValidation/useEmailValidation';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

const EditEmailDialog = (props: DialogProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const debouncedEmail = useDebounce(email);
  const [emailErrorText, setEmailErrorText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const { error: emailValidationError } = useEmailValidation(debouncedEmail);

  const [
    updateEmail,
    {
      isLoading: isLoadingUpdateEmail,
      isSuccess: isSuccessUpdateEmail,
      isError: isErrorUpdateEmail,
      error: updateEmailError,
    },
  ] = useUpdateEmailRequestMutation();

  const [updateEmailErrorText, setUpdateEmailErrorText] = useState('');

  const dispatch = useAppDispatch();

  const [passwordInputRef, setPasswordInputRef] =
    useState<HTMLInputElement | null>(null);

  useEffect(() => {
    setPasswordErrorText('');
    setEmailErrorText('');
    setUpdateEmailErrorText('');
  }, [email, password]);

  useEffect(() => {
    if (emailValidationError === 'Email Unavailable') {
      setEmailErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            emailValidationError
          )
        )
      );
    }
  }, [emailValidationError, t]);

  const handleUpdateEmail = () => {
    const emailValidationError = util.validation.validateEmail(email);
    if (emailValidationError) {
      setEmailErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            emailValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              emailValidationError
            )
          )
        )
      );
      return;
    }

    const passwordValidationError = util.validation.validatePassword(password);
    if (passwordValidationError === 'Empty Password') {
      setPasswordErrorText(
        t(
          util.translation.getValidationErrorTranslationKey(
            passwordValidationError
          ),
          util.translation.getTranslationKeyDefaultnterpolationObject(
            util.translation.getValidationErrorTranslationKey(
              passwordValidationError
            )
          )
        )
      );
      return;
    }

    updateEmail({ newEmail: email, currentPassword: password });
  };

  useEffect(() => {
    if (isErrorUpdateEmail) {
      if (
        updateEmailError &&
        'data' in updateEmailError &&
        updateEmailError.data !== null
      ) {
        const response = updateEmailError.data as ErrorResponse;

        const translationFileKey =
          util.translation.getTranslationFileKeyFromErrorResponse(response);

        if (translationFileKey) {
          setUpdateEmailErrorText(
            t(
              translationFileKey,
              util.translation.getTranslationKeyDefaultnterpolationObject(
                translationFileKey
              )
            )
          );

          return;
        }
      }

      setUpdateEmailErrorText(t('errors.somethingWentWrongPleaseTryAgain'));
      passwordInputRef?.focus();
    }
  }, [isErrorUpdateEmail, updateEmailError, t, passwordInputRef]);

  const closeDialog = () => {
    if (isSuccessUpdateEmail) {
      dispatch(apiSlice.util.invalidateTags(['accountInformation']));
    }

    props?.onClose?.({}, 'escapeKeyDown');
  };

  return (
    <Dialog aria-describedby='edit-email-dialog-title' {...props}>
      {isSuccessUpdateEmail ? (
        <>
          <DialogHeader
            onClose={closeDialog}
            title={t('navigation.updateEmail.instructionsEmailSentTitle')}
            titleProps={{
              id: 'edit-email-dialog-title',
            }}
          />

          <DialogContent>
            <Typography>
              {t('navigation.updateEmail.instructionsEmailSentBody')}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={closeDialog}>
              {t('actions.ok')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogHeader
            onClose={closeDialog}
            closeDialogButtonProps={{
              disabled: isLoadingUpdateEmail,
            }}
            title={t('navigation.navigation.editEmail')}
            titleProps={{ id: 'edit-email-dialog-title' }}
          />

          <DialogContent>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              className={styles['inputs-container']}
            >
              <Typography>
                {t(
                  'navigation.updateEmail.instructions',
                  getTranslationKeyDefaultnterpolationObject(
                    'navigation.updateEmail.instructions'
                  )
                )}
              </Typography>

              <TextField
                autoComplete='off'
                autoFocus
                disabled={isLoadingUpdateEmail}
                error={Boolean(emailErrorText)}
                fullWidth
                helperText={emailErrorText}
                label={t('forms.newEmail')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleUpdateEmail();
                  }
                }}
                spellCheck={false}
                value={email}
              />

              <PasswordField
                disabled={isLoadingUpdateEmail}
                error={
                  Boolean(updateEmailErrorText) || Boolean(passwordErrorText)
                }
                fullWidth
                helperText={updateEmailErrorText || passwordErrorText}
                label={t('forms.password')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleUpdateEmail();
                  }
                }}
                inputRef={setPasswordInputRef}
                spellCheck={false}
                value={password}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button disabled={isLoadingUpdateEmail} onClick={handleUpdateEmail}>
              {t('actions.submit')}
            </Button>

            <Button disabled={isLoadingUpdateEmail} onClick={closeDialog}>
              {t('actions.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditEmailDialog;
