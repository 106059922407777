import { Typography } from '@mui/material';
import Page from '../../components/Page/Page';
import styles from './PageNotFound.module.scss';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const PageNotFound = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('errors.pageNotFound.title'));

  return (
    <Page className={styles['page']}>
      <Typography variant='page-h1'>
        {t('errors.pageNotFound.title')}
      </Typography>
      <Typography>
        {t('errors.pageNotFound.description')}{' '}
        <a href='/'>{t('errors.pageNotFound.backToHome')}</a>
      </Typography>
    </Page>
  );
};

export default PageNotFound;
