/**
 * Returns a display-friendly expression string.
 *
 * @param string - Expression string to prettify.
 * @returns Prettified expression string.
 *
 */
export const getPrettyExpressionString = (expression: string): string => {
  expression = expression.trim();
  expression = expression.replace(/\s/g, '');
  let displayString = '';
  for (let i = 0; i < expression.length; i++) {
    switch (expression[i]) {
      case '\u002B':
        displayString += ' + ';
        break;
      case '\u002D':
        displayString += ' \u2212 ';
        break;
      case '\u002A':
        displayString += ' \u00D7 ';
        break;
      case '\u002F':
        displayString += ' \u00F7 ';
        break;
      default:
        displayString += expression[i];
    }
  }
  return displayString;
};
