import { Box } from '@mui/material';
import classNames from 'classnames';
import Section from '../../../../components/Page/Section/Section';
import styles from './Profile.module.scss';
import { ProfileProps } from './Profile';
import GoalDistanceHeatMapSkeleton from '../GoalDistanceHeatMap/GoalDistanceHeatMapSkeleton';
import ProfileSummarySkeleton from '../ProfileSummary/ProfileSummarySkeleton';
import RecentMatchesSkeleton from '../RecentMatches/RecentMatchesSkeleton';

export interface ProfileSkeletonProps extends Partial<ProfileProps> {}

const ProfileSkeleton = ({ className }: ProfileSkeletonProps) => {
  return (
    <Box className={classNames(styles['profile'], className)}>
      <Section>
        <ProfileSummarySkeleton />
      </Section>

      <Section>
        <GoalDistanceHeatMapSkeleton />
      </Section>

      <Section>
        <RecentMatchesSkeleton />
      </Section>
    </Box>
  );
};

export default ProfileSkeleton;
