const account = {
  email: {
    minDaysBetweenChanges: 30,
  },
  username: {
    minDaysBetweenChanges: 30,
    minLength: 4,
    maxLength: 25,
  },
  password: {
    minLength: 8,
    maxLength: 64,
  },
};

export default account;
