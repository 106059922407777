import SectionSkeleton from '../../components/Page/Section/SectionSkeleton';
import { Skeleton } from '@mui/material';
import Page from '../../components/Page/Page';

const SettingsSkeleton = () => {
  return (
    <Page
    // title={t('Settings')}
    // titleProps={{
    //   variant: 'page-h1',
    // }}
    >
      <SectionSkeleton>
        <Skeleton variant='rounded' sx={{ width: '100%', height: '320rem' }} />
      </SectionSkeleton>

      <SectionSkeleton>
        <Skeleton variant='rounded' sx={{ width: '100%', height: '180rem' }} />
      </SectionSkeleton>

      <SectionSkeleton>
        <Skeleton variant='rounded' sx={{ width: '100%', height: '250rem' }} />
      </SectionSkeleton>
    </Page>
  );
};

export default SettingsSkeleton;
