import { jwtDecode } from 'jwt-decode';

const isJWTExpired = (token: string, thresholdMinutes: number = 0): boolean => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    if (decoded.exp) {
      // Check if the token is expiring within the specified threshold
      const expiresIn = decoded.exp * 1000;
      const currentTime = Date.now();
      const thresholdInMilliseconds = thresholdMinutes * 60 * 1000;
      return expiresIn - currentTime <= thresholdInMilliseconds;
    }

    return true;
  } catch (error) {
    console.error('Failed to decode token', error);
    return true;
  }
};

export default isJWTExpired;
