import { Box, BoxProps, Link, Typography } from '@mui/material';
import styles from './AppStoreBadges.module.scss';
import assets from '../../../../../assets/assets';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import config from '../../../../../config/config';

export interface AppStoreBadgesProps extends BoxProps {}

const AppStoreBadges = ({ className, ...rest }: AppStoreBadgesProps) => {
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(styles['app-store-badges'], className)}
      {...rest}
    >
      <Typography>{t('navigation.home.getTheApp')}</Typography>

      <Box className={styles['badges']}>
        <Link
          href={config.application.links.appStore}
          target='_blank'
          aria-label='Get it on App Store'
        >
          <assets.images.AppStoreBadge className={styles['badge']} />
        </Link>

        <Link
          href={config.application.links.playStore}
          target='_blank'
          aria-label='Get it on Play Store'
        >
          <img
            src={assets.images.PlayStoreBadge}
            alt='Get it on Google Play'
            className={styles['badge']}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default AppStoreBadges;
