import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import styles from './Signin.module.scss';
import SigninForm from './components/SigninForm/SigninForm';
import FormLayoutContainer from './components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from './components/FormLayoutFooter/FormLayoutFooter';

const Signin = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('actions.signin')}`);

  return (
    <Page className={styles['page']}>
      <FormLayoutContainer>
        <SigninForm />

        <FormLayoutFooter />
      </FormLayoutContainer>
    </Page>
  );
};

export default Signin;
