import { SvgIcon, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ReactNode } from 'react';
import styles from './NavigationButton.module.scss';
import classNames from 'classnames';
import { NavigationButtonVariant } from './types/NavigationButtonVariant';

export interface NavigationButtonProps {
  className?: string;
  icon: ReactNode;
  isActive?: boolean;
  title: string;
  variant?: NavigationButtonVariant;
}

const NavigationButton = ({
  className,
  icon,
  isActive,
  title,
  variant = 'icon',
}: NavigationButtonProps) => {
  if (variant === 'icon') {
    return (
      <Tooltip enterDelay={1000} title={title}>
        <div
          aria-hidden
          className={classNames(
            styles['navigation-button'],
            isActive && styles['is-active'],
            className
          )}
        >
          <SvgIcon className={styles['icon']}>{icon}</SvgIcon>
        </div>
      </Tooltip>
    );
  }

  return (
    <div
      aria-hidden
      className={classNames(
        styles['navigation-button'],
        isActive && styles['is-active'],
        className
      )}
    >
      <SvgIcon className={styles['icon']}>{icon}</SvgIcon>
      <Typography className={styles['title']}>{title}</Typography>
    </div>
  );
};

export default NavigationButton;
