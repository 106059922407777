import { useEffect } from 'react';
import useMatchmaking from '../../../../hooks/useMatchmaking/useMatchmaking';
import useSettings from '../../../../hooks/useSettings/useSettings';
import useUser from '../../../../hooks/useUser/useUser';

const ChallengeBlocker = () => {
  const { state, updateChallengeStatus } = useMatchmaking();
  const { challengeStatus } = state;
  const { user } = useUser();
  const { username } = user;

  const { blockChallengeAFriendRequests } = useSettings();

  useEffect(() => {
    const isChallenged =
      challengeStatus?.status === 'PENDING' &&
      challengeStatus.challengee === username;

    if (isChallenged && blockChallengeAFriendRequests) {
      updateChallengeStatus(challengeStatus.challengeId, 'BLOCKED');
    }
  }, [
    challengeStatus,
    blockChallengeAFriendRequests,
    username,
    updateChallengeStatus,
  ]);

  return null;
};

export default ChallengeBlocker;
