import { Card, Skeleton } from '@mui/material';
import classNames from 'classnames';
import styles from './ProfileSummary.module.scss';
import { ProfileSummaryProps } from './ProfileSummary';
import StatsSkeleton from '../ProfileStats/ProfileStatsSkeleton';

export interface ProfileSummarySkeletonProps
  extends Partial<ProfileSummaryProps> {}

const ProfileSummarySkeleton = ({
  className,
  ...rest
}: ProfileSummarySkeletonProps) => {
  return (
    <Card
      className={classNames(styles['profile-summary'], className)}
      {...rest}
    >
      <Skeleton className={styles['username-skeleton']} />

      <StatsSkeleton />
    </Card>
  );
};

export default ProfileSummarySkeleton;
