import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  SvgIcon,
  InputAdornment,
} from '@mui/material';
import styles from './SearchBar.module.scss';
import { useSearchUsersQuery } from '../../../../redux/slices/api/api';
import { User } from '../../../../redux/slices/api/types/accounts/SearchUsersResponse';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import assets from '../../../../assets/assets';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import classNames from 'classnames';

export interface SearchBarProps {
  className?: string;
}

const SearchBar = ({ className }: SearchBarProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [results, setResults] = useState<User[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { data, isLoading, isError, isFetching } = useSearchUsersQuery(
    { searchTerm: debouncedSearchTerm },
    { skip: !debouncedSearchTerm }
  );
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setResults(data.users);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div
      className={classNames(styles['search-bar-container'], className)}
      ref={searchRef}
    >
      <TextField
        value={searchTerm}
        onChange={handleSearch}
        onFocus={() => setIsFocused(true)}
        placeholder={t('actions.search')}
        variant='filled'
        fullWidth
        className={styles['search-field']}
        InputProps={{
          endAdornment:
            searchTerm !== '' ? (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setSearchTerm('')}
                  className={styles['search-text-field-end-icon-button']}
                >
                  <SvgIcon>
                    <assets.icons.close />
                  </SvgIcon>
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
      />
      {isFocused && searchTerm && (
        <Paper className={styles.resultsContainer}>
          {isLoading && (
            <CircularProgress className={styles.loadingIndicator} />
          )}
          {isError && (
            <div className={styles.error}>
              {t('errors.search.errorLoadingUsers')}
            </div>
          )}
          {data && results.length > 0 ? (
            <List>
              {results.map((user) => (
                <ListItem
                  key={user.id}
                  className={styles['result-item']}
                  onClick={() => {
                    navigate(`/user/${user.username}`);
                    setIsFocused(false);
                    setSearchTerm('');
                  }}
                >
                  <ListItemText primary={user.username} />
                </ListItem>
              ))}
            </List>
          ) : (
            !isLoading &&
            !isFetching && (
              <div className={styles.noResults}>
                {t('errors.search.noUsersFound')}
              </div>
            )
          )}
        </Paper>
      )}
    </div>
  );
};

export default SearchBar;
