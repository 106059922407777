import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import assets from '../../assets/assets';
import theme from '../../theme/theme';

export type PasswordFieldProps = {
  showPassword?: boolean;
} & TextFieldProps;

const PasswordField = ({
  showPassword: showPasswordProp = false,
  ...rest
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(showPasswordProp);

  useEffect(() => {
    setShowPassword(showPasswordProp);
  }, [showPasswordProp]);

  return (
    <TextField
      {...theme.components?.MuiTextField?.defaultProps}
      InputProps={{
        ...theme.components?.MuiTextField?.defaultProps?.InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? (
                <SvgIcon>
                  <assets.icons.visibility />
                </SvgIcon>
              ) : (
                <SvgIcon>
                  <assets.icons.visibility_off />
                </SvgIcon>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
      type={showPassword ? 'text' : 'password'}
      autoComplete='off'
    />
  );
};

export default PasswordField;
