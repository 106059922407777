import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import styles from './Challenge.module.scss';
import { Typography } from '@mui/material';
import FriendPicker from './components/FriendPicker/FriendPicker';
import { useGetFriendsQuery } from '../../redux/slices/api/api';
import useMatchmaking from '../../hooks/useMatchmaking/useMatchmaking';

const Challenge = () => {
  const { t } = useTranslation();

  const { createChallenge } = useMatchmaking();

  const {
    data: friends,
    isLoading: isLoadingFriends,
    isError: isErrorFriends,
  } = useGetFriendsQuery();

  if (!friends || isLoadingFriends) return null;

  return (
    <Page className={styles['page']}>
      <Typography variant='page-h1' className={'heading-notch'}>
        {t('game.gameModes.challenge.title')}
      </Typography>

      <FriendPicker
        onPick={(username) => {
          createChallenge(username);
        }}
        friends={friends.friends.map((f) => f.name)}
      />
    </Page>
  );
};

export default Challenge;
