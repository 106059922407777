import { Box } from '@mui/system';
import classNames from 'classnames';
import OperatorSkeleton from './Operator/OperatorSkeleton';
import { OperatorsProps } from './Operators';
import styles from './Operators.module.scss';
import GAMEBOARD_OPERATORS from './util';

export interface OperatorsSkeletonProps extends Partial<OperatorsProps> {}

const OperatorsSkeleton = ({ className, ...rest }: OperatorsSkeletonProps) => {
  return (
    <Box className={classNames(styles['operators'], className)} {...rest}>
      {GAMEBOARD_OPERATORS.map((operator) => (
        <OperatorSkeleton key={operator.value} />
      ))}
    </Box>
  );
};

export default OperatorsSkeleton;
