import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Page from '../../../components/Page/Page';
import styles from './PageLoadingError.module.scss';
import { Trans, useTranslation } from 'react-i18next';

const PageLoadingError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page className={styles['page']} paddingSize='large'>
      <Typography variant='page-h1'>
        {t('errors.pageLoadingError.title')}
      </Typography>

      <Typography className={styles['body-text']}>
        <Trans
          i18nKey='errors.pageLoadingError.description'
          components={[
            <Link
              component='button'
              onClick={() => window.location.reload()}
              underline='hover'
            />,
            <Link
              component='button'
              onClick={() => navigate('/')}
              underline='hover'
            />,
          ]}
        />
      </Typography>
    </Page>
  );
};

export default PageLoadingError;
