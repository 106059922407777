import { Box, Button, Typography, useColorScheme } from '@mui/material';
import styles from './HomeUnauthorized.module.scss';
import assets from '../../../assets/assets';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeFooter from './components/HomeFooter/HomeFooter';
import useDocumentTitle from '../../../hooks/useDocumentTitle/useDocumentTitle';
import { useGuestSigninMutation } from '../../../redux/slices/api/api';
import { useEffect } from 'react';
import { AuthTokens } from '../../../redux/slices/auth/types/AuthTokens';
import { useAppDispatch } from '../../../redux/hooks';
import { setAuthTokens } from '../../../redux/slices/auth/auth';
import { jwtDecode } from 'jwt-decode';
import useUser from '../../../hooks/useUser/useUser';
import { UserType } from '../../../redux/slices/user/types/UserType';
import AppStoreBadges from './components/AppStoreBadges/AppStoreBadges';

const HomeUnauthorized = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Boronu'), { disableAffixes: true });
  const { colorScheme } = useColorScheme();
  const dispatch = useAppDispatch();
  const { setUser } = useUser();

  const [
    guestSignin,
    {
      data: guestsigninData,
      isSuccess: isSuccessGuestSignin,
      isLoading: isLoadingGuestSignin,
    },
  ] = useGuestSigninMutation();

  useEffect(() => {
    if (isSuccessGuestSignin && guestsigninData) {
      const authTokens: AuthTokens = {
        accessToken: guestsigninData.accessToken,
        refreshToken: guestsigninData.refreshToken,
      };

      dispatch(setAuthTokens(authTokens));

      const decodedAccessToken = jwtDecode(authTokens.accessToken);

      setUser({
        isSignedIn: true,
        username: decodedAccessToken.sub!,
        type:
          'type' in decodedAccessToken
            ? (decodedAccessToken.type as UserType)
            : 'GUEST',
      });
    }
  }, [isSuccessGuestSignin, guestsigninData, t, setUser, dispatch]);

  return (
    <Box className={styles['home']}>
      <div className={styles['logo-container']}>
        {colorScheme === 'light' ? (
          <assets.images.BoronuLogoDark className={styles['logo']} />
        ) : (
          <assets.images.BoronuLogoLight className={styles['logo']} />
        )}
      </div>
      <div className={styles['body-container']}>
        <div className={styles['body']}>
          <div className={styles['heading']}>
            <Typography variant='h1' className={styles['title']}>
              {t('navigation.home.heading')}
            </Typography>
            <Typography variant='body1' className={styles['subtitle']}>
              {t('navigation.home.subheading')}
            </Typography>
          </div>

          <div className={styles['actions']}>
            <Button
              color='primary'
              component={NavLink}
              fullWidth
              size='large'
              className={styles['action-button']}
              to='/signin'
            >
              {t('actions.signin')}
            </Button>

            <Button
              color='primary'
              component={NavLink}
              fullWidth
              size='large'
              className={styles['action-button']}
              to='/signup'
            >
              {t('actions.createAccount')}
            </Button>

            <Button
              disabled={isLoadingGuestSignin}
              color='primary'
              fullWidth
              size='large'
              className={styles['action-button']}
              onClick={() => guestSignin()}
              variant='text'
            >
              {t('actions.playAsGuest')}
            </Button>
          </div>

          <AppStoreBadges className={styles['app-store-badges']} />
        </div>

        <HomeFooter className={styles['home-footer']} />
      </div>
    </Box>
  );
};

export default HomeUnauthorized;
