import { useParams } from 'react-router-dom';
import Page from '../../components/Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useGetProfileQuery } from '../../redux/slices/api/api';
import TimeoutRender from '../../components/TimeoutRender/TimeoutRender';
import ProfileSkeleton from '../Profile/components/Profile/ProfileSkeleton';
import DefaultProfile from '../Profile/components/Profile/Profile';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import useUser from '../../hooks/useUser/useUser';

const User = () => {
  const { user } = useUser();
  const { username: profileViewer } = user;
  const { username } = useParams<{ username: string }>();

  useDocumentTitle(username || '');

  const { data: profile, isError } = useGetProfileQuery(
    {
      username: username!,
    },
    { skip: !username }
  );

  if (isError) {
    return <ErrorPage />;
  }

  if (!profile) {
    return (
      <TimeoutRender>
        <Page>
          <ProfileSkeleton />
        </Page>
      </TimeoutRender>
    );
  }

  return (
    <Page>
      <DefaultProfile data={profile!} profileViewer={profileViewer} />
    </Page>
  );
};

export default User;
