import React, { useState } from 'react';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import styles from './FriendPicker.module.scss'; // Define your styles here
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ItemPicker from '../ItemPicker/ItemPicker';

interface FriendPickerProps extends BoxProps {
  friends: string[];
  onPick: (friend: string) => void;
}

const FriendPicker: React.FC<FriendPickerProps> = ({
  className,
  friends,
  onPick,
  ...rest
}) => {
  const { t } = useTranslation();

  const [pickedFriend, setPickedFriend] = useState<string | null>(null);

  return (
    <Box className={classNames(styles['picker-body'], className)} {...rest}>
      <Typography variant='page-h2'>{t('actions.selectAFriend')}</Typography>

      <ItemPicker
        className={styles['item-picker']}
        onPick={setPickedFriend}
        items={friends}
        noItemsFoundWarning={t('navigation.friends.noFriendsFound')}
      />

      <Box className={styles['actions']}>
        <Button
          className={styles['action-button']}
          disabled={!pickedFriend}
          color='primary'
          onClick={() => onPick(pickedFriend!)}
        >
          {t('actions.challenge')}
        </Button>
      </Box>
    </Box>
  );
};

export default FriendPicker;
