import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiFilledInput: {
  defaultProps?: ComponentsProps['MuiFilledInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFilledInput'];
  variants?: ComponentsVariants<Theme>['MuiFilledInput'];
} = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 8,
      backgroundColor: theme.palette.background.secondary,
      border: `1px solid ${theme.palette.background.secondary}`,
      '&.Mui-error': {
        borderColor: theme.palette.error.main,
      },
    }),
  },
};

export default MuiFilledInput;
