import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './SwitchScreen.css';
import styles from './SwitchScreen.module.scss';
import { Screen } from './types/Screen';

export interface SwitchScreenProps {
  activeScreen: Screen;
  primaryScreen: ReactNode;
  secondaryScreen: ReactNode;
}

const SwitchScreen = ({
  activeScreen: activeScreenProp,
  primaryScreen,
  secondaryScreen,
}: SwitchScreenProps) => {
  const [activeScreen, setActiveScreen] = useState<Screen>(activeScreenProp);
  const primaryScreenRef = useRef<HTMLDivElement | null>(null);
  const secondaryScreenRef = useRef<HTMLDivElement | null>(null);
  const nodeRef =
    activeScreen === 'primary' ? primaryScreenRef : secondaryScreenRef;

  useEffect(() => {
    setActiveScreen(activeScreenProp);
  }, [activeScreenProp]);

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={activeScreen}
        nodeRef={nodeRef}
        addEndListener={(done: any) => {
          if (nodeRef?.current) {
            nodeRef.current.addEventListener('transitionend', done, false);
          }
        }}
        classNames='fade'
      >
        <div ref={nodeRef} className={styles['body-container']}>
          <div className={classNames('switch-body')}>
            {activeScreen === 'primary' ? primaryScreen : secondaryScreen}
          </div>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default SwitchScreen;
