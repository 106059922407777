import { motion, MotionProps } from 'framer-motion';

export interface FadeInRightProps extends MotionProps {}

const FadeInRight = ({ children, ...rest }: FadeInRightProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.15 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default FadeInRight;
