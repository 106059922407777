import { useTranslation } from 'react-i18next';
import Chip from '../../../../components/Chip/Chip';
import RadioGroup, {
  RadioGroupProps,
} from '../../../../components/RadioGroup/RadioGroup';

export interface FriendTabsChipGroupProps extends Partial<RadioGroupProps> {}

const FriendTabsChipGroup = ({ value, ...rest }: FriendTabsChipGroupProps) => {
  const { t } = useTranslation();

  return (
    <RadioGroup value={value} {...rest}>
      <Chip label={t('navigation.navigation.friends')} value='0' />
      <Chip label={t('navigation.friends.requests')} value='1' />
      <Chip label={t('navigation.friends.sent')} value='2' />
    </RadioGroup>
  );
};

export default FriendTabsChipGroup;
