import { useEffect, useState } from 'react';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import styles from './MatchmakingPuzzleboard.module.scss';
import PuzzleboardHeader from '../../components/Puzzleboard/PuzzleboardHeader/PuzzleboardHeader';
import assets from '../../../../assets/assets';
import MatchClock from '../../components/Puzzleboard/MatchClock/MatchClock';
import config from '../../../../config/config';
import Goalboard from '../../components/Puzzleboard/Goalboard/Goalboard';
import Keyboard from '../../components/Puzzleboard/Keyboard/Keyboard';
import util from '../../../../util/util';
import Playerboard from '../../components/Puzzleboard/Playerboard/Playerboard';
import { MatchParticipant } from '../../../../types/match/participants/MatchParticipant';
import { Puzzle } from '../../../../types/match/Puzzle';
import { MatchmakingGameMode } from '../../../../types/Gamemode';
import useSettings from '../../../../hooks/useSettings/useSettings';
import useAudio from '../../../../hooks/useAudio/useAudio';
import { useTranslation } from 'react-i18next';
import GameSettingsDialog from '../../components/Puzzleboard/GameSettingsDialog/GameSettingsDialog';

export interface MatchmakingPuzzleboardProps {
  disabled?: boolean;
  gameMode: MatchmakingGameMode;
  participants: MatchParticipant[];
  puzzle: Puzzle;
  onClose: VoidFunction;
  onSubmit: (expression: string) => void;
}

const MatchmakingPuzzleboard = ({
  disabled,
  gameMode,
  participants,
  puzzle,
  onClose,
  onSubmit,
}: MatchmakingPuzzleboardProps) => {
  const { t } = useTranslation();

  const { goal, tokens } = puzzle;

  const [expression, setExpression] = useState('');

  const handleSubmit = () =>
    onSubmit(util.strings.getRawExpressionString(expression));

  const { enableGameBoardSounds } = useSettings();

  const playBlip = useAudio(assets.audio.cabled_mess_blip, {
    soundEnabled: enableGameBoardSounds,
  });

  const [isOpenGameSettingsDialog, setIsOpenGameSettingsDialog] =
    useState(false);

  useEffect(() => {
    playBlip();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={styles['puzzleboard']}>
      <PuzzleboardHeader className={styles['puzzleboard-header']}>
        <Box className={styles['header-content-row']}>
          <Box className={styles['header-start-node']}>
            <IconButton aria-label='close' onClick={onClose}>
              <SvgIcon>
                <assets.icons.close />
              </SvgIcon>
            </IconButton>
          </Box>

          <Box className={styles['header-center-node']}>
            <Playerboard
              players={participants.map((participant) => ({
                username: participant.username,
                rating:
                  'preMatchRating' in participant
                    ? participant.preMatchRating
                    : undefined,
              }))}
              className={styles['header-center-node']}
            />
          </Box>

          <Box className={styles['header-end-node']}>
            <Tooltip title={t('navigation.more.sections.settings')}>
              <IconButton
                aria-label='settings'
                onClick={() => setIsOpenGameSettingsDialog(true)}
              >
                <SvgIcon>
                  <assets.icons.settings />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <GameSettingsDialog
              open={isOpenGameSettingsDialog}
              onClose={() => setIsOpenGameSettingsDialog(false)}
            />
          </Box>
        </Box>

        <Box className={styles['header-content-row']}>
          <MatchClock
            seconds={config?.game[gameMode]?.duration}
            onTimeElapsed={handleSubmit}
          />
        </Box>
      </PuzzleboardHeader>

      <Goalboard goal={goal} />

      <Keyboard
        disabled={disabled}
        tokens={tokens}
        onExpressionChange={setExpression}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default MatchmakingPuzzleboard;
