import classNames from 'classnames';
import styles from './Profile.module.scss';
import { ProfileData } from './types';
import GoalDistanceHeatMap from '../GoalDistanceHeatMap/GoalDistanceHeatMap';
import ProfileSummary from '../ProfileSummary/ProfileSummary';
import { Box, BoxProps } from '@mui/material';
import Section from '../../../../components/Page/Section/Section';
import { getParticipantsPuzzleSolution } from '../../../../util/matches/getParticipantsPuzzleSolution';
import RecentMatches from '../RecentMatches/RecentMatches';
import ProfileActions from '../ProfileActions/ProfileActions';

export interface ProfileProps extends BoxProps {
  data: ProfileData;
  profileViewer: string;
}

const Profile = ({ className, data, profileViewer, ...rest }: ProfileProps) => {
  const {
    username,
    matchesWon,
    matchesPlayed,
    rank,
    ratedGameModeRating,
    matches,
  } = data;

  return (
    <Box className={classNames(styles['profile'], className)} {...rest}>
      <Section
        className={classNames(
          styles['profile-summary-container']
          // username === profileViewer && styles['profile-summary-top-margin']
        )}
      >
        <ProfileSummary
          matches={matchesPlayed}
          rank={rank}
          rating={ratedGameModeRating}
          username={username}
          winRate={(matchesWon / matchesPlayed) * 100 || 0}
        />
        {username !== profileViewer && (
          <ProfileActions
            className={styles['profile-actions']}
            profileOwner={username}
          />
        )}
      </Section>

      <Section>
        <GoalDistanceHeatMap
          tiles={(matches || [])
            .filter((match) => match.endedAt)
            .map((match) => {
              const expressionValue = getParticipantsPuzzleSolution(
                username,
                match.participants
              )?.expressionValue;

              const tileValue =
                expressionValue !== undefined
                  ? Math.abs(expressionValue - match.puzzle.goal)
                  : null;

              return {
                value: tileValue,
                linkProps: {
                  href: `/match/${match.id}`,
                },
              };
            })}
        />
      </Section>

      <Section>
        <RecentMatches
          matches={matches.slice(0, 32)}
          matchHistoryOwner={username}
        />
      </Section>
    </Box>
  );
};

export default Profile;
