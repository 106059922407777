import { differenceInSeconds, formatDistanceToNow } from 'date-fns';
import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { useEffect } from 'react';
import util from '../../../../util/util';
import {
  Box,
  CircularProgress,
  Typography,
  TypographyProps,
} from '@mui/material';
import styles from './MatchEndDate.module.scss';
import { useCountdown } from '../../../../hooks/useCountdown/useCountdown';

export interface MatchEndDateProps extends TypographyProps {
  endDate: Date;
}

const MatchEndDate = ({ endDate, className, ...rest }: MatchEndDateProps) => {
  const appLanguage = useAppSelector(
    (state: RootState) => state.settings.appLanguage
  );

  const matchInProgress = endDate > new Date();

  const [count, { startCountdown }] = useCountdown({
    countStart: differenceInSeconds(endDate, new Date()),
    countStop: -1,
  });

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  if (count > -1 && matchInProgress) {
    return (
      <Box className={styles['progress-container']}>
        <CircularProgress className={styles['progress']} size='20rem' />
        <Typography {...rest}>{count}</Typography>
      </Box>
    );
  }

  return (
    <Typography {...rest}>
      {formatDistanceToNow(endDate, {
        addSuffix: true,
        locale: util.dates.getDateFnsLocale(appLanguage),
      })}
    </Typography>
  );
};

export default MatchEndDate;
