import gameModes from './en-US/game/gameModes.json';
import matchOutcomes from './en-US/game/matchOutcomes.json';
import puzzle from './en-US/game/puzzle.json';

import faq from './en-US/navigation/faq.json';
import friends from './en-US/navigation/friends.json';
import home from './en-US/navigation/home.json';
import matchmaking from './en-US/navigation/matchmaking.json';
import more from './en-US/navigation/more.json';
import play from './en-US/navigation/play.json';
import navigation from './en-US/navigation/navigation.json';
import profile from './en-US/navigation/profile.json';
import resetPassword from './en-US/navigation/resetPassword.json';
import signin from './en-US/navigation/signin.json';
import signup from './en-US/navigation/signup.json';
import standings from './en-US/navigation/standings.json';
import updateEmail from './en-US/navigation/updateEmail.json';

import accountSettings from './en-US/settings/accountSettings.json';
import appThemeSettings from './en-US/settings/appThemeSettings.json';
import languageSettings from './en-US/settings/languageSettings.json';
import game from './en-US/settings/game.json';

import actions from './en-US/actions.json';
import dialogs from './en-US/dialogs.json';
import errors from './en-US/errors.json';
import forms from './en-US/forms.json';
import validation from './en-US/validation.json';

const enUS = {
  game: {
    gameModes,
    matchOutcomes,
    puzzle,
  },
  navigation: {
    faq,
    friends,
    home,
    matchmaking,
    more,
    navigation,
    play,
    profile,
    resetPassword,
    signin,
    signup,
    standings,
    updateEmail,
  },
  settings: {
    accountSettings,
    appThemeSettings,
    languageSettings,
    game,
  },
  actions,
  dialogs,
  errors,
  forms,
  validation,
};

export default enUS;
