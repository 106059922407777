import {
  Box,
  BoxProps,
  Button,
  Link,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './HomeFooter.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import assets from '../../../../../assets/assets';
import { appLanguages } from '../../../../../i18n';
import useSettings from '../../../../../hooks/useSettings/useSettings';
import ChangeAppLanguageDialog from '../../../../AppHeaderFooter/components/AppFooter/ChangeAppLanguageDialog/ChangeAppLanguageDialog';

export interface HomeFooterProps extends BoxProps {}

const HomeFooter = ({ className, ...rest }: HomeFooterProps) => {
  const { t } = useTranslation();
  const [isOpenChangeAppLanguageDialog, setIsOpenChangeAppLanguageDialog] =
    useState(false);
  const { language } = useSettings();

  return (
    <Box className={classNames(styles['home-footer'], className)} {...rest}>
      <div className={styles['footer-list']}>
        <Button
          className={styles['change-language-button']}
          onClick={() => setIsOpenChangeAppLanguageDialog(true)}
          startIcon={
            <SvgIcon>
              <assets.icons.language />
            </SvgIcon>
          }
        >
          {(() => {
            const selectedLanguage = appLanguages.find(
              (lang) => lang.locale === language
            );
            return selectedLanguage?.name;
          })()}
        </Button>

        <ChangeAppLanguageDialog
          open={isOpenChangeAppLanguageDialog}
          onClose={() => setIsOpenChangeAppLanguageDialog(false)}
          maxWidth='xs'
        />

        <Link
          className={styles['footer-link']}
          color='inherit'
          underline='hover'
          href={'/privacy'}
          target='_blank'
        >
          {t('navigation.navigation.privacyPolicy')}
        </Link>

        <Link
          className={styles['footer-link']}
          color='inherit'
          underline='hover'
          href={'/terms-of-service'}
          target='_blank'
        >
          {t('navigation.navigation.termsOfService')}
        </Link>

        <Typography className={styles['copyright']}>
          &copy; {new Date().getFullYear()} Boronu
        </Typography>
      </div>
    </Box>
  );
};

export default HomeFooter;
