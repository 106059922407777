import { useTranslation } from 'react-i18next';
import Chip from '../../../../components/Chip/Chip';
import RadioGroup, {
  RadioGroupProps,
} from '../../../../components/RadioGroup/RadioGroup';

export interface GamemodesChipGroupProps extends Partial<RadioGroupProps> {}

const GamemodesChipGroup = ({ ...rest }: GamemodesChipGroupProps) => {
  const { t } = useTranslation();

  return (
    <RadioGroup value={'all'} {...rest}>
      <Chip label={t('game.gameModes.all')} value='all' />
      <Chip label={t('game.gameModes.practice.title')} value='practice' />
      <Chip label={t('game.gameModes.normal.title')} value='normal' />
      <Chip label={t('game.gameModes.rated.title')} value='rated' />
      <Chip
        label={t('game.gameModes.challenge.shortTitle')}
        value='challenge'
      />
    </RadioGroup>
  );
};

export default GamemodesChipGroup;
