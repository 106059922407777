import { useTranslation } from 'react-i18next';
import appStyles from '../../App.module.scss';
import PageLoadingError from './PageLoadingError/PageLoadingError';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const ErrorPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('errors.pageLoadingError.title'));

  return (
    <div className={appStyles['app']}>
      <PageLoadingError />
    </div>
  );
};

export default ErrorPage;
