import { useEffect } from 'react';
import { useCountdown } from '../../../../../hooks/useCountdown/useCountdown';
import { Typography, TypographyProps } from '@mui/material';
import classNames from 'classnames';
import styles from './MatchClock.module.scss';
import assets from '../../../../../assets/assets';
import useAudio from '../../../../../hooks/useAudio/useAudio';
import useSettings from '../../../../../hooks/useSettings/useSettings';

export interface MatchClockProps extends TypographyProps {
  seconds: number;
  onTimeElapsed?: VoidFunction;
}

const MatchClock = ({
  className,
  onTimeElapsed,
  seconds,
  ...rest
}: MatchClockProps) => {
  const [count, { startCountdown }] = useCountdown({ countStart: seconds });

  const { enableGameBoardSounds } = useSettings();

  const playBlip = useAudio(assets.audio.cabled_mess_blip, {
    soundEnabled: enableGameBoardSounds,
  });

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  useEffect(() => {
    if ([1, 2, 3, 4, 5, 10].includes(count)) {
      playBlip();
    }

    if (count < 1) {
      onTimeElapsed?.();
    }
  }, [count, onTimeElapsed, playBlip]);

  return (
    <Typography
      className={classNames(styles['match-clock'], className)}
      {...rest}
    >
      {count}
    </Typography>
  );
};

export default MatchClock;
