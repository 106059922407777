import DefaultSection, {
  SectionProps as DefaultSectionProps,
} from '../../../../components/Page/Section/Section';

export interface SectionProps extends DefaultSectionProps {}

const Section = ({ sx, ...rest }: SectionProps) => {
  return (
    <DefaultSection
      sx={{
        gap: '20rem',
        overflow: 'visible',
        padding: 0,
        ...sx,
      }}
      {...rest}
    />
  );
};

export default Section;
