import { Box } from '@mui/system';
import classNames from 'classnames';
import { GoalDistanceHeatMapProps } from './GoalDistanceHeatMap';
import HeatMapSkeleton from './HeatMap/HeatMapSkeleton';
import { Skeleton } from '@mui/material';

export interface GoalDistanceHeatMapSkeletonProps
  extends Partial<GoalDistanceHeatMapProps> {}

const GoalDistanceHeatMapSkeleton = ({
  className,
  ...rest
}: GoalDistanceHeatMapSkeletonProps) => {
  return (
    <Box className={classNames(className)} {...rest}>
      <Skeleton
        sx={{ width: '140rem', height: '30rem', marginBottom: '20rem' }}
      />

      <HeatMapSkeleton />
    </Box>
  );
};

export default GoalDistanceHeatMapSkeleton;
