import { Box, BoxProps } from '@mui/material';
import classNames from 'classnames';
import styles from './DistanceBar.module.scss';
import util from '../../../../../../../util/util';

export interface DistanceBarProps extends BoxProps {
  distance?: number | null;
}

const DistanceBar = ({ className, distance, ...rest }: DistanceBarProps) => {
  return (
    <Box
      className={classNames(
        styles['distance-bar'],
        `background-color-goal-distance-${
          distance !== undefined && distance !== null
            ? util.theme.getGoalDistanceColorThemeKey(distance)
            : 'null'
        }`,
        className
      )}
      {...rest}
    />
  );
};

export default DistanceBar;
