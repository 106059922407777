import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './Chip.module.scss';
import useRadioGroup from '../RadioGroup/useRadioGroup';
import classNames from 'classnames';

export interface ChipProps extends Omit<ButtonProps, 'onChange'> {
  defaultChecked?: boolean;
  label: string;
  labelProps?: Partial<TypographyProps>;
  onChange?: (value: string) => void;
  value?: string;
}

const Chip = ({
  className,
  defaultChecked,
  label,
  labelProps,
  onChange,
  onClick,
  value,
  ...rest
}: ChipProps) => {
  const radioGroup = useRadioGroup();
  let isChecked = defaultChecked;
  if (radioGroup) {
    isChecked = radioGroup.value === value;
  }
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    if (radioGroup?.value) {
      setChecked(radioGroup?.value === value);
    }
  }, [radioGroup?.value, value]);

  return (
    <Button
      className={classNames(
        styles['chip'],
        isChecked && styles['chip-checked'],
        className
      )}
      onClick={(e) => {
        if (value) {
          if (radioGroup) {
            radioGroup.onChange(value);
          } else {
            setChecked(!checked);
          }
          onChange?.(value);
        }

        onClick?.(e);
      }}
      {...rest}
    >
      <Typography {...labelProps}>{label}</Typography>
    </Button>
  );
};

export default Chip;
