import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import HeatMap from './HeatMap/HeatMap';
import { TileProps } from './HeatMap/Tile/Tile';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface GoalDistanceHeatMapProps extends BoxProps {
  tiles: TileProps[];
}

const GoalDistanceHeatMap = ({
  className,
  tiles,
  ...rest
}: GoalDistanceHeatMapProps) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames(className)} {...rest}>
      <Typography variant='page-h2'>
        {t('navigation.profile.goalDistances')}
      </Typography>

      {tiles.length ? (
        <HeatMap tiles={tiles} />
      ) : (
        <Typography>{t('navigation.profile.noPlayedMatchesFound')}</Typography>
      )}
    </Box>
  );
};

export default GoalDistanceHeatMap;
