import apiSlice, { useSignoutQuery } from '../../redux/slices/api/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetState as resetMatchmakingState } from '../../redux/slices/matchmaking/matchmaking';
import { resetState as resetUserState } from '../../redux/slices/user/user';
import { clearAuthTokens } from '../../redux/slices/auth/auth';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const Signout = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('actions.signout'));

  const refreshToken = useAppSelector(
    (state: RootState) => state.auth.refreshToken
  );

  const { isLoading } = useSignoutQuery(undefined, { skip: !refreshToken });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      dispatch(apiSlice.util.resetApiState());
      dispatch(resetMatchmakingState());
      dispatch(resetUserState());
      dispatch(clearAuthTokens());

      navigate('/');
    }
  }, [isLoading, dispatch, navigate]);

  return null;
};

export default Signout;
