import styles from './Token.module.scss';
import classNames from 'classnames';
import { Skeleton } from '@mui/material';
import { TokenProps } from './Token';

export interface TokenSkeletonProps extends Partial<TokenProps> {}

const TokenSkeleton = ({ className, size = 'medium' }: TokenSkeletonProps) => {
  return (
    <Skeleton
      className={classNames(
        styles['token'],
        styles[`size-${size}-skeleton`],
        className
      )}
      variant='rounded'
    />
  );
};

export default TokenSkeleton;
