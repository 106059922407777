import { addDays, differenceInDays } from 'date-fns';

/**
 * Calculates availability date.
 *
 * @param becameUnavailableAt - Date unavailability happened.
 * @returns Date availability starts.
 *
 */
const getAvailableAt = (
  becameUnavailableAt: Date,
  daysDisabledFor: number
): Date => {
  const disabledDaysAgo = Math.abs(
    differenceInDays(new Date(), new Date(becameUnavailableAt))
  );

  const availableAt = addDays(new Date(), daysDisabledFor - disabledDaysAgo);

  return availableAt;
};

export default getAvailableAt;
