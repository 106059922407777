import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './Section.module.scss';
import { SectionProps } from './Section';

export interface SectionSkeletonProps extends Partial<SectionProps> {}

const SectionSkeleton = ({
  children,
  className,
  // subtitle,
  // subtitleProps,
  // title,
  // titleProps = {
  //   variant: 'page-h2',
  // },
  sx,
  ...rest
}: SectionProps) => {
  return (
    <Card
      className={classNames(styles['section'], className)}
      // sx={{ bgcolor: 'unset', gap: '8rem', ...sx }}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default SectionSkeleton;
