import Page from '../../components/Page/Page';
import StandingsTableSkeleton from './components/StandingsTable/StandingsTableSkeleton';
import Section from '../../components/Page/Section/Section';
import { Skeleton, Typography } from '@mui/material';

const StandingsSkeleton = () => {
  return (
    <Page paddingSize='medium'>
      <Typography variant='h1'>
        <Skeleton />
      </Typography>

      <Section>
        <StandingsTableSkeleton />
      </Section>
    </Page>
  );
};

export default StandingsSkeleton;
