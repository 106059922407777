import config from '../../config/config';
import { UsernameValidationErrorType } from '../../redux/slices/api/types/error/UsernameValidationErrorType';
import { isValidUsernameFormat } from '../strings/isValidUsernameFormat';

/**
 * Checks if given username has a valid length.
 *
 * @param {string} username - Username on which to perform the check on.
 * @returns {boolean} a Boolean value that indicates whether or not a given username
 * has a valid length.
 *
 */
export const isValidUsernameLength = (username: string) => {
  const { length } = username;

  return (
    config.account.username.minLength <= length &&
    length <= config.account.username.maxLength
  );
};

/**
 * Returns validity of a given username.
 *
 * @param {string} username - Username string to check the validity of.
 * @returns {enum} Undefined if username is valid, error otherwise.
 *
 */
export const validateUsername = (
  username: string
): UsernameValidationErrorType | undefined => {
  if (username.length < 1) {
    return 'Empty Username';
  } else if (!isValidUsernameFormat(username)) {
    return 'Invalid Username Format';
  } else if (!isValidUsernameLength(username)) {
    return 'Invalid Username Length';
  }
};

/**
 * Checks for username validity.
 *
 * @param {string} username - Username on which to perform the check on.
 * @returns {boolean} a Boolean value that indicates whether or not a given username
 * is valid.
 *
 */
export const isValidUsername = (username: string) =>
  validateUsername(username) === undefined;
