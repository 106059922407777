import { ReactComponent as BoronuLogoDark } from './images/boronu_horizontal_dark.svg';
import { ReactComponent as BoronuLogoLight } from './images/boronu_horizontal_light.svg';
import { ReactComponent as BoronuText } from './images/boronu_text.svg';
import { ReactComponent as SquaresSplash } from './images/squares_splash.svg';
import { ReactComponent as AppStoreBadge } from './images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import PlayStoreBadge from './images/GetItOnGooglePlay_Badge_Web_color_English.png';

const images = {
  BoronuLogoDark,
  BoronuLogoLight,
  BoronuText,
  AppStoreBadge,
  PlayStoreBadge,
  SquaresSplash,
};

export default images;
