import { Box, BoxProps, Button, SvgIcon } from '@mui/material';
import classNames from 'classnames';
import styles from './ProfileActions.module.scss';
import assets from '../../../../assets/assets';
import {
  useFriendRequestRespondMutation,
  useGetFriendsQuery,
  useGetPendingFriendRequestsQuery,
  useSendFriendRequestMutation,
  useUnfriendMutation,
} from '../../../../redux/slices/api/api';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMatchmaking from '../../../../hooks/useMatchmaking/useMatchmaking';

export interface ProfileActionsProps extends BoxProps {
  profileOwner: string;
}

const ProfileActions = ({
  className,
  profileOwner,
  ...rest
}: ProfileActionsProps) => {
  const { t } = useTranslation();

  const {
    data: friends,
    isLoading: isLoadingFriends,
    isError: isErrorFriends,
    refetch: refetchGetFriends,
  } = useGetFriendsQuery();

  const [
    sendFriendRequest,
    {
      isLoading: isLoadingSendFriendRequest,
      isSuccess: isSuccessSendFriendRequest,
    },
  ] = useSendFriendRequestMutation();

  const [
    friendRequestRespond,
    {
      isLoading: isLoadingFriendRequestRespond,
      isSuccess: isSuccessFriendRequestRespond,
    },
  ] = useFriendRequestRespondMutation();

  const {
    data: pendingFriendRequests,
    isLoading: isLoadingGetPendingFriendRequests,
    isError: isErrorGetPendingFriendRequests,
    refetch: refetchGetPendingFriendRequests,
  } = useGetPendingFriendRequestsQuery();

  const [
    unfriend,
    { isLoading: isLoadingUnfriend, isSuccess: isSuccessUnfriend },
  ] = useUnfriendMutation();

  const { createChallenge } = useMatchmaking();

  useEffect(() => {
    if (isSuccessSendFriendRequest) {
      refetchGetPendingFriendRequests();
    }
  }, [isSuccessSendFriendRequest, refetchGetPendingFriendRequests]);

  useEffect(() => {
    if (isSuccessFriendRequestRespond) {
      refetchGetPendingFriendRequests();
    }
  }, [isSuccessFriendRequestRespond, refetchGetPendingFriendRequests]);

  useEffect(() => {
    if (isSuccessFriendRequestRespond) {
      refetchGetFriends();
    }
  }, [isSuccessFriendRequestRespond, refetchGetFriends]);

  useEffect(() => {
    if (isSuccessUnfriend) {
      refetchGetFriends();
    }
  }, [isSuccessUnfriend, refetchGetFriends]);

  if (!pendingFriendRequests || isErrorGetPendingFriendRequests) return null;
  if (!friends || isErrorFriends) return null;

  if (friends.friends.some((f) => f.name === profileOwner)) {
    return (
      <Box
        className={classNames(className, styles['profile-actions'])}
        {...rest}
      >
        <Button
          className={styles['profile-action-button']}
          disabled={
            isLoadingFriendRequestRespond ||
            isLoadingFriends ||
            isLoadingGetPendingFriendRequests ||
            isLoadingSendFriendRequest ||
            isLoadingUnfriend
          }
          onClick={() => createChallenge(profileOwner)}
          color='primary'
        >
          <SvgIcon>
            <assets.icons.groups_filled />
          </SvgIcon>
          {t('actions.challenge')}
        </Button>
        <Button
          className={styles['profile-action-button']}
          disabled={
            isLoadingFriendRequestRespond ||
            isLoadingFriends ||
            isLoadingGetPendingFriendRequests ||
            isLoadingSendFriendRequest ||
            isLoadingUnfriend
          }
          onClick={() =>
            unfriend({
              username: profileOwner,
            })
          }
          variant='outlined'
          color='primary'
        >
          <SvgIcon>
            <assets.icons.person_remove_filled />
          </SvgIcon>
          {t('actions.unfriend')}
        </Button>
      </Box>
    );
  }

  if (pendingFriendRequests.requests.some((r) => r.receiver === profileOwner)) {
    return (
      <Box
        className={classNames(className, styles['profile-actions'])}
        {...rest}
      >
        <Button
          className={styles['profile-action-button']}
          disabled={
            isLoadingFriendRequestRespond ||
            isLoadingFriends ||
            isLoadingGetPendingFriendRequests ||
            isLoadingSendFriendRequest ||
            isLoadingUnfriend
          }
          onClick={() =>
            friendRequestRespond({
              requestId: pendingFriendRequests.requests.find(
                (r) => r.receiver === profileOwner
              )?.id!,
              action: 'CANCEL',
            })
          }
          color='primary'
        >
          <SvgIcon>
            <assets.icons.person_cancel_filled />
          </SvgIcon>
          {t('actions.cancelRequest')}
        </Button>
      </Box>
    );
  }

  if (pendingFriendRequests.requests.some((r) => r.sender === profileOwner)) {
    return (
      <Box
        className={classNames(className, styles['profile-actions'])}
        {...rest}
      >
        <Button
          className={styles['profile-action-button']}
          disabled={
            isLoadingFriendRequestRespond ||
            isLoadingFriends ||
            isLoadingGetPendingFriendRequests ||
            isLoadingSendFriendRequest ||
            isLoadingUnfriend
          }
          onClick={() =>
            friendRequestRespond({
              requestId: pendingFriendRequests.requests.find(
                (r) => r.sender === profileOwner
              )?.id!,
              action: 'ACCEPT',
            })
          }
          color='primary'
        >
          {t('actions.acceptFriendRequest')}
        </Button>
        <Button
          className={styles['profile-action-button']}
          disabled={
            isLoadingFriendRequestRespond ||
            isLoadingFriends ||
            isLoadingGetPendingFriendRequests ||
            isLoadingSendFriendRequest ||
            isLoadingUnfriend
          }
          onClick={() =>
            friendRequestRespond({
              requestId: pendingFriendRequests.requests.find(
                (r) => r.sender === profileOwner
              )?.id!,
              action: 'DECLINE',
            })
          }
          color='primary'
          variant='outlined'
        >
          {t('actions.declineFriendRequest')}
        </Button>
      </Box>
    );
  }

  return (
    <Box className={classNames(className, styles['profile-actions'])} {...rest}>
      <Button
        className={styles['profile-action-button']}
        disabled={
          isLoadingFriendRequestRespond ||
          isLoadingFriends ||
          isLoadingGetPendingFriendRequests ||
          isLoadingSendFriendRequest ||
          isLoadingUnfriend
        }
        onClick={() =>
          sendFriendRequest({
            receiver: profileOwner,
          })
        }
        color='primary'
      >
        <SvgIcon>
          <assets.icons.person_add_filled />
        </SvgIcon>
        {t('actions.addFriend')}
      </Button>
    </Box>
  );
};

export default ProfileActions;
