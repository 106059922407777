import { useState, useEffect } from 'react';
import { UseAudioOptions } from './types/UseAudioOptions';

const defaultOptions: UseAudioOptions = {
  soundEnabled: true,
};

const useAudio = (url: string, options: UseAudioOptions = defaultOptions) => {
  const [audio] = useState(new Audio(url));

  const play = () => {
    if (options.soundEnabled) {
      if (!audio.paused) {
        audio.currentTime = 0;
      }
      audio.play();
    }
  };

  useEffect(() => {
    audio.addEventListener('ended', () => audio.pause());
    return () => {
      audio.removeEventListener('ended', () => audio.pause());
    };
  }, [audio]);

  return play;
};

export default useAudio;
