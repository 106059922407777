import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchmakingGameMode } from '../../../types/Gamemode';
import { MatchmakingState } from './types/MatchmakingState';
import { MatchStartData } from '../../../types/match/startData/MatchStartData';
import { Match } from '../../../types/match/Match';
import { MatchmakingStatus } from './types/MatchmakingStatus';
import { ChallengeStatusUpdateMessageData } from '../../../hooks/useMatchmaking/types/messages/server/ChallengeStatusUpdateMessage';

const initialState: MatchmakingState = {
  selectedMatchmakingGameMode: null,
  enqueuedGameMode: null,
  matchStartData: null,
  matchSummaryData: null,
  status: 'idle',
  challengeStatus: null,
};

export const matchmakingSlice = createSlice({
  name: 'matchmaking',
  initialState,
  reducers: {
    resetState: () => initialState,
    setEnqueuedState: (state, action: PayloadAction<MatchmakingGameMode>) => {
      state.enqueuedGameMode = action.payload;
      state.status = 'enqueued';
    },
    setPlayAgainState: (state) => ({
      ...initialState,
      selectedMatchmakingGameMode: state.selectedMatchmakingGameMode,
      status: 'gameModeSelected',
    }),
    setSelectedMatchmakingGameMode: (
      state,
      action: PayloadAction<MatchmakingGameMode>
    ) => {
      state.selectedMatchmakingGameMode = action.payload;
      state.status = 'gameModeSelected';
    },
    setMatchStartData: (state, action: PayloadAction<MatchStartData>) => {
      state.matchStartData = action.payload;
    },
    setMatchStartState: (state, action: PayloadAction<MatchStartData>) => {
      state.matchStartData = action.payload;
      state.status = 'matchStarted';
    },
    setMatchSummaryState: (state, action: PayloadAction<Match>) => {
      state.matchSummaryData = action.payload;
      state.status = 'matchSummary';
    },
    setMatchmakingStatus: (state, action: PayloadAction<MatchmakingStatus>) => {
      state.status = action.payload;
    },
    setForfeitMatchStatus: (state) => {
      state.status = 'forfeitedMatch';
    },
    setChallengeStatus: (
      state,
      action: PayloadAction<ChallengeStatusUpdateMessageData>
    ) => {
      state.challengeStatus = action.payload;
    },
    resetChallengeStatus: (state) => {
      state.challengeStatus = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetState,
  setEnqueuedState,
  setSelectedMatchmakingGameMode,
  setMatchStartState,
  setMatchSummaryState,
  setMatchmakingStatus,
  setPlayAgainState,
  setForfeitMatchStatus,
  setChallengeStatus,
  resetChallengeStatus,
  setMatchStartData,
} = matchmakingSlice.actions;

export default matchmakingSlice.reducer;
