import { addSeconds } from 'date-fns';
import config from '../../config/config';
import { GameMode, MatchmakingGameMode } from '../../types/Gamemode';

/**
 * Calculates expected match end date.
 *
 * @param startDate - Match start date.
 * @param startDate - Match game mode.
 * @returns Expected Match end date.
 *
 */
const getExpectedMatchEndDate = (startDate: Date, gameMode: GameMode) => {
  if (gameMode === 'practice') {
    return new Date(startDate);
  }

  return addSeconds(
    new Date(startDate),
    config.game[gameMode as MatchmakingGameMode].duration
  );
};

export default getExpectedMatchEndDate;
