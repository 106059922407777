import { Box, BoxProps, Typography } from '@mui/material';
import { Player } from './types/Player';
import styles from './Playerboard.module.scss';
import classNames from 'classnames';

export interface PlayerboardProps extends BoxProps {
  players: Player[];
}

const Playerboard = ({ className, players, ...rest }: PlayerboardProps) => {
  return (
    <Box className={classNames(styles['playerboard'], className)} {...rest}>
      <Box className={styles['player-1']}>
        {players[0].username ? (
          <Typography className={styles['username']}>
            {players[0].username}
          </Typography>
        ) : undefined}

        {players[0].rating ? (
          <Typography>{players[0].rating}</Typography>
        ) : undefined}
      </Box>

      <Box className={styles['divider']} />

      <Box className={styles['player-2']}>
        {players[1].username ? (
          <Typography className={styles['username']}>
            {players[1].username}
          </Typography>
        ) : undefined}
        {players[1].rating ? (
          <Typography>{players[1].rating}</Typography>
        ) : undefined}
      </Box>
    </Box>
  );
};

export default Playerboard;
