import { MatchOutcomeChipProps } from './MatchOutcomeChip';

export interface MatchOutcomeTextSkeletonProps
  extends Partial<MatchOutcomeChipProps> {}

const MatchOutcomeTextSkeleton = ({
  style,
  ...rest
}: MatchOutcomeTextSkeletonProps) => {
  return null;
  // return (
  //   <TextSkeleton style={[styles['match-outcome-text'], style]} {...rest} />
  // );
};

export default MatchOutcomeTextSkeleton;
