import { Box } from '@mui/material';
import styles from './PuzzleSolutions.module.scss';
import classNames from 'classnames';
import { PuzzleSolutionsProps } from './PuzzleSolutions';
import PuzzleSolutionCardSkeleton from '../../../Gameboards/components/MatchSummary/PuzzleSolutionCard/PuzzleSolutionCardSkeleton';

export interface PuzzleSolutionsSkeletonProps
  extends Partial<PuzzleSolutionsProps> {}

const PuzzleSolutionsSkeleton = ({
  className,
  ...rest
}: PuzzleSolutionsSkeletonProps) => {
  return (
    <Box
      className={classNames(styles['puzzle-solutions'], className)}
      {...rest}
    >
      <Box className={classNames(styles['player-solutions'], className)}>
        <PuzzleSolutionCardSkeleton />
        <PuzzleSolutionCardSkeleton />
        <PuzzleSolutionCardSkeleton />
      </Box>
    </Box>
  );
};

export default PuzzleSolutionsSkeleton;
