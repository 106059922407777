import { Box, BoxProps } from '@mui/material';
import styles from './Keyboard.module.scss';
import Tokens from './components/Tokens/Tokens';
import { useEffect, useState } from 'react';
import { isBackspace, useExpression } from './util/useExpression';
import Operators from './components/Operators/Operators';
import classNames from 'classnames';
import SubmitButton from './components/SubmitButton/SubmitButton';
import util from '../../../../../util/util';
import Expressionboard from './components/Expressionboard/Expressionboard';
import useSettings from '../../../../../hooks/useSettings/useSettings';
import useAudio from '../../../../../hooks/useAudio/useAudio';
import assets from '../../../../../assets/assets';

export interface KeyboardProps extends BoxProps {
  disabled?: boolean;
  onExpressionChange?: (expression: string) => void;
  onSubmit?: () => void;
  tokens: number[];
}

const Keyboard = ({
  className,
  disabled = false,
  style,
  onExpressionChange,
  onSubmit,
  tokens: tokensProp,
  ...rest
}: KeyboardProps) => {
  const [tokens, setTokens] = useState<number[]>(
    tokensProp.slice().sort((a, b) => a - b)
  );
  const [disabledTokenIndexes, setDisabledTokenIndexes] = useState<number[]>(
    []
  );

  const {
    expression,
    expressionAppendix,
    pushToken,
    popToken,
    clearExpression,
  } = useExpression();

  const { enableGameBoardSounds } = useSettings();

  const playKeyPressPositive = useAudio(assets.audio.finger_tap, {
    soundEnabled: enableGameBoardSounds,
  });

  const playKeyPressNegative = useAudio(assets.audio.menu_click_silent, {
    soundEnabled: enableGameBoardSounds,
  });

  useEffect(() => {
    setTokens(tokensProp.slice().sort((a, b) => a - b));
  }, [tokensProp]);

  useEffect(() => {
    clearExpression();
    setDisabledTokenIndexes([]);
    // eslint-disable-next-line
  }, [tokens]);

  const handleClearExpression = () => {
    const tokenCleared = clearExpression();

    if (tokenCleared) {
      playKeyPressPositive();
    } else {
      playKeyPressNegative();
    }
    setTokens(tokensProp.slice().sort((a, b) => a - b));
    setDisabledTokenIndexes([]);
  };

  useEffect(() => {
    onExpressionChange?.(expression);
    // eslint-disable-next-line
  }, [expression]);

  return (
    <Box className={classNames(styles['keyboard'], className)}>
      <Expressionboard
        // disabled={disabled}
        expression={expression}
        expressionAppendix={expressionAppendix}
        onClearExpression={handleClearExpression}
      />

      <Box className={styles['keys-container']}>
        <Tokens
          tokens={tokens.map((token, i) => ({
            value: token,
            disabled: disabledTokenIndexes.includes(i) || disabled,
          }))}
          onTokenClick={(tokenIndex) => {
            const tokenPushed = pushToken(String(tokens[tokenIndex]));

            if (tokenPushed) {
              setDisabledTokenIndexes([...disabledTokenIndexes, tokenIndex]);

              playKeyPressPositive();
            } else {
              playKeyPressNegative();
            }
          }}
        />

        <Operators
          // disabled={disabled}
          onOperatorClick={(operator) => {
            if (isBackspace(operator)) {
              const poppedToken = popToken();

              if (poppedToken) {
                if (util.strings.isNum(poppedToken)) {
                  setDisabledTokenIndexes(disabledTokenIndexes.slice(0, -1));
                }
                playKeyPressPositive();
              } else {
                playKeyPressNegative();
              }
            } else {
              const tokenPushed = pushToken(operator);

              if (tokenPushed) {
                playKeyPressPositive();
              } else {
                playKeyPressNegative();
              }
            }
          }}
        />
      </Box>

      <SubmitButton disabled={disabled} onClick={onSubmit} />
    </Box>
  );
};

export default Keyboard;
