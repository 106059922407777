import { ErrorResponse } from '../../redux/slices/api/types/ErrorResponse';
import { ResponseErrorType } from '../../redux/slices/api/types/error/ResponseErrorType';
import getTranslationFileErrorKey from './getTranslationFileErrorKey';

const getTranslationFileKeyFromErrorResponse = (response: ErrorResponse) => {
  const { errors } = response;

  if (errors && errors.length > 0) {
    const error: ResponseErrorType = errors[0].type as ResponseErrorType;

    return getTranslationFileErrorKey(error);
  }
};

export default getTranslationFileKeyFromErrorResponse;
