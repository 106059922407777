import { Box } from '@mui/system';
import classNames from 'classnames';
import TokenSkeleton from './Token/TokenSkeleton';
import { TokensProps } from './Tokens';
import styles from './Tokens.module.scss';

export interface TokensSkeletonProps extends Partial<TokensProps> {}

const TokensSkeleton = ({ className, ...rest }: TokensSkeletonProps) => {
  return (
    <Box className={classNames(styles['tokens'], className)} {...rest}>
      {Array.from(Array(6)).map((i) => (
        <TokenSkeleton key={i} />
      ))}
    </Box>
  );
};

export default TokensSkeleton;
