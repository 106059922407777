import styles from './MatchHeading.module.scss';
import { Box, Skeleton, Typography } from '@mui/material';
import { MatchHeadingProps } from './MatchHeading';
import MatchOutcomeTextSkeleton from '../MatchOutcomeText/MatchOutcomeChipSkeleton';

export interface MatchHeadingSkeletonProps extends Partial<MatchHeadingProps> {}

const MatchHeadingSkeleton = () => {
  return (
    <Box className={styles['match-heading']}>
      <Typography variant='h2' width={200}>
        <Skeleton />
      </Typography>

      <MatchOutcomeTextSkeleton />

      <Typography width={100}>
        <Skeleton />
      </Typography>
    </Box>
  );
};

export default MatchHeadingSkeleton;
