import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiInputAdornment: {
  defaultProps?: ComponentsProps['MuiInputAdornment'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputAdornment'];
  variants?: ComponentsVariants<Theme>['MuiInputAdornment'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& > .MuiIconButton-root svg': {
        fill: theme.palette.text.secondary,
      },
    }),
  },
};

export default MuiInputAdornment;
