import { Card, CardProps, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './ProfileSummary.module.scss';
import ProfileStats from '../ProfileStats/ProfileStats';

export interface ProfileSummaryProps extends CardProps {
  matches: number;
  rank: number;
  rating: number;
  username: string;
  winRate: number;
}

const ProfileSummary = ({
  className,
  matches,
  rank,
  rating,
  username,
  winRate,
  ...rest
}: ProfileSummaryProps) => {
  return (
    <Card
      className={classNames(styles['profile-summary'], className)}
      {...rest}
    >
      <Typography className={styles['username']}>{username}</Typography>

      {!!rank && (
        <ProfileStats
          matches={matches}
          rank={rank}
          rating={rating}
          winRate={winRate}
        />
      )}
    </Card>
  );
};

export default ProfileSummary;
