import { useEffect, useState } from 'react';
import { useUsernameAvailabilityQuery } from '../../../redux/slices/api/api';
import { UsernameValidationErrorType } from '../../../redux/slices/api/types/error/UsernameValidationErrorType';
import util from '../../../util/util';

const useUsernameValidation = (username: string) => {
  const isValid = util.validation.isValidUsername(username);
  const [error, setError] = useState<UsernameValidationErrorType | null>(null);

  const { data: availabilityData, isFetching } = useUsernameAvailabilityQuery(
    { username: username },
    { skip: !isValid }
  );

  useEffect(() => {
    if ([null, undefined, ''].includes(username)) {
      setError('Empty Username');
    } else {
      setError(util.validation.validateUsername(username) || null);
    }
  }, [username]);

  useEffect(() => {
    if (availabilityData !== undefined && !isFetching) {
      const available = availabilityData;

      if (!available) {
        setError('Username Unavailable');
      }
    }
  }, [availabilityData, isFetching]);

  return {
    isValidating: isFetching,
    error,
  };
};

export default useUsernameValidation;
