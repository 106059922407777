import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import assets from '../../assets/assets';
import styles from './SearchField.module.scss';
import classNames from 'classnames';

export type SearchFieldProps = {} & TextFieldProps;

const SearchField = ({
  className,
  value,
  onChange,
  ...rest
}: SearchFieldProps) => {
  const { t } = useTranslation();

  const handleClearClick = () => {
    const event = {
      target: {
        value: '',
      },
    } as ChangeEvent<HTMLInputElement>;
    onChange && onChange(event);
  };

  return (
    <TextField
      className={classNames(
        value ? styles['search-field'] : undefined,
        className
      )}
      placeholder={t('actions.search')}
      InputProps={{
        endAdornment: !value ? (
          <InputAdornment position='end'>
            <SvgIcon>
              <assets.icons.search />
            </SvgIcon>
          </InputAdornment>
        ) : (
          <InputAdornment position='end'>
            <IconButton
              onClick={handleClearClick}
              className={styles['search-field-icon-button']}
            >
              <SvgIcon>
                <assets.icons.close />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SearchField;
