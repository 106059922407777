import classNames from 'classnames';
import styles from './PuzzleSolutionCard.module.scss';
import { Skeleton } from '@mui/material';
import { PuzzleSolutionCardProps } from './PuzzleSolutionCard';

export interface PuzzleSolutionCardSkeletonProps
  extends Partial<PuzzleSolutionCardProps> {}

const PuzzleSolutionCardSkeleton = ({
  className,
}: PuzzleSolutionCardSkeletonProps) => {
  return (
    <Skeleton
      className={classNames(styles['solution-card'], className)}
      variant='rounded'
      width={'100%'}
      height={'117rem'}
    />
  );
};

export default PuzzleSolutionCardSkeleton;
