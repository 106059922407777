import { IconButton, IconButtonProps, SvgIcon } from '@mui/material';
import assets from '../../../../../assets/assets';
import classNames from 'classnames';
import styles from './CloseMatchSummaryButton.module.scss';

export interface CloseMatchSummaryButtonProps extends IconButtonProps {}

const CloseMatchSummaryButton = ({
  className,
  ...rest
}: CloseMatchSummaryButtonProps) => {
  return (
    <IconButton
      aria-label='close'
      className={classNames(styles['close-match-summary-button'], className)}
      {...rest}
    >
      <SvgIcon>
        <assets.icons.close />
      </SvgIcon>
    </IconButton>
  );
};

export default CloseMatchSummaryButton;
