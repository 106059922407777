import { Box } from '@mui/material';
import styles from './MatchSummary.module.scss';
import classNames from 'classnames';
import { MatchSummaryProps } from './MatchSummary';
import MatchSkeleton from '../../../Match/components/Match/MatchSkeleton';
import SubmitButtonSkeleton from '../Puzzleboard/Keyboard/components/SubmitButton/SubmitButtonSkeleton';
import CloseMatchSummaryButtonSkeleton from './CloseMatchSummaryButton/CloseMatchSummaryButtonSkeleton';

export interface MatchSummarySkeletonProps extends Partial<MatchSummaryProps> {}

const MatchSummarySkeleton = ({
  className,
  submitButtonProps,
  ...rest
}: MatchSummarySkeletonProps) => {
  return (
    <Box className={classNames(styles['match-summary'], className)} {...rest}>
      <MatchSkeleton />

      <Box className={styles['match-summary-actions']}>
        <CloseMatchSummaryButtonSkeleton />

        <SubmitButtonSkeleton
          className={classNames(
            styles['submit-button'],
            submitButtonProps?.className
          )}
        />
      </Box>
    </Box>
  );
};

export default MatchSummarySkeleton;
