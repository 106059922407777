import styles from './HeatMap.module.scss';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HeatMapProps } from './HeatMap';

export interface HeatMapSkeletonProps extends Partial<HeatMapProps> {}

const HeatMapSkeleton = ({ ...rest }: HeatMapSkeletonProps) => {
  const { t } = useTranslation();

  return (
    <Box className={styles['heat-map-container']} {...rest}>
      <Skeleton variant='rounded' sx={{ height: '180rem' }} />

      <Skeleton className={styles['average-distance']}>
        <Typography className={styles['average-distance']}>
          {t('navigation.profile.averageDistance')}
        </Typography>
      </Skeleton>
    </Box>
  );
};

export default HeatMapSkeleton;
