import { GoalDistanceColorThemeKey } from './types/GoalDistanceColorThemeKey';

/**
 * Calculates GoalDistanceColorThemeKey representing quality of a puzzle solution.
 * Returned value can be used to access theme object in order to get appropriate
 * goal distance color.
 *
 * @param distance - Solution's goal distance.
 * @returns GoalDistanceColorThemeKey representing quality of a puzzle solution.
 *
 */
export const getGoalDistanceColorThemeKey = (
  distance: number
): GoalDistanceColorThemeKey => {
  const absDistance = Math.abs(distance);

  if (distance === 0) {
    return '0';
  } else if (absDistance <= 5) {
    return '1';
  }

  return '2';
};
