import { Box, BoxProps } from '@mui/material';
import styles from './PuzzleboardHeader.module.scss';
import classNames from 'classnames';

export interface PuzzleboardHeaderProps extends BoxProps {}

const PuzzleboardHeader = ({ className, ...rest }: PuzzleboardHeaderProps) => {
  return <Box className={classNames(styles['header'], className)} {...rest} />;
};

export default PuzzleboardHeader;
