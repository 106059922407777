import styles from './AppHeaderFooter.module.scss';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import useUser from '../../hooks/useUser/useUser';
import AppFooter from './components/AppFooter/AppFooter';
import AppHeader from './components/AppHeader/AppHeader';
import ChallengeListener from '../Challenge/components/ChallengeListener/ChallengeListener';
import ChallengeBlocker from '../Challenge/components/ChallengeBlocker/ChallengeBlocker';

const AppHeaderFooter = () => {
  const { user } = useUser();
  const { isSignedIn } = user;

  if (!isSignedIn) {
    return <Outlet />;
  }

  return (
    <>
      <ChallengeBlocker />
      <ChallengeListener />

      <AppHeader isSignedIn={isSignedIn} />

      <Outlet />

      <AppFooter
        className={classNames(styles['footer'], styles['padding-top'])}
      />
    </>
  );
};

export default AppHeaderFooter;
