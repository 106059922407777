import { Box, BoxProps, SvgIcon, Typography } from '@mui/material';
import styles from './MatchForfeitLabel.module.scss';
import { useTranslation } from 'react-i18next';
import assets from '../../../../../../../assets/assets';
import classNames from 'classnames';

export interface MatchForfeitLabelProps extends BoxProps {}

const MatchForfeitLabel = ({ className, ...rest }: MatchForfeitLabelProps) => {
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(styles['match-forfeit-label'], className)}
      {...rest}
    >
      <SvgIcon className={styles['flag-icon']}>
        <assets.icons.flag />
      </SvgIcon>
      <Typography className={styles['forfeit-text']}>
        {t('game.matchOutcomes.forfeit')}
      </Typography>
    </Box>
  );
};

export default MatchForfeitLabel;
