import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import styles from './Tile.module.scss';
import { Link, LinkProps, Typography } from '@mui/material';
import util from '../../../../../../util/util';

export interface TileProps extends BoxProps {
  linkProps?: LinkProps;
  value?: number | null;
}

const Tile = ({ className, linkProps, value, ...rest }: TileProps) => {
  if (linkProps) {
    return (
      <Link
        {...linkProps}
        className={classNames(styles['tile-link'], linkProps?.className)}
      >
        <Box
          className={classNames(
            styles['tile'],
            value !== undefined
              ? `background-color-goal-distance-${
                  value === null
                    ? null
                    : util.theme.getGoalDistanceColorThemeKey(value)
                }`
              : styles['tile-no-value'],
            className
          )}
          {...rest}
        >
          {value !== undefined ? (
            <Typography className={styles['tile-text']}>
              {value === null ? '--' : util.strings.formatGoalDistance(value)}
            </Typography>
          ) : null}
        </Box>
      </Link>
    );
  }

  return (
    <Box
      className={classNames(
        styles['tile'],
        value !== undefined
          ? `background-color-goal-distance-${
              value === null
                ? null
                : util.theme.getGoalDistanceColorThemeKey(value)
            }`
          : styles['tile-no-value'],
        className
      )}
      {...rest}
    >
      {value !== undefined ? (
        <Typography className={styles['tile-text']}>
          {value === null ? '--' : util.strings.formatGoalDistance(value)}
        </Typography>
      ) : null}
    </Box>
  );
};

export default Tile;
