import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SwitchScreen from '../../../components/SwitchScreen/SwitchScreen';
import {
  useGetPracticeMatchQuery,
  useSubmitPracticeMatchSolutionMutation,
} from '../../../redux/slices/api/api';
import Page from '../../../components/Page/Page';
import TimeoutRender from '../../../components/TimeoutRender/TimeoutRender';
import PracticePuzzleboard from './PracticePuzzleboard/PracticePuzzleboard';
import { useNavigate } from 'react-router-dom';
import MatchSummary from '../components/MatchSummary/MatchSummary';
import { Screen } from '../../../components/SwitchScreen/types/Screen';
import styles from './PracticeGameboard.module.scss';
import PracticePuzzleboardSkeleton from './PracticePuzzleboard/PracticePuzzleboardSkeleton';
import MatchSummarySkeleton from '../components/MatchSummary/MatchSummarySkeleton';
import useDocumentTitle from '../../../hooks/useDocumentTitle/useDocumentTitle';

const PracticeGameboard = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.navigation.practiceMatch'));

  const [screen, setScreen] = useState<Screen>('primary');

  const { data: practiceMatchStartData, refetch: refetchPracticeMatch } =
    useGetPracticeMatchQuery();

  const [submitPracticeMatch, { data: match }] =
    useSubmitPracticeMatchSolutionMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (match) {
      setScreen('secondary');
    }
  }, [match]);

  useEffect(() => {
    if (screen === 'primary') {
      refetchPracticeMatch();
    }
  }, [screen, refetchPracticeMatch]);

  return (
    <Page variant='gameboard'>
      <SwitchScreen
        activeScreen={screen}
        primaryScreen={
          practiceMatchStartData &&
          practiceMatchStartData.matchId !== match?.id ? (
            <PracticePuzzleboard
              onClose={() => navigate(-1)}
              onSkip={refetchPracticeMatch}
              onSubmit={(expression) =>
                submitPracticeMatch({
                  matchId: practiceMatchStartData.matchId,
                  expression: expression || '0',
                })
              }
              puzzle={practiceMatchStartData.puzzle}
            />
          ) : (
            <TimeoutRender>
              <PracticePuzzleboardSkeleton />
            </TimeoutRender>
          )
        }
        secondaryScreen={
          match && practiceMatchStartData?.matchId === match.id ? (
            <MatchSummary
              className={styles['match-summary']}
              closeMatchSummaryButtonProps={{
                onClick: () => navigate('/'),
              }}
              animate={true}
              data={match}
              matchHeaderProps={{
                matchHeadingProps: {
                  className: styles['match-summary-match-heading'],
                },
                showMatchEndDateTimestamp: false,
              }}
              onClose={() => navigate('/')}
              submitButtonProps={{
                children: t('actions.playAgain'),
                onClick: () => setScreen('primary'),
              }}
            />
          ) : (
            <MatchSummarySkeleton />
          )
        }
      />
    </Page>
  );
};

export default PracticeGameboard;
