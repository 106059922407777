import isValidEmailAddress, {
  validateEmail,
} from './validation/isValidEmailAdress';
import {
  isValidPassword,
  isValidPasswordLength,
  validatePassword,
} from './validation/isValidPassword';
import {
  isValidUsername,
  validateUsername,
} from './validation/isValidUsername';

const validation = {
  isValidEmailAddress: isValidEmailAddress,
  isValidUsername: isValidUsername,
  isValidPassword: isValidPassword,
  isValidPasswordLength: isValidPasswordLength,
  validatePassword: validatePassword,
  validateUsername: validateUsername,
  validateEmail: validateEmail,
};

export default validation;
