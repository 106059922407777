function getMedalColor(rank: number) {
  if (rank === 1) {
    return 1;
  } else if (rank === 2) {
    return 2;
  } else if (rank < 4) {
    return 3;
  } else if (rank < 11) {
    return 4;
  }
}

export default getMedalColor;
