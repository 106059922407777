import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiButton: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants<Theme>['MuiButton'];
} = {
  defaultProps: {
    color: 'secondary',
    disableElevation: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      transitionDuration: '100ms',
      padding: '10rem 24rem',
      '&:focus-visible': {
        outline: `2rem solid ${theme.palette.primary.extraLight}`,
      },
    }),

    sizeSmall: {
      padding: '6rem 12rem',
    },
  },
};

export default MuiButton;
