import classNames from 'classnames';
import styles from './ProfileStats.module.scss';
import { Box } from '@mui/material';
import { StatsProps } from './ProfileStats';
import ProfileStatSkeleton from '../ProfileStat/ProfileStatSkeleton';

export interface ProfileStatsSkeletonProps extends Partial<StatsProps> {}

const ProfileStatsSkeleton = ({
  className,
  ...rest
}: ProfileStatsSkeletonProps) => {
  return (
    <Box className={classNames(styles['stats'], className)} {...rest}>
      <ProfileStatSkeleton />

      <ProfileStatSkeleton />

      <ProfileStatSkeleton />

      <ProfileStatSkeleton />
    </Box>
  );
};

export default ProfileStatsSkeleton;
