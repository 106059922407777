import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';
import signinStyles from '../Signin/Signin.module.scss';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import FormLayoutContainer from '../Signin/components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from '../Signin/components/FormLayoutFooter/FormLayoutFooter';

const ResetPassword = () => {
  const { t } = useTranslation();
  useDocumentTitle(`${t('actions.resetPassword')}`);

  return (
    <Page className={signinStyles['page']}>
      <FormLayoutContainer>
        <ResetPasswordForm />

        <FormLayoutFooter />
      </FormLayoutContainer>
    </Page>
  );
};

export default ResetPassword;
