import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import shadows from '@mui/material/styles/shadows';

const MuiPopover: {
  defaultProps?: ComponentsProps['MuiPopover'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiPopover'];
  variants?: ComponentsVariants<Theme>['MuiPopover'];
} = {
  styleOverrides: {
    paper: {
      boxShadow: shadows[3],
    },
  },
};

export default MuiPopover;
