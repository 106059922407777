import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import Match, { MatchProps } from '../../../Match/components/Match/Match';
import SubmitButton, {
  SubmitButtonProps,
} from '../Puzzleboard/Keyboard/components/SubmitButton/SubmitButton';
import styles from './MatchSummary.module.scss';
import classNames from 'classnames';
import { Animatable } from '../../../../types/Animatable';
import { MatchHeaderProps } from '../../../Match/components/MatchHeader/MatchHeader';
import CloseMatchSummaryButton, {
  CloseMatchSummaryButtonProps,
} from './CloseMatchSummaryButton/CloseMatchSummaryButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import assets from '../../../../assets/assets';
import GameSettingsDialog from '../Puzzleboard/GameSettingsDialog/GameSettingsDialog';
import useSettings from '../../../../hooks/useSettings/useSettings';
import useAudio from '../../../../hooks/useAudio/useAudio';
import util from '../../../../util/util';
import useUser from '../../../../hooks/useUser/useUser';

export interface MatchSummaryProps extends MatchProps, Animatable {
  closeMatchSummaryButtonProps?: Partial<CloseMatchSummaryButtonProps>;
  matchHeaderProps?: Partial<MatchHeaderProps>;
  onClose: VoidFunction;
  submitButtonProps: SubmitButtonProps;
}

const MatchSummary = ({
  animate,
  className,
  closeMatchSummaryButtonProps,
  data,
  onClose,
  matchHeaderProps,
  submitButtonProps,
  ...rest
}: MatchSummaryProps) => {
  const { t } = useTranslation();
  const [isOpenGameSettingsDialog, setIsOpenGameSettingsDialog] =
    useState(false);

  const { enableGameBoardSounds } = useSettings();

  const playAudioMatchVictory = useAudio(assets.audio.score_victory, {
    soundEnabled: enableGameBoardSounds,
  });

  const playAudioMatchDefeat = useAudio(assets.audio.score_defeat, {
    soundEnabled: enableGameBoardSounds,
  });

  const { user } = useUser();

  useEffect(() => {
    if (data) {
      switch (data.gameMode) {
        case 'practice':
          const participant = data.participants.find(
            (p) => p.username === user.username
          );
          const { expressionValue } = participant?.puzzleSolution || {};
          const distance =
            expressionValue !== undefined
              ? Math.abs(expressionValue - data.puzzle.goal)
              : undefined;
          if (distance === 0) {
            playAudioMatchVictory();
          } else {
            playAudioMatchDefeat();
          }
          break;
        case 'normal':
        case 'rated':
          const matchOutcome = util.matches.getMatchOutcome(
            user.username,
            data.participants
          );
          if (matchOutcome !== undefined) {
            if (matchOutcome === 'victory') {
              playAudioMatchVictory();
            } else {
              playAudioMatchDefeat();
            }
          }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className={classNames(styles['match-summary'], className)} {...rest}>
      <Match
        animate={animate}
        data={data}
        matchHeaderProps={{
          endNode: (
            <>
              <Tooltip title={t('navigation.more.sections.settings')}>
                <IconButton
                  aria-label='settings'
                  onClick={() => setIsOpenGameSettingsDialog(true)}
                >
                  <SvgIcon>
                    <assets.icons.settings />
                  </SvgIcon>
                </IconButton>
              </Tooltip>

              <GameSettingsDialog
                open={isOpenGameSettingsDialog}
                onClose={() => setIsOpenGameSettingsDialog(false)}
              />
            </>
          ),
          ...matchHeaderProps,
        }}
      />

      <Box className={styles['match-summary-footer']}>
        <Box className={styles['actions']}>
          <CloseMatchSummaryButton {...closeMatchSummaryButtonProps} />

          <SubmitButton
            {...submitButtonProps}
            className={classNames(
              styles['submit-button'],
              submitButtonProps?.className
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MatchSummary;
