import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiInputLabel: {
  defaultProps?: ComponentsProps['MuiInputLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInputLabel'];
  variants?: ComponentsVariants<Theme>['MuiInputLabel'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '14rem',
      '&.Mui-focused:not(.Mui-error)': {
        color: theme.palette.text.secondary,
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(11rem, 7rem) scale(0.85)',
      },
    }),
  },
};

export default MuiInputLabel;
