import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SwitchScreen from '../../../components/SwitchScreen/SwitchScreen';
import useMatchmaking from '../../../hooks/useMatchmaking/useMatchmaking';
import { useGetMatchQuery } from '../../../redux/slices/api/api';
import { useNavigate } from 'react-router-dom';
import config from '../../../config/config';
import Page from '../../../components/Page/Page';
import LookingForOpponent from '../components/Puzzleboard/LookingForOpponent/LookingForOpponent';
import styles from './MatchmakingGameboard.module.scss';
import MatchmakingPuzzleboard from './MatchmakingPuzzleboard/MatchmakingPuzzleboard';
import TimeoutRender from '../../../components/TimeoutRender/TimeoutRender';
import MatchSummary from '../components/MatchSummary/MatchSummary';
import { Screen } from '../../../components/SwitchScreen/types/Screen';
import ForfeitMatchDialog from '../components/Puzzleboard/ForfeitMatchDialog/ForfeitMatchDialog';
import useDocumentTitle from '../../../hooks/useDocumentTitle/useDocumentTitle';
import MatchSummarySkeleton from '../components/MatchSummary/MatchSummarySkeleton';
import MatchmakingPuzzleboardSkeleton from './MatchmakingPuzzleboard/MatchmakingPuzzleboardSkeleton';

const MatchmakingGameboard = () => {
  const { t } = useTranslation();

  const [screen, setScreen] = useState<Screen>('primary');

  const {
    enqueue,
    dequeue,
    submitSolution,
    forfeitMatch,
    selectedMatchmakingGameMode,
    enqueuedGameMode,
    playAgain,
    matchStartData,
    matchSummaryData,
    status: matchmakingStatus,
    setStatus: setMatchmakingStatus,
  } = useMatchmaking();

  useDocumentTitle(
    t(`navigation.navigation.${selectedMatchmakingGameMode}Match`)
  );

  const { data: match, isFetching: isFetchingMatch } = useGetMatchQuery(
    { matchId: matchSummaryData?.id || '' },
    {
      skip: !(
        matchSummaryData?.id &&
        ['solutionSubmitted', 'matchSummary'].includes(matchmakingStatus)
      ),
    }
  );

  const [isOpenForfeitMatchDialog, setIsOpenForfeitMatchDialog] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (matchmakingStatus === 'idle') {
      navigate('/');
    }
  }, [navigate, matchmakingStatus]);

  useEffect(() => {
    if (matchmakingStatus === 'gameModeSelected') {
      setScreen('primary');
    }
  }, [matchmakingStatus]);

  useEffect(() => {
    if (
      matchmakingStatus === 'gameModeSelected' &&
      selectedMatchmakingGameMode
    ) {
      const timeout = setTimeout(
        () => enqueue(selectedMatchmakingGameMode),
        config.game.enqueueTimeout
      );

      return () => clearTimeout(timeout);
    }
  }, [selectedMatchmakingGameMode, matchmakingStatus, enqueue]);

  useEffect(() => {
    if (
      matchmakingStatus === 'enqueued' &&
      matchStartData &&
      matchStartData.startedAt
    ) {
      const delay = Math.max(
        0,
        new Date(matchStartData.startedAt).getTime() - Date.now()
      );

      setTimeout(() => {
        setMatchmakingStatus('matchStarted');
      }, delay);
    }
  }, [matchmakingStatus, matchStartData, setMatchmakingStatus]);

  useEffect(() => {
    if (
      ['solutionSubmitted', 'matchSummary'].includes(matchmakingStatus) &&
      match &&
      !isFetchingMatch
    ) {
      setScreen('secondary');
    }
  }, [matchmakingStatus, match, isFetchingMatch]);

  if (['gameModeSelected', 'enqueued'].includes(matchmakingStatus)) {
    return (
      <Page
        variant='gameboard'
        className={styles['looking-for-opponnent-page']}
      >
        <LookingForOpponent
          headline={
            matchStartData?.startedAt
              ? t('navigation.matchmaking.startingMatch')
              : t('navigation.matchmaking.lookingForOpponent')
          }
          cancelButtonProps={{
            disabled: !!matchStartData?.startedAt,
            onClick: () => dequeue(selectedMatchmakingGameMode!),
          }}
        />
      </Page>
    );
  }

  return (
    <Page variant='gameboard'>
      <ForfeitMatchDialog
        open={isOpenForfeitMatchDialog}
        onYes={() => {
          forfeitMatch(matchStartData!.matchId);
          setIsOpenForfeitMatchDialog(false);
        }}
        onNo={() => setIsOpenForfeitMatchDialog(false)}
      />

      <SwitchScreen
        activeScreen={screen}
        primaryScreen={
          matchmakingStatus === 'matchStarted' && matchStartData?.puzzle ? (
            <MatchmakingPuzzleboard
              gameMode={enqueuedGameMode!}
              onClose={() => setIsOpenForfeitMatchDialog(true)}
              onSubmit={(expression) =>
                submitSolution(expression || '0', matchStartData.matchId)
              }
              participants={
                'participants' in matchStartData
                  ? matchStartData.participants
                  : []
              }
              puzzle={matchStartData.puzzle}
            />
          ) : (
            <TimeoutRender>
              <MatchmakingPuzzleboardSkeleton />
            </TimeoutRender>
          )
        }
        secondaryScreen={
          match && match.id === matchSummaryData?.id ? (
            <MatchSummary
              animate={true}
              data={match}
              closeMatchSummaryButtonProps={{
                onClick: () => navigate('/'),
              }}
              onClose={() => navigate('/')}
              submitButtonProps={{
                children: t('actions.playAgain'),
                onClick: playAgain,
              }}
            />
          ) : (
            <MatchSummarySkeleton />
          )
        }
      />
    </Page>
  );
};

export default MatchmakingGameboard;
