import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiCard: {
  defaultProps?: ComponentsProps['MuiCard'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCard'];
  variants?: ComponentsVariants<Theme>['MuiCard'];
} = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      backgroundImage: 'none',
    },
  },
};

export default MuiCard;
