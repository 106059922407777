import PageLoadingError from '../ErrorPage/PageLoadingError/PageLoadingError';
import Page from '../../components/Page/Page';
import useUser from '../../hooks/useUser/useUser';
import DefaultProfile from './components/Profile/Profile';
import { useGetProfileQuery } from '../../redux/slices/api/api';
import ProfileSkeleton from './components/Profile/ProfileSkeleton';
import TimeoutRender from '../../components/TimeoutRender/TimeoutRender';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../../hooks/useDocumentTitle/useDocumentTitle';

const Profile = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('navigation.navigation.profile'));

  const { user } = useUser();
  const { username } = user;

  const { data: profile, isError } = useGetProfileQuery({
    username: username,
  });

  if (isError) {
    return <PageLoadingError />;
  }

  if (!profile) {
    return (
      <TimeoutRender>
        <Page>
          <ProfileSkeleton />
        </Page>
      </TimeoutRender>
    );
  }

  return (
    <Page>
      <DefaultProfile data={profile!} profileViewer={username} />
    </Page>
  );
};

export default Profile;
