import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useLocalStorage from '../useLocalStorage';
import { useAppDispatch } from '../../redux/hooks';
import { AppCache } from './types/AppCache';
import { Settings } from '../../redux/slices/settings/types/Settings';
import { setSettings } from '../../redux/slices/settings/settings';
import { User } from '../../redux/slices/user/types/User';
import {
  initialState as initialUserState,
  setUser,
} from '../../redux/slices/user/user';
import _ from 'lodash';
import { setAuthTokens } from '../../redux/slices/auth/auth';
import { SettingsCache } from './types/SettingsCache';
import { UserCache } from './types/UserCache';
import { AuthCache } from './types/AuthCache';
import { AuthTokens } from '../../redux/slices/auth/types/AuthTokens';
import { useNavigate } from 'react-router-dom';
import util from '../../util/util';

export default function useAppCache() {
  const settingsState = useSelector((state: RootState) => state.settings);
  const userState = useSelector((state: RootState) => state.user);
  const authState = useSelector((state: RootState) => state.auth);

  const [appCache, setAppCache] = useState<AppCache>();
  const [localStorageAppCache, setLocalStorageAppCache] = useLocalStorage(
    'app-cache',
    appCache
  );

  const dispatch = useAppDispatch();

  const [isInitComplete, setIsInitComplete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (settingsState) {
      const settingsCache: SettingsCache = settingsState;

      setAppCache((cache) =>
        cache
          ? { ...cache, settings: settingsCache }
          : { settings: settingsCache }
      );
    }
  }, [settingsState]);

  useEffect(() => {
    if (userState) {
      const userCache: UserCache = {
        isSignedIn: userState.isSignedIn,
        username: userState.username,
        type: userState.type,
      };

      setAppCache((cache) =>
        cache ? { ...cache, user: userCache } : { user: userCache }
      );
    }
  }, [userState]);

  useEffect(() => {
    if (authState) {
      const authCache: AuthCache = {
        accessToken: authState.accessToken,
        refreshToken: authState.refreshToken,
      };

      setAppCache((cache) =>
        cache ? { ...cache, auth: authCache } : { auth: authCache }
      );
    }
  }, [authState]);

  useEffect(() => {
    if (appCache && !_.isEqual(appCache, localStorageAppCache)) {
      setLocalStorageAppCache(appCache);
    }
  }, [appCache, localStorageAppCache, setLocalStorageAppCache]);

  useEffect(() => {
    if (localStorageAppCache) {
      const {
        auth: authTokensCache,
        settings: settingsCache,
        user: userCache,
      } = localStorageAppCache;

      if (authTokensCache) {
        const { accessToken, refreshToken } = authTokensCache;
        if (accessToken !== null && refreshToken !== null) {
          const authTokens: AuthTokens = {
            accessToken,
            refreshToken,
          };

          if (
            util.jwt.isJWTExpired(accessToken, 0) &&
            util.jwt.isJWTExpired(refreshToken, 0)
          ) {
            navigate('/signout');
          }

          dispatch(setAuthTokens(authTokens));
        }
      }

      if (settingsCache) {
        const settings: Settings = settingsCache;
        dispatch(setSettings(settings));
      }

      if (userCache) {
        const user: User = {
          ...initialUserState,
          isSignedIn: userCache.isSignedIn || initialUserState.isSignedIn,
          username: userCache.username || initialUserState.username,
          type: userCache.type || initialUserState.type,
        };
        dispatch(setUser(user));
      }

      setIsInitComplete(true);
    }
  }, [localStorageAppCache, dispatch, navigate]);

  return isInitComplete;
}
