export const UNICODE_CHARACTER_ENCODINGS = {
  BACKSPACE: '\u0008',
  'LEFT PARENTHESIS': '\u0028',
  'RIGHT PARENTHESIS': '\u0029',
  ASTERISK: '\u002A',
  'PLUS SIGN': '\u002B',
  'HYPHEN-MINUS': '\u002D',
  SOLIDUS: '\u002F',
  'MULTIPLICATION SIGN': '\u00D7',
  'DIVISION SIGN': '\u00F7',
  'MINUS SIGN': '\u2212',
};

const GAMEBOARD_OPERATORS = [
  {
    name: 'PLUS SIGN',
    value: UNICODE_CHARACTER_ENCODINGS['PLUS SIGN'],
    tooltip: 'Add',
  },
  {
    name: 'MINUS SIGN',
    value: UNICODE_CHARACTER_ENCODINGS['MINUS SIGN'],
    tooltip: 'Subtract',
  },
  {
    name: 'MULTIPLICATION SIGN',
    value: UNICODE_CHARACTER_ENCODINGS['MULTIPLICATION SIGN'],
    tooltip: 'Multiply',
  },
  {
    name: 'DIVISION SIGN',
    value: UNICODE_CHARACTER_ENCODINGS['DIVISION SIGN'],
    tooltip: 'Divide',
  },
  {
    name: 'LEFT PARENTHESIS',
    value: UNICODE_CHARACTER_ENCODINGS['LEFT PARENTHESIS'],
    tooltip: 'Open bracket',
  },
  {
    name: 'RIGHT PARENTHESIS',
    value: UNICODE_CHARACTER_ENCODINGS['RIGHT PARENTHESIS'],
    tooltip: 'Close bracket',
  },
  {
    name: 'BACKSPACE',
    value: UNICODE_CHARACTER_ENCODINGS.BACKSPACE,
    icon: 'backspace',
    tooltip: 'Backspace',
  },
];

export default GAMEBOARD_OPERATORS;
