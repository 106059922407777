import MuiAppBar from './components/MuiAppBar';
import MuiAvatar from './components/MuiAvatar';
import MuiButton from './components/MuiButton';
import MuiButtonBase from './components/MuiButtonBase';
import MuiCard from './components/MuiCard';
import MuiDialog from './components/MuiDialog';
import MuiDialogActions from './components/MuiDialogActions';
import MuiFormControlLabel from './components/MuiFormControlLabel';
import MuiFilledInput from './components/MuiFilledInput';
import MuiIcon from './components/MuiIcon';
import MuiIconButton from './components/MuiIconButton';
import MuiInputAdornment from './components/MuiInputAdornment';
import MuiInputBase from './components/MuiInputBase';
import MuiInputLabel from './components/MuiInputLabel';
import MuiLinearProgress from './components/MuiLinearProgress';
import MuiLink from './components/MuiLink';
import MuiListItemIcon from './components/MuiListItemIcon';
import MuiMenuItem from './components/MuiMenuItem';
import MuiModal from './components/MuiModal';
import MuiPopover from './components/MuiPopover';
import MuiSelect from './components/MuiSelect';
import MuiSwitch from './components/MuiSwitch';
import MuiTab from './components/MuiTab';
import MuiTextField from './components/MuiTextField';
import MuiToggleButton from './components/MuiToggleButton';
import MuiToolbar from './components/MuiToolbar';
import MuiTooltip from './components/MuiTooltip';
import MuiTypography from './components/MuiTypography';

const components = {
  MuiAppBar,
  MuiAvatar,
  MuiButton,
  MuiButtonBase,
  MuiCard,
  MuiDialog,
  MuiDialogActions,
  MuiFormControlLabel,
  MuiFilledInput,
  MuiIcon,
  MuiIconButton,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiLink,
  MuiListItemIcon,
  MuiMenuItem,
  MuiModal,
  MuiPopover,
  MuiSelect,
  MuiSwitch,
  MuiTab,
  MuiTextField,
  MuiToggleButton,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
};

export default components;
