import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';
import LinkBehavior from '../mui-link';

const MuiButtonBase: {
  defaultProps?: ComponentsProps['MuiButtonBase'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButtonBase'];
  variants?: ComponentsVariants<Theme>['MuiButtonBase'];
} = {
  defaultProps: {
    disableRipple: true,
    LinkComponent: LinkBehavior,
  },
};

export default MuiButtonBase;
