import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiTextField: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants<Theme>['MuiTextField'];
} = {
  defaultProps: {
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
    },
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      borderRadius: 8,
      '& .MuiFilledInput-root .MuiFilledInput-input': {
        padding: ownerState.label ? '25rem 12rem 6rem 12rem' : '12rem',
      },
    }),
  },
};

export default MuiTextField;
