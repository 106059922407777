import classNames from 'classnames';
import { Box, BoxProps, Link } from '@mui/material';
import styles from './FriendCard.module.scss';

export interface FriendCardProps extends BoxProps {
  name: string;
}

const FriendCard = ({
  className,
  children,
  name,
  ...rest
}: FriendCardProps) => {
  return (
    <Box className={classNames(styles['friend-card'], className)} {...rest}>
      <Link
        className={styles['name']}
        color='inherit'
        href={`/user/${name}`}
        underline='hover'
      >
        {name}
      </Link>
      <Box className={styles['card-actions']}>{children}</Box>
    </Box>
  );
};

export default FriendCard;
