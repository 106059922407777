import { Box, BoxProps, SvgIcon, Typography } from '@mui/material';
import styles from './GameModeIcon.module.scss';
import { GameMode } from '../../../../types/Gamemode';
import classNames from 'classnames';
import getGameModeIcon from './util/getGameModeIcon';
import { useTranslation } from 'react-i18next';

export interface GameModeIconProps extends BoxProps {
  gameMode: GameMode;
  showLabel?: boolean;
  label?: string;
}

const GameModeIcon = ({
  className,
  gameMode,
  showLabel = false,
  label,
  ...rest
}: GameModeIconProps) => {
  const { t } = useTranslation();

  return (
    <Box className={classNames(styles['game-mode-icon'], className)} {...rest}>
      <SvgIcon fontSize='small'>{getGameModeIcon(gameMode)}</SvgIcon>
      {showLabel && (
        <Typography className={styles['label']}>
          {label || t(`game.gameModes.${gameMode}.title`)}
        </Typography>
      )}
    </Box>
  );
};

export default GameModeIcon;
