import { PaletteMode, CommonColors, TypeAction } from '@mui/material';
import {
  experimental_extendTheme as extendTheme,
  PaletteColorOptions,
} from '@mui/material/styles';
import {
  ColorPartial,
  PaletteTonalOffset,
} from '@mui/material/styles/createPalette';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import components from './components';
import M3Theme from './M3Theme';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'page-h1': React.CSSProperties | TypographyStyleOptions;
    'page-h2': React.CSSProperties | TypographyStyleOptions;
  }

  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    extraLight?: string;
    extraDark?: string;
  }

  interface PaletteOptions {
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    success?: PaletteColorOptions;
    mode?: PaletteMode;
    tonalOffset?: PaletteTonalOffset;
    contrastThreshold?: number;
    common?: Partial<CommonColors>;
    grey?: ColorPartial;
    text?: Partial<TypeText>;
    divider?: string;
    action?: Partial<TypeAction>;
    background?: Partial<TypeBackground>;
    getContrastText?: (background: string) => string;
    goalDistance?: TypeGoalDistanceBackground;
    medal?: TypeMedalColor;
    matchOutcome?: TypeMatchOutcomeColor;
    primaryContainer?: string;
    secondaryContainer?: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    secondary?: string;
    tertiary?: string;
    quaternary?: string;
    defaultContrast?: string;
  }

  interface TypeText {
    primary: string;
    onPrimary: string;
    secondary: string;
    tertiary: string;
    disabled: string;
  }

  interface TypeGoalDistanceBackground {
    0: string;
    1: string;
    2: string;
  }

  interface TypeMedalColor {
    first: string;
    second: string;
    third: string;
    fourth: string;
  }

  interface TypeMatchOutcomeColor {
    victory: string;
    defeat: string;
    draw: string;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    extraLight?: string;
    extraDark?: string;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'page-h1'?: React.CSSProperties | TypographyStyleOptions;
    'page-h2'?: React.CSSProperties | TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'page-h1': true;
    'page-h2': true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPro {
    dashed: true;
  }
}

export const theme = extendTheme({
  cssVarPrefix: '',
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: M3Theme.schemes.light.background,
          paper: M3Theme.schemes.light.background,
          secondary: M3Theme.schemes.light.surfaceContainer,
          defaultContrast: M3Theme.schemes.light.inverseSurface,
        },
        error: {
          main: M3Theme.schemes.light.error,
        },
        goalDistance: {
          0: '#00897b',
          1: '#ef6c00',
          2: '#e53935',
        },
        matchOutcome: {
          defeat: '#e53935',
          draw: M3Theme.schemes.light.background,
          victory: '#3066be',
        },
        medal: {
          first: '#C9B037',
          second: '#B4B4B4',
          third: '#AD8A56',
          fourth: '#9bb9d4',
        },
        primary: {
          main: M3Theme.schemes.light.primary,
          extraLight: M3Theme.schemes.light.primary,
        },
        secondary: {
          main: M3Theme.schemes.light.surfaceContainer,
          light: M3Theme.schemes.light.surfaceContainerLow,
          extraLight: M3Theme.schemes.light.surfaceContainerLowest,
          dark: M3Theme.schemes.light.surfaceContainerHigh,
          extraDark: M3Theme.schemes.light.surfaceContainerHighest,
        },
        primaryContainer: M3Theme.schemes.light.primaryContainer,
        secondaryContainer: M3Theme.schemes.light.secondaryContainer,
        text: {
          onPrimary: M3Theme.schemes.light.onPrimary,
          tertiary: M3Theme.schemes.light.outlineVariant,
        },
        LinearProgress: {
          primaryBg: M3Theme.schemes.light.surfaceContainer,
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: M3Theme.schemes.dark.background,
          paper: M3Theme.schemes.dark.background,
          secondary: M3Theme.schemes.dark.surfaceContainer,
          defaultContrast: M3Theme.schemes.dark.inverseSurface,
        },
        error: {
          main: M3Theme.schemes.dark.error,
        },
        goalDistance: {
          0: '#00897b',
          1: '#ef6c00',
          2: '#e53935',
        },
        matchOutcome: {
          defeat: '#e53935',
          draw: M3Theme.schemes.dark.background,
          victory: '#3066be',
        },
        medal: {
          first: '#C9B037',
          second: '#B4B4B4',
          third: '#AD8A56',
          fourth: '#9bb9d4',
        },
        primary: {
          main: M3Theme.schemes.dark.primary,
          extraLight: M3Theme.schemes.dark.primary,
        },
        secondary: {
          main: M3Theme.schemes.dark.surfaceContainer,
          light: M3Theme.schemes.dark.surfaceContainerLow,
          extraLight: M3Theme.schemes.dark.surfaceContainerLowest,
          dark: M3Theme.schemes.dark.surfaceContainerHigh,
          extraDark: M3Theme.schemes.dark.surfaceContainerHighest,
        },
        primaryContainer: M3Theme.schemes.dark.primaryContainer,
        secondaryContainer: M3Theme.schemes.dark.secondaryContainer,
        text: {
          onPrimary: M3Theme.schemes.dark.onPrimary,
          tertiary: M3Theme.schemes.dark.outlineVariant,
        },
        LinearProgress: {
          primaryBg: M3Theme.schemes.dark.surfaceContainer,
        },
      },
    },
  },
  typography: {
    htmlFontSize: 1,
    fontSize: 14,
    h1: {},
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    'page-h1': {
      fontSize: '28rem',
      fontWeight: 'bold',
      margin: '8rem 0 8rem 0',
      '@media (max-width:900px)': {
        fontSize: '26rem',
      },
      '@media (max-width:600px)': {
        fontSize: '26rem',
      },
      '@media (max-width:500px)': {
        fontSize: '24rem',
      },
    },
    'page-h2': {
      fontSize: '24rem',
      fontWeight: 'bold',
      margin: '8rem 0 20rem 0',
      '@media (max-width:900px)': {
        fontSize: '22rem',
      },
      '@media (max-width:600px)': {
        fontSize: '22rem',
      },
      '@media (max-width:500px)': {
        fontSize: '20rem',
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    ...components,
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
  },
});

export default theme;
