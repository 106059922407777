import { Navigate } from 'react-router-dom';
import useUser from '../../hooks/useUser/useUser';

function PublicAndGuestRoute({ element }: { element: React.ReactNode }) {
  const { user } = useUser();
  const { isSignedIn, type } = user;

  return !isSignedIn || type === 'GUEST' ? <>{element}</> : <Navigate to='/' />;
}

export default PublicAndGuestRoute;
