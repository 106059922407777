import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  SvgIcon,
  Typography,
} from '@mui/material';
import assets from '../../../../assets/assets';
import styles from './ShortestSolutionAccordion.module.scss';
import SolutionExpressionDetails from '../../../Gameboards/components/MatchSummary/PuzzleSolutionCard/components/PuzzleSolutionDetails/PuzzleSolutionDetails';
import { useTranslation } from 'react-i18next';
import util from '../../../../util/util';
import classNames from 'classnames';
import { useState } from 'react';

export interface ShortestSolutionAccordionProps
  extends Partial<AccordionProps> {
  expression: string;
  expressionValue: number;
  goalDistance: number;
}

const ShortestSolutionAccordion = ({
  className,
  expanded: expandedProp,
  expression,
  expressionValue,
  goalDistance,
  ...rest
}: ShortestSolutionAccordionProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(expandedProp);

  return (
    <Accordion
      className={classNames(styles['shortest-solution-accordion'], className)}
      expanded={expanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      {...rest}
    >
      <AccordionSummary
        expandIcon={
          <SvgIcon fontSize='medium'>
            <assets.icons.expand_more />
          </SvgIcon>
        }
      >
        <Box className={styles['shortest-solution-title-container']}>
          <SvgIcon sx={{ fontSize: '22rem' }}>
            <assets.icons.boronu_icon_filled />
          </SvgIcon>

          <Typography className={styles['title']}>
            {t('game.puzzle.shortestSolution').toLocaleUpperCase()}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <SolutionExpressionDetails
          className={styles['expression-details']}
          distance={goalDistance}
          displayTime={false}
          expression={util.strings.getPrettyExpressionString(expression)}
          expressionValue={expressionValue}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ShortestSolutionAccordion;
