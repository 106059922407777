const M3Theme = {
  description:
    'TYPE: CUSTOM\nMaterial Theme Builder export 2023-12-23 10:33:03',
  seed: '#3066BE',
  coreColors: {
    primary: '#3066BE',
  },
  extendedColors: [],
  schemes: {
    light: {
      primary: '#3066BE',
      surfaceTint: '#3066BE',
      onPrimary: '#FFFFFF',
      primaryContainer: '#D8E2FF',
      onPrimaryContainer: '#001A41',
      secondary: '#565E71',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#DBE2F9',
      onSecondaryContainer: '#141B2C',
      tertiary: '#715573',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#FBD7FC',
      onTertiaryContainer: '#29132D',
      error: '#BA1A1A',
      onError: '#FFFFFF',
      errorContainer: '#FFDAD6',
      onErrorContainer: '#410002',
      background: '#F9F9FF',
      onBackground: '#1A1B20',
      surface: '#F9F9FF',
      onSurface: '#1A1B20',
      surfaceVariant: '#E1E2EC',
      onSurfaceVariant: '#44474F',
      outline: '#74777F',
      outlineVariant: '#C4C6D0',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#2F3036',
      inverseOnSurface: '#F0F0F7',
      inversePrimary: '#ADC6FF',
      primaryFixed: '#D8E2FF',
      onPrimaryFixed: '#001A41',
      primaryFixedDim: '#ADC6FF',
      onPrimaryFixedVariant: '#2B4678',
      secondaryFixed: '#DBE2F9',
      onSecondaryFixed: '#141B2C',
      secondaryFixedDim: '#BFC6DC',
      onSecondaryFixedVariant: '#3F4759',
      tertiaryFixed: '#FBD7FC',
      onTertiaryFixed: '#29132D',
      tertiaryFixedDim: '#DEBCDF',
      onTertiaryFixedVariant: '#583E5B',
      surfaceDim: '#D9D9E0',
      surfaceBright: '#F9F9FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F3F3FA',
      surfaceContainer: '#EDEDF4',
      surfaceContainerHigh: '#E8E7EE',
      surfaceContainerHighest: '#E2E2E9',
    },
    'light-medium-contrast': {
      primary: '#264273',
      surfaceTint: '#445E91',
      onPrimary: '#FFFFFF',
      primaryContainer: '#5A74A9',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#3B4355',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#6D7488',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#543A57',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#886B8A',
      onTertiaryContainer: '#FFFFFF',
      error: '#8C0009',
      onError: '#FFFFFF',
      errorContainer: '#DA342E',
      onErrorContainer: '#FFFFFF',
      background: '#F9F9FF',
      onBackground: '#1A1B20',
      surface: '#F9F9FF',
      onSurface: '#1A1B20',
      surfaceVariant: '#E1E2EC',
      onSurfaceVariant: '#40434B',
      outline: '#5C5F67',
      outlineVariant: '#787A83',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#2F3036',
      inverseOnSurface: '#F0F0F7',
      inversePrimary: '#ADC6FF',
      primaryFixed: '#5A74A9',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#415B8E',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#6D7488',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#545C6F',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#886B8A',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#6E5371',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#D9D9E0',
      surfaceBright: '#F9F9FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F3F3FA',
      surfaceContainer: '#EDEDF4',
      surfaceContainerHigh: '#E8E7EE',
      surfaceContainerHighest: '#E2E2E9',
    },
    'light-high-contrast': {
      primary: '#00214E',
      surfaceTint: '#445E91',
      onPrimary: '#FFFFFF',
      primaryContainer: '#264273',
      onPrimaryContainer: '#FFFFFF',
      secondary: '#1A2233',
      onSecondary: '#FFFFFF',
      secondaryContainer: '#3B4355',
      onSecondaryContainer: '#FFFFFF',
      tertiary: '#301A34',
      onTertiary: '#FFFFFF',
      tertiaryContainer: '#543A57',
      onTertiaryContainer: '#FFFFFF',
      error: '#4E0002',
      onError: '#FFFFFF',
      errorContainer: '#8C0009',
      onErrorContainer: '#FFFFFF',
      background: '#F9F9FF',
      onBackground: '#1A1B20',
      surface: '#F9F9FF',
      onSurface: '#000000',
      surfaceVariant: '#E1E2EC',
      onSurfaceVariant: '#21242B',
      outline: '#40434B',
      outlineVariant: '#40434B',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#2F3036',
      inverseOnSurface: '#FFFFFF',
      inversePrimary: '#E6ECFF',
      primaryFixed: '#264273',
      onPrimaryFixed: '#FFFFFF',
      primaryFixedDim: '#0A2B5C',
      onPrimaryFixedVariant: '#FFFFFF',
      secondaryFixed: '#3B4355',
      onSecondaryFixed: '#FFFFFF',
      secondaryFixedDim: '#252D3E',
      onSecondaryFixedVariant: '#FFFFFF',
      tertiaryFixed: '#543A57',
      onTertiaryFixed: '#FFFFFF',
      tertiaryFixedDim: '#3C243F',
      onTertiaryFixedVariant: '#FFFFFF',
      surfaceDim: '#D9D9E0',
      surfaceBright: '#F9F9FF',
      surfaceContainerLowest: '#FFFFFF',
      surfaceContainerLow: '#F3F3FA',
      surfaceContainer: '#EDEDF4',
      surfaceContainerHigh: '#E8E7EE',
      surfaceContainerHighest: '#E2E2E9',
    },
    dark: {
      primary: '#ADC6FF',
      surfaceTint: '#ADC6FF',
      onPrimary: '#102F60',
      primaryContainer: '#2B4678',
      onPrimaryContainer: '#D8E2FF',
      secondary: '#BFC6DC',
      onSecondary: '#293041',
      secondaryContainer: '#3F4759',
      onSecondaryContainer: '#DBE2F9',
      tertiary: '#DEBCDF',
      onTertiary: '#402843',
      tertiaryContainer: '#583E5B',
      onTertiaryContainer: '#FBD7FC',
      error: '#FFB4AB',
      onError: '#690005',
      errorContainer: '#93000A',
      onErrorContainer: '#FFDAD6',
      background: '#111318',
      onBackground: '#E2E2E9',
      surface: '#111318',
      onSurface: '#E2E2E9',
      surfaceVariant: '#44474F',
      onSurfaceVariant: '#C4C6D0',
      outline: '#8E9099',
      outlineVariant: '#44474F',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E2E2E9',
      inverseOnSurface: '#2F3036',
      inversePrimary: '#445E91',
      primaryFixed: '#D8E2FF',
      onPrimaryFixed: '#001A41',
      primaryFixedDim: '#ADC6FF',
      onPrimaryFixedVariant: '#2B4678',
      secondaryFixed: '#DBE2F9',
      onSecondaryFixed: '#141B2C',
      secondaryFixedDim: '#BFC6DC',
      onSecondaryFixedVariant: '#3F4759',
      tertiaryFixed: '#FBD7FC',
      onTertiaryFixed: '#29132D',
      tertiaryFixedDim: '#DEBCDF',
      onTertiaryFixedVariant: '#583E5B',
      surfaceDim: '#111318',
      surfaceBright: '#37393E',
      surfaceContainerLowest: '#0C0E13',
      surfaceContainerLow: '#1A1B20',
      surfaceContainer: '#1E1F25',
      surfaceContainerHigh: '#282A2F',
      surfaceContainerHighest: '#33353A',
    },
    'dark-medium-contrast': {
      primary: '#B4CBFF',
      surfaceTint: '#ADC6FF',
      onPrimary: '#001537',
      primaryContainer: '#7791C7',
      onPrimaryContainer: '#000000',
      secondary: '#C3CAE1',
      onSecondary: '#0E1626',
      secondaryContainer: '#8991A5',
      onSecondaryContainer: '#000000',
      tertiary: '#E2C0E3',
      onTertiary: '#230D28',
      tertiaryContainer: '#A687A8',
      onTertiaryContainer: '#000000',
      error: '#FFBAB1',
      onError: '#370001',
      errorContainer: '#FF5449',
      onErrorContainer: '#000000',
      background: '#111318',
      onBackground: '#E2E2E9',
      surface: '#111318',
      onSurface: '#FBFAFF',
      surfaceVariant: '#44474F',
      onSurfaceVariant: '#C9CAD4',
      outline: '#A1A2AC',
      outlineVariant: '#81838C',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E2E2E9',
      inverseOnSurface: '#282A2F',
      inversePrimary: '#2C4779',
      primaryFixed: '#D8E2FF',
      onPrimaryFixed: '#00102D',
      primaryFixedDim: '#ADC6FF',
      onPrimaryFixedVariant: '#183566',
      secondaryFixed: '#DBE2F9',
      onSecondaryFixed: '#091121',
      secondaryFixedDim: '#BFC6DC',
      onSecondaryFixedVariant: '#2E3647',
      tertiaryFixed: '#FBD7FC',
      onTertiaryFixed: '#1E0822',
      tertiaryFixedDim: '#DEBCDF',
      onTertiaryFixedVariant: '#462D49',
      surfaceDim: '#111318',
      surfaceBright: '#37393E',
      surfaceContainerLowest: '#0C0E13',
      surfaceContainerLow: '#1A1B20',
      surfaceContainer: '#1E1F25',
      surfaceContainerHigh: '#282A2F',
      surfaceContainerHighest: '#33353A',
    },
    'dark-high-contrast': {
      primary: '#FBFAFF',
      surfaceTint: '#ADC6FF',
      onPrimary: '#000000',
      primaryContainer: '#B4CBFF',
      onPrimaryContainer: '#000000',
      secondary: '#FBFAFF',
      onSecondary: '#000000',
      secondaryContainer: '#C3CAE1',
      onSecondaryContainer: '#000000',
      tertiary: '#FFF9FA',
      onTertiary: '#000000',
      tertiaryContainer: '#E2C0E3',
      onTertiaryContainer: '#000000',
      error: '#FFF9F9',
      onError: '#000000',
      errorContainer: '#FFBAB1',
      onErrorContainer: '#000000',
      background: '#111318',
      onBackground: '#E2E2E9',
      surface: '#111318',
      onSurface: '#FFFFFF',
      surfaceVariant: '#44474F',
      onSurfaceVariant: '#FBFAFF',
      outline: '#C9CAD4',
      outlineVariant: '#C9CAD4',
      shadow: '#000000',
      scrim: '#000000',
      inverseSurface: '#E2E2E9',
      inverseOnSurface: '#000000',
      inversePrimary: '#062859',
      primaryFixed: '#DEE6FF',
      onPrimaryFixed: '#000000',
      primaryFixedDim: '#B4CBFF',
      onPrimaryFixedVariant: '#001537',
      secondaryFixed: '#DFE6FD',
      onSecondaryFixed: '#000000',
      secondaryFixedDim: '#C3CAE1',
      onSecondaryFixedVariant: '#0E1626',
      tertiaryFixed: '#FFDCFF',
      onTertiaryFixed: '#000000',
      tertiaryFixedDim: '#E2C0E3',
      onTertiaryFixedVariant: '#230D28',
      surfaceDim: '#111318',
      surfaceBright: '#37393E',
      surfaceContainerLowest: '#0C0E13',
      surfaceContainerLow: '#1A1B20',
      surfaceContainer: '#1E1F25',
      surfaceContainerHigh: '#282A2F',
      surfaceContainerHighest: '#33353A',
    },
  },
  palettes: {
    primary: {
      '0': '#000000',
      '5': '#00102D',
      '10': '#001A41',
      '15': '#002455',
      '20': '#002E69',
      '25': '#00397E',
      '30': '#004494',
      '35': '#0B4FA7',
      '40': '#225CB3',
      '50': '#4275CE',
      '60': '#5E8FEA',
      '70': '#80ABFF',
      '80': '#ADC6FF',
      '90': '#D8E2FF',
      '95': '#EDF0FF',
      '98': '#F9F9FF',
      '99': '#FEFBFF',
      '100': '#FFFFFF',
    },
    secondary: {
      '0': '#000000',
      '5': '#071123',
      '10': '#121B2E',
      '15': '#1C2639',
      '20': '#273044',
      '25': '#323B4F',
      '30': '#3E475B',
      '35': '#495267',
      '40': '#555E74',
      '50': '#6E778D',
      '60': '#8791A8',
      '70': '#A2ABC3',
      '80': '#BDC6DF',
      '90': '#D9E2FC',
      '95': '#EDF0FF',
      '98': '#F9F9FF',
      '99': '#FEFBFF',
      '100': '#FFFFFF',
    },
    tertiary: {
      '0': '#000000',
      '5': '#1F0624',
      '10': '#2B1130',
      '15': '#361C3B',
      '20': '#422646',
      '25': '#4E3152',
      '30': '#5A3C5E',
      '35': '#66486A',
      '40': '#735476',
      '50': '#8D6C90',
      '60': '#A885AB',
      '70': '#C49FC6',
      '80': '#E1BAE2',
      '90': '#FED6FF',
      '95': '#FFEBFC',
      '98': '#FFF7FA',
      '99': '#FFFBFF',
      '100': '#FFFFFF',
    },
    neutral: {
      '0': '#000000',
      '5': '#101114',
      '10': '#1B1B1F',
      '15': '#252629',
      '20': '#303033',
      '25': '#3B3B3F',
      '30': '#46464A',
      '35': '#525256',
      '40': '#5E5E62',
      '50': '#77777A',
      '60': '#919094',
      '70': '#ABABAF',
      '80': '#C7C6CA',
      '90': '#E3E2E6',
      '95': '#F2F0F4',
      '98': '#FAF9FD',
      '99': '#FEFBFF',
      '100': '#FFFFFF',
    },
    'neutral-variant': {
      '0': '#000000',
      '5': '#0E1118',
      '10': '#191B22',
      '15': '#23262D',
      '20': '#2E3038',
      '25': '#393B43',
      '30': '#44474F',
      '35': '#50525A',
      '40': '#5C5E66',
      '50': '#74777F',
      '60': '#8E9099',
      '70': '#A9ABB4',
      '80': '#C4C6D0',
      '90': '#E1E2EC',
      '95': '#EFF0FA',
      '98': '#F9F9FF',
      '99': '#FEFBFF',
      '100': '#FFFFFF',
    },
  },
  styles: {
    display: {
      large: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 57,
        lineHeight: 64,
        letterSpacing: -0.25,
      },
      medium: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 45,
        lineHeight: 52,
        letterSpacing: 0,
      },
      small: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 36,
        lineHeight: 44,
        letterSpacing: 0,
      },
    },
    headline: {
      large: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 700,
        fontSize: 28,
        lineHeight: 36,
        letterSpacing: 0,
      },
      medium: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 800,
        fontSize: 24,
        lineHeight: 30,
        letterSpacing: 0,
      },
      small: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 800,
        fontSize: 22,
        lineHeight: 28,
        letterSpacing: 0,
      },
    },
    body: {
      large: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.5,
      },
      medium: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: 0.25,
      },
      small: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: 0.4000000059604645,
      },
    },
    label: {
      large: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Medium',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: 0.10000000149011612,
      },
      medium: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Medium',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: 0.5,
      },
      small: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Medium',
        fontWeight: 500,
        fontSize: 11,
        lineHeight: 16,
        letterSpacing: 0.5,
      },
    },
    title: {
      large: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Regular',
        fontWeight: 400,
        fontSize: 22,
        lineHeight: 28,
        letterSpacing: 0,
      },
      medium: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Medium',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.15000000596046448,
      },
      small: {
        fontFamilyName: 'Roboto',
        fontFamilyStyle: 'Medium',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: 0.10000000149011612,
      },
    },
  },
};

export default M3Theme;
