import React, { ReactNode, useEffect, useState } from 'react';
import ChipGroupContext from './RadioGroupContext';
import { Box, BoxProps } from '@mui/material';
import styles from './RadioGroup.module.scss';
import classNames from 'classnames';

export interface RadioGroupProps extends Omit<BoxProps, 'onChange'> {
  children: ReactNode;
  name?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const RadioGroup = ({
  children,
  className,
  name = 'RadioGroup',
  onChange,
  value: valueProp,
  ...rest
}: RadioGroupProps) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    if (value) {
      onChange?.(value);
    }
  }, [value, onChange]);

  return (
    <ChipGroupContext.Provider value={{ name, value, onChange: setValue }}>
      <Box className={classNames(styles['radio-group'], className)} {...rest}>
        {children}
      </Box>
    </ChipGroupContext.Provider>
  );
};

export default RadioGroup;
