import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { GoalProps } from './Goal';
import styles from './Goal.module.scss';

export interface GoalSkeletonProps extends Partial<GoalProps> {}

const GoalSkeleton = ({ className, size = 'medium' }: GoalSkeletonProps) => {
  return (
    <Skeleton
      className={classNames(
        styles['goal'],
        styles[`size-${size}-skeleton`],
        className
      )}
      variant='rounded'
    />
  );
};

export default GoalSkeleton;
