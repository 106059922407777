import { Box, BoxProps, SvgIcon, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './MedalRank.module.scss';
import getMedalColor from './util/getMedalColor';
import assets from '../../../../../assets/assets';

export interface MedalRankProps extends BoxProps {
  rank: number;
}

const MedalRank = ({ className, rank, ...rest }: MedalRankProps) => {
  const medalColor = getMedalColor(rank);

  return (
    <Box
      className={classNames(
        medalColor !== undefined ? styles['medal-rank'] : undefined,
        medalColor !== undefined ? `medal-color-${medalColor}` : undefined,
        className
      )}
      {...rest}
    >
      <SvgIcon className={styles['medal-icon']}>
        <assets.icons.medal_filled />
      </SvgIcon>
      <Typography className={styles['rank']}>{rank}</Typography>
    </Box>
  );
};

export default MedalRank;
