import { Box } from '@mui/material';
import styles from './Keyboard.module.scss';
import classNames from 'classnames';
import { KeyboardProps } from './Keyboard';
import ExpressionboardSkeleton from './components/Expressionboard/ExpressionboardSkeleton';
import TokensSkeleton from './components/Tokens/TokensSkeleton';
import OperatorsSkeleton from './components/Operators/OperatorsSkeleton';
import SubmitButtonSkeleton from './components/SubmitButton/SubmitButtonSkeleton';

export interface KeyboardSkeletonProps extends Partial<KeyboardProps> {}

const KeyboardSkeleton = ({ className, ...rest }: KeyboardSkeletonProps) => {
  return (
    <Box className={classNames(styles['keyboard'], className)}>
      <ExpressionboardSkeleton />

      <Box className={styles['keys-container']}>
        <TokensSkeleton />

        <OperatorsSkeleton />
      </Box>

      <SubmitButtonSkeleton />
    </Box>
  );
};

export default KeyboardSkeleton;
