import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../../../../components/Dialog/DialogHeader/DialogHeader';
import styles from './ForfeitMatchDialog.module.scss';

export interface ForfeitMatchDialogProps extends DialogProps {
  onYes: VoidFunction;
  onNo: VoidFunction;
}

const ForfeitMatchDialog = ({
  onYes,
  onNo,
  ...rest
}: ForfeitMatchDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog {...rest}>
      <DialogHeader
        onClose={onNo}
        title={t('dialogs.forfeitMatch.title')}
        titleProps={{
          id: 'how-to-play-dialog-title',
        }}
      />

      <DialogContent className={styles['dialog-content']}>
        <Typography>{t('dialogs.forfeitMatch.body')}</Typography>
      </DialogContent>

      <DialogActions className={styles['dialog-actions']}>
        <Button onClick={onYes}>{t('actions.yes')}</Button>
        <Button onClick={onNo}>{t('actions.no')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForfeitMatchDialog;
