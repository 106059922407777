import React from 'react';
import { Box, Typography, Link, SvgIcon } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import styles from './ChallengeStatus.module.scss';
import TimerCircle from '../TimerCircle/TimerCircle';
import assets from '../../../../assets/assets';
import classNames from 'classnames';

interface ChallengeStatusProps {
  status: string;
  challenger?: string;
  challengee: string;
  expirationTime?: number;
  isChallenger?: boolean;
  onTimeElapsed?: () => void;
}

const ChallengeStatus: React.FC<ChallengeStatusProps> = ({
  status,
  challenger,
  challengee,
  expirationTime,
  isChallenger,
  onTimeElapsed,
}) => {
  const { t } = useTranslation();

  const getStatusIcon = () => {
    switch (status) {
      case 'PENDING':
        return (
          <TimerCircle
            expirationTime={expirationTime!}
            onTimeElapsed={onTimeElapsed}
            size={70}
          />
        );
      case 'ACCEPTED':
        return (
          <Box
            className={classNames(
              styles['status-icon-container'],
              styles['success']
            )}
          >
            <SvgIcon className={styles['status-icon']}>
              <assets.icons.check_circle />
            </SvgIcon>
          </Box>
        );
      case 'BLOCKED':
        return (
          <Box
            className={classNames(
              styles['status-icon-container'],
              styles['error']
            )}
          >
            <SvgIcon className={styles['status-icon']}>
              <assets.icons.block />
            </SvgIcon>
          </Box>
        );
      case 'DECLINED':
        return (
          <Box
            className={classNames(
              styles['status-icon-container'],
              styles['error']
            )}
          >
            <SvgIcon className={styles['status-icon']}>
              <assets.icons.cancel />
            </SvgIcon>
          </Box>
        );
      case 'CANCELED':
        return (
          <Box
            className={classNames(
              styles['status-icon-container'],
              styles['warning']
            )}
          >
            <SvgIcon className={styles['status-icon']}>
              <assets.icons.do_not_disturb />
            </SvgIcon>
          </Box>
        );
      default:
        return (
          <SvgIcon>
            <assets.icons.do_not_disturb />
          </SvgIcon>
        );
    }
  };

  const getStatusMessage = () => {
    switch (status) {
      case 'PENDING':
        return t(
          isChallenger
            ? 'dialogs.challengeStatus.bodyChallenger'
            : 'dialogs.challengeStatus.bodyChallengee',
          {
            username: isChallenger ? challengee : challenger,
          }
        );
      case 'ACCEPTED':
        return t(
          isChallenger
            ? 'dialogs.challengeStatus.acceptedChallenger'
            : 'dialogs.challengeStatus.acceptedChallengee',
          {
            username: challengee,
          }
        );
      case 'BLOCKED':
        return t('dialogs.challengeStatus.blocked', {
          username: challengee,
        });
      case 'DECLINED':
        return t(
          isChallenger
            ? 'dialogs.challengeStatus.declinedChallenger'
            : 'dialogs.challengeStatus.declinedChallengee',
          {
            username: challengee,
          }
        );
      case 'CANCELED':
        return t(
          isChallenger
            ? 'dialogs.challengeStatus.canceledChallenger'
            : 'dialogs.challengeStatus.canceledChallengee',
          {
            username: challenger,
          }
        );
      default:
        return '';
    }
  };

  return (
    <Box className={styles['challenge-status']}>
      <Box>{getStatusIcon()}</Box>
      <Typography className={styles['status-text']}>
        <Trans
          i18nKey={getStatusMessage()}
          components={[
            <Link
              className={styles['username']}
              color='inherit'
              href={`/user/${isChallenger ? challengee : challenger}`}
              underline='hover'
              target='_blank'
            />,
          ]}
        />
      </Typography>
    </Box>
  );
};

export default ChallengeStatus;
