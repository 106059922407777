import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from '@mui/material';

const MuiSwitch: {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSwitch'];
  variants?: ComponentsVariants<Theme>['MuiSwitch'];
} = {
  styleOverrides: {
    root: {
      width: 40,
      height: 22,
      padding: 0,
      margin: 8,
      overflow: 'visible',
    },
    switchBase: ({ theme }) => ({
      height: 'auto',
      padding: 3,
      '&+.MuiSwitch-track': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:hover': {
        backgroundColor: 'unset',
        '&+.MuiSwitch-track': {
          backgroundColor: theme.palette.secondary.extraDark,
        },
      },
      '&.Mui-focusVisible': {
        outline: `6rem solid ${theme.palette.primary.extraLight}`,
      },
      '&.Mui-checked': {
        transform: 'translateX(18rem)',
        color: theme.palette.background.default,
        '&:hover': {
          backgroundColor: 'unset',
          '&+.MuiSwitch-track': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        '&+.MuiSwitch-track': {
          opacity: 1,
        },
      },
    }),
    thumb: ({ theme }) => ({
      width: 16,
      height: 16,
      boxShadow: 'none',
      color: theme.palette.background.default,
    }),
    track: ({ theme }) => ({
      borderRadius: 12,
      backgroundColor: theme.palette.secondary.main,
      opacity: 1,
      transition:
        'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }),
  },
};

export default MuiSwitch;
