import { Box, BoxProps, Link, SvgIcon, Typography } from '@mui/material';
import styles from './SolutionHeader.module.scss';
import classNames from 'classnames';
import { SolutionHeaderTitleVariant } from './types/SolutionHeaderTitleVariant';
import RatingChange from './components/RatingChange/RatingChange';
import assets from '../../../../../../../assets/assets';
import MatchForfeitLabel from '../MatchForfeitLabel/MatchForfeitLabel';
import { MatchOutcome } from '../../../../../../../types/match/MatchOutcome';
import { Animatable } from '../../../../../../../types/Animatable';
import FadeInRight from '../../../../../../../components/motion/FadeInRight/FadeInRight';
import MatchOutcomeChip from '../../../../../../Match/components/MatchOutcomeText/MatchOutcomeChip';
import useUser from '../../../../../../../hooks/useUser/useUser';

export interface SolutionHeaderProps extends BoxProps, Animatable {
  forfeit?: boolean;
  hideDistanceCardOnSmallScreen?: boolean;
  matchOutcome?: MatchOutcome;
  rating?: number;
  ratingChange?: number;
  title: string;
  titleVariant?: SolutionHeaderTitleVariant;
}

const SolutionHeader = ({
  animate,
  className,
  forfeit,
  hideDistanceCardOnSmallScreen = true,
  matchOutcome,
  rating,
  ratingChange,
  title,
  titleVariant = 'profile-link',
  ...rest
}: SolutionHeaderProps) => {
  const { user } = useUser();

  const matchOutcomeChip = matchOutcome ? (
    <MatchOutcomeChip
      className={styles['match-outcome-text']}
      matchOutcome={matchOutcome}
    />
  ) : undefined;

  const updatedRatingText =
    rating !== undefined && ratingChange !== undefined ? (
      <Typography>
        {rating}{' '}
        {ratingChange !== 0 && (
          <RatingChange
            ratingChange={ratingChange}
            className={styles['rating-change']}
          />
        )}
      </Typography>
    ) : undefined;

  return (
    <Box className={classNames(styles['solution-header'], className)} {...rest}>
      {titleVariant === 'profile-link' ? (
        <Box className={styles['start-node']}>
          <Box className={styles['profile-link-container']}>
            <Link
              className={classNames(
                styles['profile-link'],
                styles['title'],
                title === user.username ? styles['personal-title'] : undefined
              )}
              color='inherit'
              href={`/user/${title}`}
              onClick={(e) => e.stopPropagation()}
              underline='hover'
            >
              {title}
            </Link>

            {matchOutcomeChip &&
              (animate ? (
                <FadeInRight>{matchOutcomeChip}</FadeInRight>
              ) : (
                matchOutcomeChip
              ))}

            {updatedRatingText &&
              (animate ? (
                <FadeInRight>{updatedRatingText}</FadeInRight>
              ) : (
                updatedRatingText
              ))}
          </Box>
        </Box>
      ) : (
        <Box className={styles['shortest-solution-container']}>
          <SvgIcon>
            <assets.icons.boronu_icon_filled />
          </SvgIcon>

          <Typography className={styles['title']}>{title}</Typography>
        </Box>
      )}

      <Box className={styles['end-node']}>
        {forfeit && <MatchForfeitLabel className={styles['match-forf']} />}
      </Box>
    </Box>
  );
};

export default SolutionHeader;
