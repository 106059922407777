import { useEffect, useState } from 'react';
import { useEmailAvailabilityQuery } from '../../../redux/slices/api/api';
import util from '../../../util/util';
import { EmailValidationErrorType } from '../../../redux/slices/api/types/error/EmailValidationErrorType';

const useEmailValidation = (email: string) => {
  const isValid = util.validation.isValidEmailAddress(email);
  const [error, setError] = useState<EmailValidationErrorType | null>(null);

  const { data: availabilityData, isFetching } = useEmailAvailabilityQuery(
    { email: email },
    { skip: !isValid }
  );

  useEffect(() => {
    if ([null, undefined, ''].includes(email)) {
      setError('Empty Email');
    } else if (!util.validation.isValidEmailAddress(email)) {
      setError('Invalid Email');
    } else {
      setError(null);
    }
  }, [email]);

  useEffect(() => {}, [isFetching, availabilityData]);

  useEffect(() => {
    if (availabilityData !== undefined && !isFetching) {
      const available = availabilityData;

      if (!available) {
        setError('Email Unavailable');
      }
    }
  }, [availabilityData, isFetching]);

  return {
    isValidating: isFetching,
    error,
  };
};

export default useEmailValidation;
