import styles from './HeatMap.module.scss';
import { useTranslation } from 'react-i18next';
import Tile, { TileProps } from './Tile/Tile';
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import util from '../../../../../util/util';

export interface HeatMapProps {
  capacity?: number;
  tiles: TileProps[];
}

const HeatMap = ({ capacity = 60, tiles, ...rest }: HeatMapProps) => {
  const { t } = useTranslation();

  const maxWidth900 = useMediaQuery('(max-width:900px)');
  const maxWidth600 = useMediaQuery('(max-width:600px)');
  const maxWidth500 = useMediaQuery('(max-width:500px)');
  capacity = maxWidth900 ? 45 : capacity;
  capacity = maxWidth600 ? 30 : capacity;
  capacity = maxWidth500 ? 24 : capacity;

  tiles = tiles.slice(0, capacity);
  const averageDistance = parseFloat(
    util.numbers
      .avg(
        tiles
          .filter((tile) => tile.value !== undefined && tile.value !== null)
          .map((tile) => tile.value!)
      )
      .toFixed(2)
  );

  return (
    <Box className={styles['heat-map-container']} {...rest}>
      <div className={styles['heat-map']}>
        {tiles.slice(0, capacity).map((tileProps, i) => (
          <Tile key={i} {...tileProps} />
        ))}
        {tiles.length < capacity &&
          Array.from(Array(capacity - tiles.length).keys()).map((i) => (
            <Tile key={i + tiles.length} />
          ))}
      </div>

      {tiles.length ? (
        <Typography className={styles['average-distance']}>
          {`${t('navigation.profile.averageDistance')}: `}
          <Typography className={styles['average-distance-value']}>
            {isNaN(averageDistance) ? '--' : averageDistance}
          </Typography>
        </Typography>
      ) : null}
    </Box>
  );
};

export default HeatMap;
