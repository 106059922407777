import { getMatchOutcomeVariant } from '../pages/Match/components/PuzzleSolutions/util/getMatchOutcomeVariant';
import { getMatchOutcome } from './matches/getMatchOutcome';
import { getMatchWinner } from './matches/getMatchWinner';
import { getParticipantsPuzzleSolution } from './matches/getParticipantsPuzzleSolution';

const matches = {
  getMatchOutcome,
  getMatchOutcomeVariant,
  getMatchWinner,
  getParticipantsPuzzleSolution,
};

export default matches;
