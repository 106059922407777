import { useTranslation } from 'react-i18next';
import Section, { SectionProps } from '../components/Section/Section';
import { Divider, Switch, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings/useSettings';
import Setting from '../components/Setting/Setting';
import accountSectionStyles from '../AccountSettingsSection/AccountSettingsSection.module.scss';

export interface GameSettingsSectionProps {
  sectionProps?: SectionProps;
  showHeading?: boolean;
}

const GameSettingsSection = ({
  sectionProps,
  showHeading = true,
}: GameSettingsSectionProps) => {
  const { t } = useTranslation();

  const {
    autoExpandShortestSolutionCard,
    setAutoExpandShortestSolutionCard,
    enableGameBoardSounds,
    setEnableGameBoardSounds,
    blockChallengeAFriendRequests,
    setBlockChallengeAFriendRequests,
  } = useSettings();

  return (
    <Section {...sectionProps}>
      {showHeading && (
        <Typography variant='page-h2'>
          {t('navigation.more.sections.game')}
        </Typography>
      )}

      <Setting
        endNode={
          <Switch
            checked={enableGameBoardSounds}
            inputProps={{ 'aria-label': 'enable game board sounds' }}
            onChange={(e, checked) => setEnableGameBoardSounds(checked)}
          />
        }
        title={t('settings.game.enableGameBoardSounds')}
      />

      <Divider className={accountSectionStyles['setting-divider']} />

      <Setting
        endNode={
          <Switch
            checked={autoExpandShortestSolutionCard}
            inputProps={{ 'aria-label': 'expand shortest solution card' }}
            onChange={(e, checked) =>
              setAutoExpandShortestSolutionCard(checked)
            }
          />
        }
        title={t('settings.game.autoExpandShortestSolutionCard')}
      />

      <Divider className={accountSectionStyles['setting-divider']} />

      <Setting
        endNode={
          <Switch
            checked={blockChallengeAFriendRequests}
            inputProps={{ 'aria-label': 'block challenge a friend requests' }}
            onChange={(e, checked) => setBlockChallengeAFriendRequests(checked)}
          />
        }
        title={t('settings.game.blockChallengeAFriendRequests')}
      />
    </Section>
  );
};

export default GameSettingsSection;
