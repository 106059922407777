import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import EditUsernameDialog from './EditUsernameDialog/EditUsernameDialog';
import { Divider, Link, Typography } from '@mui/material';
import EditPasswordDialog from './EditPasswordDialog/EditPasswordDialog';
import { useState } from 'react';
import Section, { SectionProps } from '../components/Section/Section';
import Setting from '../components/Setting/Setting';
import util from '../../../util/util';
import config from '../../../config/config';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import EditEmailDialog from './EditEmailDialog/EditEmailDialog';
import styles from './AccountSettingsSection.module.scss';
import DeleteYourAccountDialog from './DeleteYourAccountDialog/DeleteYourAccountDialog';

export interface AccountSettingsSectionProps {
  email: string;
  emailLastUpdatedAt?: Date;
  username: string;
  usernameLastUpdatedAt?: Date;
  sectionProps?: SectionProps;
}

const AccountSettingsSection = ({
  email,
  emailLastUpdatedAt,
  username,
  usernameLastUpdatedAt,
  sectionProps,
}: AccountSettingsSectionProps) => {
  const { t } = useTranslation();
  const appLanguage = useAppSelector(
    (state: RootState) => state.settings.appLanguage
  );

  const [openEditUsernameDialog, setOpenEditUsernameDialog] = useState(false);
  const [openEditEmailDialog, setOpenEditEmailDialog] = useState(false);
  const [openEditPasswordDialog, setOpenEditPasswordDialog] = useState(false);
  const [openDeleteYourAccountDialog, setOpenDeleteYourAccountDialog] =
    useState(false);

  const usernameUpdateAvailableAt =
    usernameLastUpdatedAt !== undefined
      ? util.dates.getAvailableAt(
          usernameLastUpdatedAt,
          config.account.username.minDaysBetweenChanges
        )
      : new Date();

  const isUsernameEditable = usernameUpdateAvailableAt <= new Date();

  const emailUpdateAvailableAt =
    emailLastUpdatedAt !== undefined
      ? util.dates.getAvailableAt(
          emailLastUpdatedAt,
          config.account.email.minDaysBetweenChanges
        )
      : new Date();

  const isEmailEditable = emailUpdateAvailableAt <= new Date();

  return (
    <Section {...sectionProps}>
      <Typography variant='page-h2'>
        {t('navigation.more.sections.account')}
      </Typography>

      <Setting
        description={username}
        endNode={
          isUsernameEditable ? (
            <>
              <Link
                aria-label={'edit username'}
                className={styles['setting-action-button']}
                color='inherit'
                component='button'
                onClick={() => setOpenEditUsernameDialog(true)}
                underline='hover'
              >
                {t('actions.edit')}
              </Link>

              {openEditUsernameDialog && (
                <EditUsernameDialog
                  onClose={() => setOpenEditUsernameDialog(false)}
                  open={openEditUsernameDialog}
                  maxWidth='xs'
                />
              )}
            </>
          ) : undefined
        }
        title={t('forms.username')}
      >
        {isUsernameEditable ? undefined : (
          <Typography>
            {t('navigation.more.account.usernameUpdateAvailableIn', {
              distanceToNow: formatDistanceToNow(
                new Date(usernameUpdateAvailableAt),
                {
                  addSuffix: true,
                  locale: util.dates.getDateFnsLocale(appLanguage),
                }
              ),
            })}
          </Typography>
        )}
      </Setting>

      <Divider className={styles['setting-divider']} />

      <Setting
        description={email}
        endNode={
          isEmailEditable ? (
            <>
              <Link
                aria-label={'edit email'}
                className={styles['setting-action-button']}
                color='inherit'
                component='button'
                onClick={() => setOpenEditEmailDialog(true)}
                underline='hover'
              >
                {t('actions.edit')}
              </Link>

              {openEditEmailDialog && (
                <EditEmailDialog
                  onClose={() => setOpenEditEmailDialog(false)}
                  open={openEditEmailDialog}
                  maxWidth='xs'
                />
              )}
            </>
          ) : undefined
        }
        title={t('forms.email')}
      >
        {isEmailEditable ? undefined : (
          <Typography>
            {t('navigation.more.account.emailUpdateAvailableIn', {
              distanceToNow: formatDistanceToNow(
                new Date(emailUpdateAvailableAt),
                {
                  addSuffix: true,
                  locale: util.dates.getDateFnsLocale(appLanguage),
                }
              ),
            })}
          </Typography>
        )}
      </Setting>

      <Divider className={styles['setting-divider']} />

      <Setting
        description={'*'.repeat(8)}
        endNode={
          <>
            <Link
              aria-label={'edit password'}
              className={styles['setting-action-button']}
              color='inherit'
              component='button'
              onClick={() => setOpenEditPasswordDialog(true)}
              underline='hover'
            >
              {t('actions.edit')}
            </Link>

            {openEditPasswordDialog && (
              <EditPasswordDialog
                onClose={() => setOpenEditPasswordDialog(false)}
                open={openEditPasswordDialog}
                maxWidth='xs'
              />
            )}
          </>
        }
        title={t('forms.password')}
      />

      <Divider className={styles['setting-divider']} />

      <Setting
        description={t('settings.accountSettings.deleteYourAccountDescription')}
        endNode={
          <>
            <Link
              aria-label={'delete account'}
              className={styles['setting-action-button']}
              color='inherit'
              component='button'
              onClick={() => setOpenDeleteYourAccountDialog(true)}
              underline='hover'
            >
              {t('actions.delete')}
            </Link>

            {openDeleteYourAccountDialog && (
              <DeleteYourAccountDialog
                onClose={() => setOpenDeleteYourAccountDialog(false)}
                open={openDeleteYourAccountDialog}
                maxWidth='xs'
              />
            )}
          </>
        }
        title={t('actions.deleteYourAccount')}
      />
    </Section>
  );
};

export default AccountSettingsSection;
