import { ReactComponent as account_circle_filled } from './icons/account_circle_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as account_circle } from './icons/account_circle_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as backspace } from './icons/backspace_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as block } from './icons/block_24dp_5F6368_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as boronu_icon_filled } from './icons/boronu_icon_filled.svg';
import { ReactComponent as boronu_icon } from './icons/boronu_icon.svg';
import { ReactComponent as cancel } from './icons/cancel_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as cancel_filled } from './icons/cancel_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as check_circle } from './icons/check_circle_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as check } from './icons/check_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as check_small } from './icons/check_small_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as close_small } from './icons/close_small_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as close } from './icons/close_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as crown } from './icons/crown.svg';
import { ReactComponent as dark_mode } from './icons/dark_mode_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as do_not_disturb } from './icons/do_not_disturb_on_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as expand_more } from './icons/expand_more_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as fitness_center } from './icons/fitness_center_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as flag } from './icons/flag_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as group } from './icons/group_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as group_filled } from './icons/group_24dp_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as groups } from './icons/groups_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as groups_filled } from './icons/groups_24dp_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as handshake_filled } from './icons/handshake_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as handshake } from './icons/handshake_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as help } from './icons/help_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as home_filled } from './icons/home_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as home } from './icons/home_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as language } from './icons/language_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as leaderboard_filled } from './icons/leaderboard_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as leaderboard } from './icons/leaderboard_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as list } from './icons/list_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as lock } from './icons/lock_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as lock_filled } from './icons/lock_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as logout } from './icons/logout_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as medal_filled } from './icons/medal_filled.svg';
import { ReactComponent as navigate_next } from './icons/navigate_next_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as person_add_filled } from './icons/person_add_24dp_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as person_cancel_filled } from './icons/person_cancel_24dp_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as person_filled } from './icons/person_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as person_remove_filled } from './icons/person_remove_24dp_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as search } from './icons/search_24dp_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as settings } from './icons/settings_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as skip_next } from './icons/skip_next_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as swords } from './icons/swords_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as swords_filled } from './icons/swords_FILL1_wght500_GRAD0_opsz48.svg';
import { ReactComponent as visibility_off } from './icons/visibility_off_FILL0_wght500_GRAD0_opsz48.svg';
import { ReactComponent as visibility } from './icons/visibility_FILL0_wght500_GRAD0_opsz48.svg';

const icons = {
  account_circle_filled,
  account_circle,
  backspace,
  block,
  boronu_icon_filled,
  boronu_icon,
  cancel,
  cancel_filled,
  check_circle,
  check,
  check_small,
  close_small,
  close,
  crown,
  dark_mode,
  do_not_disturb,
  expand_more,
  fitness_center,
  flag,
  group,
  group_filled,
  groups,
  groups_filled,
  handshake_filled,
  handshake,
  help,
  home_filled,
  home,
  language,
  leaderboard_filled,
  leaderboard,
  lock,
  lock_filled,
  list,
  logout,
  medal_filled,
  navigate_next,
  person_add_filled,
  person_cancel_filled,
  person_filled,
  person_remove_filled,
  search,
  settings,
  skip_next,
  swords,
  swords_filled,
  visibility_off,
  visibility,
};

export default icons;
