// import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Signin from './pages/Signin/Signin';
import Play from './pages/Play/Play';
import Profile from './pages/Profile/Profile';
import Standings from './pages/Standings/Standings';
import Settings from './pages/Settings/Settings';
import Match from './pages/Match/Match';
import theme from './theme/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import AppHeaderFooter from './pages/AppHeaderFooter/AppHeaderFooter';
import ErrorPage from './pages/ErrorPage/ErrorPage';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Signup from './pages/Signup/Signup';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Home from './pages/Home/Home';
import Signout from './pages/Signout/Signout';
import User from './pages/User/User';
import PracticeGameboard from './pages/Gameboards/PracticeGameboard/PracticeGameboard';
import MatchmakingGameboard from './pages/Gameboards/MatchmakingGameboard/MatchmakingGameboard';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import PublicAndGuestRoute from './components/PublicAndGuestRoute/PublicAndGuestRoute';
import ResetPasswordComplete from './pages/ResetPasswordComplete/ResetPasswordComplete';
import SignupComplete from './pages/SignupComplete/SignupComplete';
import UpdateEmailComplete from './pages/UpdateEmailComplete/UpdateEmailComplete';
import FAQ from './pages/FAQ/FAQ';
import Friends from './pages/Friends/Friends';
import Challenge from './pages/Challenge/Challenge';
import ChallengeGameboard from './pages/Gameboards/ChallengeGameboard/ChallengeGameboard';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <CssVarsProvider theme={theme}>
            <CssBaseline />
            <ScrollToTop />
            <App />
          </CssVarsProvider>
        </QueryClientProvider>
      </Provider>
    ),
    errorElement: (
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <ErrorPage />
      </CssVarsProvider>
    ),
    children: [
      {
        path: '/',
        element: <AppHeaderFooter />,
        children: [
          {
            path: '/',
            index: true,
            element: <Home />,
          },
          {
            path: 'signout',
            element: <Signout />,
          },
          {
            path: '/match/:id',
            element: <PrivateRoute element={<Match />} />,
          },
          {
            path: 'play',
            element: <PrivateRoute element={<Play />} />,
          },
          {
            path: '/play/challenge/lobby',
            element: <PrivateRoute element={<Challenge />} />,
          },
          {
            path: 'profile',
            element: <PrivateRoute element={<Profile />} />,
          },
          {
            path: 'friends',
            element: <PrivateRoute element={<Friends />} />,
          },
          {
            path: 'settings',
            element: <PrivateRoute element={<Settings />} />,
          },
          {
            path: 'standings',
            element: <PrivateRoute element={<Standings />} />,
          },
          {
            path: '/user/:username',
            element: <PrivateRoute element={<User />} />,
          },
        ],
      },
      {
        path: 'signin',
        element: <PublicAndGuestRoute element={<Signin />} />,
      },
      {
        path: 'signup',
        element: <PublicAndGuestRoute element={<Signup />} />,
      },
      {
        path: 'signup/complete',
        element: <PublicAndGuestRoute element={<SignupComplete />} />,
      },
      {
        path: 'reset',
        element: <PublicAndGuestRoute element={<ResetPassword />} />,
      },
      {
        path: 'reset/complete',
        element: <PublicAndGuestRoute element={<ResetPasswordComplete />} />,
      },
      {
        path: '/play/practice',
        element: <PrivateRoute element={<PracticeGameboard />} />,
      },
      {
        path: '/play/normal',
        element: <PrivateRoute element={<MatchmakingGameboard />} />,
      },
      {
        path: '/play/rated',
        element: <PrivateRoute element={<MatchmakingGameboard />} />,
      },
      {
        path: '/play/challenge',
        element: <PrivateRoute element={<ChallengeGameboard />} />,
      },
      {
        path: 'update-email/complete',
        element: <UpdateEmailComplete />,
      },
      {
        path: '/faq',
        element: <FAQ />,
      },
      {
        path: '/privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: '/*',
        element: <PageNotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={router} />);

// Strict Mode causes double render
// root.render(
//   <StrictMode>
//     <RouterProvider router={router} />
//   </StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
