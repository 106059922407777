import { Box, BoxProps } from '@mui/system';
import classNames from 'classnames';
import styles from './Page.module.scss';
import { PageVariant } from './types/PageVariant';
import { PagePaddingSize } from './types/PagePaddingSize';

export interface PageProps extends BoxProps {
  paddingSize?: PagePaddingSize;
  variant?: PageVariant;
}

const Page = ({
  children,
  className,
  paddingSize = 'medium',
  variant = 'app',
  ...rest
}: PageProps) => {
  return (
    <Box
      className={classNames(
        styles['page'],
        styles[`page-variant-${variant}`],
        styles[`page-padding-size-${paddingSize}`],
        className
      )}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Page;
