import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SigninResponse } from './types/accounts/SigninResponse';
import { SigninRequest } from './types/accounts/SigninRequest';
import { SignupRequest } from './types/accounts/SignupRequest';
import { ErrorResponse } from './types/ErrorResponse';
import { AccountInformationResponse } from './types/accounts/AccountInformationResponse';
import { EmailAvailabilityResponse } from './types/accounts/EmailAvailabilityResponse';
import { EmailAvailabilityParams } from './types/accounts/EmailAvailabilityParams';
import { UpdateEmailRequest } from './types/accounts/UpdateEmailRequest';
import { UpdatePasswordRequest } from './types/accounts/UpdatePasswordRequest';
import { PasswordResetRequest } from './types/accounts/PasswordResetRequest';
import { UsernameAvailabilityResponse } from './types/accounts/UsernameAvailabilityResponse';
import { UsernameAvailabilityParams } from './types/accounts/UsernameAvailabilityParams';
import { UpdateUsernameRequest } from './types/accounts/UpdateUsernameRequest';
import {
  GetPracticeMatchResponse,
  SubmitPracticeMatchResponse,
  SubmitPracticeMatchSolutionParams,
} from './types/gamemodes/practice/PracticeMatch';
import { GetMatchParams, GetMatchResponse } from './types/matches/Match';
import { GetProfileParams, GetProfileResponse } from './types/profiles/Profile';
import { StandingsResponse } from './types/standings/Standings';
import { RefreshTokenResponse } from './types/RefreshTokenResponse';
import { UpdateUsernameResponse } from './types/accounts/UpdateUsernameResponse';
import { SignupResponse } from './types/accounts/SignupResponse';
import { RefreshTokenRequest } from './types/RefreshTokenRequest';
import { AuthTokens } from '../auth/types/AuthTokens';
import { API_URL } from './config';
import { RootState } from '../../store';
import { setAuthTokens } from '../auth/auth';
import { PasswordResetCompleteRequest } from './types/accounts/PasswordResetCompleteRequest';
import { SignupCompleteRequest } from './types/accounts/SignupCompleteRequest';
import { DeleteAccountRequest } from './types/accounts/DeleteAccountRequest';
import { UpdateEmailComplete } from './types/accounts/UpdateEmailComplete';
import { SendFriendRequestRequest } from './types/accounts/SendFriendRequestRequest';
import { GetPendingFriendRequestsResponse } from './types/accounts/GetPendingFriendRequestsResponse';
import { GetFriendsResponse } from './types/accounts/GetFriendsResponse';
import { FriendRequestRespondRequest } from './types/accounts/FriendRequestRespondRequest';
import { UnfriendRequest } from './types/accounts/UnfriendRequest';
import { SearchUsersRequest } from './types/accounts/SearchUsersRequest';
import { SearchUsersResponse } from './types/accounts/SearchUsersResponse';

// Function to refresh tokens
const refreshTokens = async (refreshToken: string): Promise<AuthTokens> => {
  try {
    const response = await fetch(`${API_URL}/auth/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const tokens: RefreshTokenResponse = await response.json();

    return {
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    };
  } catch (error) {
    console.error('Error refreshing tokens:', error);
    throw error;
  }
};

// Base query with token refreshing logic
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    // Token is expired, try to refresh it
    const { refreshToken } = (api.getState() as RootState).auth;
    if (refreshToken) {
      const newTokens = await refreshTokens(refreshToken);

      if (newTokens) {
        api.dispatch(setAuthTokens(newTokens));

        // Retry the original query with new token
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'accountInformation',
    'match',
    'practiceMatch',
    'profile',
    'standings',
  ],
  endpoints: (build) => ({
    // auth
    signin: build.mutation<SigninResponse, SigninRequest>({
      query({ emailOrUsername, password }) {
        return {
          url: 'auth/signin',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: new URLSearchParams({
            emailOrUsername: emailOrUsername,
            password: password,
          }).toString(),
        };
      },
    }),
    guestSignin: build.mutation<SigninResponse, void>({
      query() {
        return {
          url: 'auth/guest/signin',
          method: 'POST',
        };
      },
    }),
    signup: build.mutation<ErrorResponse | SignupResponse, SignupRequest>({
      query({ email, username, password }) {
        return {
          url: 'auth/signup',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: new URLSearchParams({
            email: email,
            username: username,
            password: password,
          }).toString(),
        };
      },
    }),
    signupComplete: build.mutation<void, SignupCompleteRequest>({
      query(params) {
        return {
          url: 'auth/signup/complete',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    signout: build.query<void, void>({
      query() {
        return {
          url: '/auth/signout',
        };
      },
    }),
    passwordReset: build.mutation<void, PasswordResetRequest>({
      query(params) {
        return {
          url: 'auth/password-reset/request',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    passwordResetComplete: build.mutation<void, PasswordResetCompleteRequest>({
      query(params) {
        return {
          url: 'auth/password-reset/complete',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    refreshTokens: build.mutation<RefreshTokenResponse, RefreshTokenRequest>({
      query(params) {
        return {
          url: 'auth/refresh-token',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    // users
    accountInformation: build.query<AccountInformationResponse, void>({
      query() {
        return {
          url: 'users/account-information',
        };
      },
      providesTags: ['accountInformation'],
    }),
    deleteAccount: build.mutation<void, DeleteAccountRequest>({
      query(params) {
        return {
          url: 'users/delete-account',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    emailAvailability: build.query<
      EmailAvailabilityResponse,
      EmailAvailabilityParams
    >({
      query(params) {
        return {
          url: 'users/email-availability',
          params,
        };
      },
    }),
    updateEmailRequest: build.mutation<ErrorResponse, UpdateEmailRequest>({
      query(params) {
        return {
          url: 'users/update-email/request',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    updateEmailComplete: build.mutation<ErrorResponse, UpdateEmailComplete>({
      query(params) {
        return {
          url: 'users/update-email/complete',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    updatePassword: build.mutation<void, UpdatePasswordRequest>({
      query(params) {
        return {
          url: 'users/update-password',
          method: 'PATCH',
          body: JSON.stringify(params),
        };
      },
    }),
    usernameAvailability: build.query<
      UsernameAvailabilityResponse,
      UsernameAvailabilityParams
    >({
      query(params) {
        return {
          url: 'users/username-availability',
          params,
        };
      },
    }),
    updateUsername: build.mutation<
      ErrorResponse | UpdateUsernameResponse,
      UpdateUsernameRequest
    >({
      query(params) {
        return {
          url: 'users/update-username',
          method: 'PATCH',
          body: JSON.stringify(params),
        };
      },
    }),
    sendFriendRequest: build.mutation<void, SendFriendRequestRequest>({
      query(params) {
        return {
          url: '/users/friends/request',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    getFriends: build.query<GetFriendsResponse, void>({
      query() {
        return {
          url: '/users/friends',
          method: 'GET',
        };
      },
    }),
    getPendingFriendRequests: build.query<
      GetPendingFriendRequestsResponse,
      void
    >({
      query() {
        return {
          url: '/users/friends/requests',
          method: 'GET',
        };
      },
    }),
    friendRequestRespond: build.mutation<void, FriendRequestRespondRequest>({
      query(params) {
        return {
          url: '/users/friends/respond',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
    }),
    unfriend: build.mutation<void, UnfriendRequest>({
      query({ username }) {
        return {
          url: `/users/friends/${username}`,
          method: 'DELETE',
        };
      },
    }),
    searchUsers: build.query<SearchUsersResponse, SearchUsersRequest>({
      query({ searchTerm }) {
        return {
          url: `/users/search?query=${searchTerm}`,
          method: 'GET',
        };
      },
    }),
    // gamemodes
    getPracticeMatch: build.query<GetPracticeMatchResponse, void>({
      query() {
        return {
          url: 'gamemodes/practice/new-match',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['practiceMatch'],
    }),
    submitPracticeMatchSolution: build.mutation<
      SubmitPracticeMatchResponse,
      SubmitPracticeMatchSolutionParams
    >({
      query(params) {
        return {
          url: 'gamemodes/practice/submit-solution',
          method: 'POST',
          body: JSON.stringify(params),
        };
      },
      invalidatesTags: ['profile'],
    }),
    // matches
    getMatch: build.query<GetMatchResponse, GetMatchParams>({
      query({ matchId }) {
        return {
          url: `matches/${matchId}`,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['match'],
    }),
    // profiles
    getProfile: build.query<GetProfileResponse, GetProfileParams>({
      query({ username }) {
        return {
          url: `profiles/${username}`,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['profile'],
    }),
    // standings
    standings: build.query<StandingsResponse, void>({
      query() {
        return {
          url: 'standings/rated',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['standings'],
    }),
  }),
});

export const {
  useAccountInformationQuery,
  useDeleteAccountMutation,
  useEmailAvailabilityQuery,
  useRefreshTokensMutation,
  useGetMatchQuery,
  useGetProfileQuery,
  useGetPracticeMatchQuery,
  usePasswordResetMutation,
  usePasswordResetCompleteMutation,
  useSigninMutation,
  useGuestSigninMutation,
  useSendFriendRequestMutation,
  useGetPendingFriendRequestsQuery,
  useGetFriendsQuery,
  useFriendRequestRespondMutation,
  useUnfriendMutation,
  useSearchUsersQuery,
  useSignupMutation,
  useSignupCompleteMutation,
  useSignoutQuery,
  useStandingsQuery,
  useSubmitPracticeMatchSolutionMutation,
  useUpdatePasswordMutation,
  useUpdateEmailCompleteMutation,
  useUpdateEmailRequestMutation,
  useUpdateUsernameMutation,
  useUsernameAvailabilityQuery,
} = apiSlice;

export default apiSlice;
