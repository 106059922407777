import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SwitchScreen from '../../../components/SwitchScreen/SwitchScreen';
import useMatchmaking from '../../../hooks/useMatchmaking/useMatchmaking';
import { useGetMatchQuery } from '../../../redux/slices/api/api';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page/Page';
import TimeoutRender from '../../../components/TimeoutRender/TimeoutRender';
import { Screen } from '../../../components/SwitchScreen/types/Screen';
import ForfeitMatchDialog from '../components/Puzzleboard/ForfeitMatchDialog/ForfeitMatchDialog';
import useDocumentTitle from '../../../hooks/useDocumentTitle/useDocumentTitle';
import MatchSummarySkeleton from '../components/MatchSummary/MatchSummarySkeleton';
import MatchmakingPuzzleboard from '../MatchmakingGameboard/MatchmakingPuzzleboard/MatchmakingPuzzleboard';
import MatchmakingPuzzleboardSkeleton from '../MatchmakingGameboard/MatchmakingPuzzleboard/MatchmakingPuzzleboardSkeleton';
import useUser from '../../../hooks/useUser/useUser';
import ChallengeMatchSummary from './ChallengeMatchSummary/ChallengeMatchSummary';
import { PlayerChallengeStatus } from './ChallengeMatchSummary/ChallengePlayAgainStatus/types/PlayerChallengeStatus';

const ChallengeGameboard = () => {
  const { t } = useTranslation();

  const { user } = useUser();
  const { username } = user;

  const [screen, setScreen] = useState<Screen>('primary');

  const {
    updateChallengeStatus,
    submitSolution,
    forfeitMatch,
    matchStartData,
    matchSummaryData,
    status: matchmakingStatus,
    state: matchmakingState,
    setStatus: setMatchmakingStatus,
  } = useMatchmaking();

  const isChallenger =
    matchmakingState.challengeStatus?.challenger === username;

  const challengeId = matchmakingState.challengeStatus?.challengeId;

  useDocumentTitle(t(`navigation.navigation.challengeMatch`));

  const [isRematchPending, setIsRematchPending] = useState(false);

  const { data: match, isFetching: isFetchingMatch } = useGetMatchQuery(
    { matchId: matchSummaryData?.id || '' },
    {
      skip: !(
        matchSummaryData?.id &&
        ['solutionSubmitted', 'matchSummary'].includes(matchmakingStatus)
      ),
    }
  );

  const [isOpenForfeitMatchDialog, setIsOpenForfeitMatchDialog] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (matchSummaryData?.id !== matchStartData?.matchId) {
      setScreen('primary');
    }
  }, [matchStartData, matchSummaryData]);

  useEffect(() => {
    if (matchSummaryData?.id !== matchStartData?.matchId) {
      setMatchmakingStatus('matchStarted');
    }
    // eslint-disable-next-line
  }, [matchStartData, matchSummaryData]);

  useEffect(() => {
    if (
      ['solutionSubmitted', 'matchSummary'].includes(matchmakingStatus) &&
      match &&
      !isFetchingMatch
    ) {
      setScreen('secondary');
    }
  }, [matchmakingStatus, match, isFetchingMatch]);

  useEffect(() => {
    if (matchmakingState.challengeStatus?.status === 'PENDING') {
      setIsRematchPending(true);
    } else {
      setIsRematchPending(false);
    }
  }, [matchmakingState.challengeStatus?.status]);

  const handleRequestRematch = () => {
    if (challengeId) {
      updateChallengeStatus(challengeId, 'PENDING');
    }
  };

  const handleAcceptRematch = () => {
    if (challengeId) {
      updateChallengeStatus(challengeId, 'ACCEPTED');
    }
  };

  const handleCancelRematch = () => {
    if (challengeId) {
      updateChallengeStatus(challengeId, 'CANCELED');
    }
  };

  const handleCloseRematch = () => {
    if (challengeId) {
      updateChallengeStatus(challengeId, 'CLOSED');
    }
  };

  const getRematchStatuses = (): PlayerChallengeStatus[] => {
    const challengeStatus = matchmakingState.challengeStatus?.status;
    if (challengeStatus === 'CLOSED') {
      return [];
    }

    if (!isRematchPending || !challengeStatus) {
      return ['PENDING', 'PENDING'];
    }

    switch (challengeStatus) {
      case 'PENDING':
        return ['ACCEPTED', 'PENDING'];
      case 'ACCEPTED':
        return ['ACCEPTED', 'ACCEPTED'];
    }

    return ['ACCEPTED', 'DECLINED'];
  };

  return (
    <Page variant='gameboard'>
      <ForfeitMatchDialog
        open={isOpenForfeitMatchDialog}
        onYes={() => {
          forfeitMatch(matchStartData!.matchId);
          setIsOpenForfeitMatchDialog(false);
        }}
        onNo={() => setIsOpenForfeitMatchDialog(false)}
      />

      <SwitchScreen
        activeScreen={screen}
        primaryScreen={
          matchmakingStatus === 'matchStarted' && matchStartData?.puzzle ? (
            <MatchmakingPuzzleboard
              gameMode={'challenge'}
              onClose={() => setIsOpenForfeitMatchDialog(true)}
              onSubmit={(expression) =>
                submitSolution(expression || '0', matchStartData.matchId)
              }
              participants={
                'participants' in matchStartData
                  ? matchStartData.participants
                  : []
              }
              puzzle={matchStartData.puzzle}
            />
          ) : (
            <TimeoutRender>
              <MatchmakingPuzzleboardSkeleton />
            </TimeoutRender>
          )
        }
        secondaryScreen={
          match && match.id === matchSummaryData?.id ? (
            <ChallengeMatchSummary
              animate={true}
              data={match}
              closeMatchSummaryButtonProps={{
                disabled: isChallenger && isRematchPending,
                onClick: () => {
                  if (matchmakingState.challengeStatus?.status !== 'CLOSED') {
                    handleCloseRematch();
                  }
                  navigate('/');
                },
              }}
              submitButtonProps={{
                disabled: matchmakingState.challengeStatus?.status === 'CLOSED',
                children:
                  isRematchPending && isChallenger
                    ? t('actions.cancel')
                    : t('actions.playAgain'),
                onClick: isRematchPending
                  ? isChallenger
                    ? handleCancelRematch
                    : handleAcceptRematch
                  : handleRequestRematch,
              }}
              rematchStatuses={getRematchStatuses()}
              matchmakingStatus={matchmakingStatus}
            />
          ) : (
            <MatchSummarySkeleton />
          )
        }
      />
    </Page>
  );
};

export default ChallengeGameboard;
