import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types/User';

export const initialState: User = {
  isSignedIn: false,
  username: '',
  type: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState: () => initialState,
    setUser: (state, action: PayloadAction<User>) => action.payload,
    setIsSignedIn: (state, action: PayloadAction<boolean>) => {
      state.isSignedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetState, setUser, setIsSignedIn } = userSlice.actions;

export default userSlice.reducer;
