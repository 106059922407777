import { MatchParticipant } from '../../types/match/participants/MatchParticipant';

/**
 * Returns participants puzzle solution.
 *
 * @param username - Players username.
 * @param participants - Match participants.
 * @returns Players puzzle solution, if there is one.
 *
 */
export const getParticipantsPuzzleSolution = (
  username: string,
  participants: MatchParticipant[]
) => {
  const participant = participants.find((p) => p.username === username);

  return participant?.puzzleSolution;
};
