import { Button, ButtonProps, SvgIcon, Typography } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './PlaygameCard.module.scss';

export interface PlayGameCardProps extends ButtonProps {
  children?: ReactNode;
  description?: string;
  title?: string;
  icon?: ReactNode;
}

const PlayGameCard = ({
  className,
  children,
  description,
  title,
  icon,
  ...rest
}: PlayGameCardProps) => {
  return (
    <Button className={classNames(styles['card'], className)} {...rest}>
      {(title || icon) && (
        <div className={styles['header']}>
          <Typography className={styles['title']}>{title}</Typography>

          {icon && (
            <SvgIcon className={styles['gamemode-icon']}>{icon}</SvgIcon>
          )}
        </div>
      )}

      {description && (
        <Typography className={styles['description']}>{description}</Typography>
      )}

      {children}
    </Button>
  );
};

export default PlayGameCard;
