import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { BoxProps } from '@mui/system';
import FormContainer from '../../../Signin/components/FormContainer/FormContainer';
import FormLogoHeader from '../../../Signin/components/FormLogoHeader/FormLogoHeader';
import FormBody from '../../../Signin/components/FormBody/FormBody';
import { NavLink, useSearchParams } from 'react-router-dom';
import styles from './UpdateEmailCompleteForm.module.scss';
import { useUpdateEmailCompleteMutation } from '../../../../redux/slices/api/api';
import Page from '../../../../components/Page/Page';
import FormLayoutContainer from '../../../Signin/components/FormLayoutContainer/FormLayoutContainer';
import FormLayoutFooter from '../../../Signin/components/FormLayoutFooter/FormLayoutFooter';

interface UpdateEmailCompleteFormProps extends BoxProps {}

const UpdateEmailCompleteForm = ({
  className,
  ...rest
}: UpdateEmailCompleteFormProps) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const expires = searchParams.get('expires');
  const isLinkExpired = expires
    ? new Date(Number(expires)) < new Date()
    : false;

  const [
    updateEmailComplete,
    {
      isSuccess: isSuccessUpdateEmailComplete,
      isError: isErrorUpdateEmailComplete,
    },
  ] = useUpdateEmailCompleteMutation();

  useEffect(() => {
    if (token) {
      updateEmailComplete({ token });
    }
  }, [token, updateEmailComplete]);

  if (!token || !expires) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t(
                'navigation.updateEmail.complete.invalidLinkError.title'
              )}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t(
                    'navigation.updateEmail.complete.invalidLinkError.description'
                  )}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isLinkExpired) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t(
                'navigation.updateEmail.complete.linkExpiredError.title'
              )}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t(
                    'navigation.updateEmail.complete.linkExpiredError.description'
                  )}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isErrorUpdateEmailComplete) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.updateEmail.complete.error.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.updateEmail.complete.error.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  if (isSuccessUpdateEmailComplete) {
    return (
      <Page className={styles['page']}>
        <FormLayoutContainer>
          <FormContainer>
            <FormLogoHeader
              title={t('navigation.updateEmail.complete.success.title')}
            />
            <FormBody className={styles['page-body']}>
              <Box className={styles['form-introduction-text']}>
                <Typography>
                  {t('navigation.updateEmail.complete.success.description')}
                </Typography>
              </Box>
              <Button
                className={styles['form-action-button']}
                color='primary'
                component={NavLink}
                fullWidth
                size='large'
                to='/'
              >
                {t('actions.returnToHome')}
              </Button>
            </FormBody>
          </FormContainer>
          <FormLayoutFooter />
        </FormLayoutContainer>
      </Page>
    );
  }

  return (
    <Page className={styles['signup-complete']}>
      <CircularProgress />
    </Page>
  );
};

export default UpdateEmailCompleteForm;
