import styles from './PracticePuzzleboard.module.scss';
import { Box, IconButton, Skeleton, SvgIcon } from '@mui/material';
import assets from '../../../../assets/assets';
import PuzzleboardHeader from '../../components/Puzzleboard/PuzzleboardHeader/PuzzleboardHeader';
import { PracticePuzzleboardProps } from './PracticePuzzleboard';
import KeyboardSkeleton from '../../components/Puzzleboard/Keyboard/KeyboardSkeleton';
import GoalboardSkeleton from '../../components/Puzzleboard/Goalboard/GoalboardSkeleton';
import PuzzleboardToolbar from '../../components/Puzzleboard/PuzzleboardToolbar/PuzzleboardToolbar';

export interface PracticePuzzleboardSkeletonProps
  extends Partial<PracticePuzzleboardProps> {}

const PracticePuzzleboardSkeleton = () => {
  return (
    <Box className={styles['puzzleboard']}>
      <PuzzleboardHeader>
        <Skeleton>
          <IconButton>
            <SvgIcon>
              <assets.icons.close />
            </SvgIcon>
          </IconButton>
        </Skeleton>

        <Skeleton sx={{ marginLeft: 'auto' }}>
          <IconButton>
            <SvgIcon>
              <assets.icons.help />
            </SvgIcon>
          </IconButton>
        </Skeleton>
      </PuzzleboardHeader>

      <GoalboardSkeleton />

      <PuzzleboardToolbar className={styles['puzzleboard-toolbar']}>
        <Skeleton>
          <IconButton>
            <SvgIcon>
              <assets.icons.close />
            </SvgIcon>
          </IconButton>
        </Skeleton>
      </PuzzleboardToolbar>

      <KeyboardSkeleton />
    </Box>
  );
};

export default PracticePuzzleboardSkeleton;
