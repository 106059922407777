import styles from './AppHeader.module.scss';
import assets from '../../../../assets/assets';
import Navigation from '../../components/Navigation/Navigation';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Link, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../../hooks/useSettings/useSettings';
import SearchBar from '../SearchBar/SearchBar';

export interface AppHeaderProps {
  className?: string;
  isSignedIn: boolean;
}

const AppHeader = ({ className, isSignedIn }: AppHeaderProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { colorScheme } = useSettings();

  return (
    <AppBar
      className={classNames(styles['appbar'], className)}
      position='static'
    >
      <Toolbar className={styles['toolbar']}>
        <div className={styles['logo-container']}>
          <Link href='/' aria-label='Go to Home'>
            {colorScheme === 'light' ? (
              <assets.images.BoronuLogoDark className={styles['logo']} />
            ) : (
              <assets.images.BoronuLogoLight className={styles['logo']} />
            )}
          </Link>
        </div>

        <SearchBar className={styles['search-bar']} />

        <div className={styles['navigation-container']}>
          {isSignedIn ? (
            <Navigation />
          ) : (
            <Button
              color='secondary'
              onClick={() => navigate('/signin')}
              sx={{ marginLeft: 'auto' }}
            >
              {t('actions.signin')}
            </Button>
          )}
        </div>
      </Toolbar>

      <Box className={styles['search-bar-mobile-container']}>
        <SearchBar className={styles['search-bar-mobile']} />
      </Box>
    </AppBar>
  );
};

export default AppHeader;
